<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Pricing</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Pricing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pricing-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="pricing-item wow fadeInUp" data-wow-delay=".3s">
                    <span>Basic</span>
                    <h2>$30</h2>
                    <p>Always</p>

                    <ul>
                        <li>2 Jobs Posting</li>
                        <li>1 Renew Jobs</li>
                        <li>2 Featured Jobs</li>
                        <li>10 Days Duration</li>
                    </ul>

                    <a class="pricing-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                    <span>Premium</span>
                    <h2>$50</h2>
                    <p>Always</p>

                    <ul>
                        <li>4 Jobs Posting</li>
                        <li>2 Renew Jobs</li>
                        <li>5 Featured Jobs</li>
                        <li>12 Days Duration</li>
                    </ul>

                    <a class="pricing-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="pricing-item wow fadeInUp" data-wow-delay=".5s">
                    <span>Advance</span>
                    <h2>$100</h2>
                    <p>Always</p>
                    
                    <ul>
                        <li>7 Jobs Posting</li>
                        <li>3 Renew Jobs</li>
                        <li>8 Featured Jobs</li>
                        <li>15 Days Duration</li>
                    </ul>

                    <a class="pricing-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>