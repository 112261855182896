import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submit-resume',
  templateUrl: './submit-resume.component.html',
  styleUrls: ['./submit-resume.component.scss']
})
export class SubmitResumeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
