import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobListService } from '../../services/job-list/job-list.service';
import { LocalStorageService } from '../../services/localStorage/localStorage.services';
import { PopupService } from '../../services/other/popup.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../services/acccount/account.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})

export class JobListComponent implements OnInit {
  currentDate: Date = new Date();
  lastDate;
  lastDateExpired = false;
  check = false;
  job_title : string;
  location : string;
  searchForm: FormGroup;
  candidateID: any;
  jobFormObj: any;
  jobPost: any;
  page = 1;
  pageSize = 10;
  itemLength: number;
  selectAll: any
  isApplied = false;
  buttonText = 'Apply';
  appliedHoursAgo;
  originalJobList:any;
  noJobToShowMeassage:string= "No Job Post";
  college:string;branch:string;year:string;sem:string;
  constructor(

    private jobListService: JobListService,
    private popup: PopupService,
    private router: Router,
    private accountService: AccountService,
    private formBuilder: FormBuilder
  ) {

  }
  isLastDateExpired(lastDate: string): boolean {
    const currentDate = new Date();
    const lastDateObj = new Date(lastDate);
    if (lastDateObj < currentDate) {
      this.lastDateExpired = true;
    }
    return lastDateObj < currentDate;
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      jobTitle: [''],
      jobLocation: [''],
    });

    this.jobListService.jobTitle$.subscribe((jobTitle) => { this.job_title = jobTitle});
    this.jobListService.jobLocation$.subscribe((jobLocation) => { this.location = jobLocation});
    this.currentDate = new Date();

    this.accountService.getUserId().subscribe(
      (response) => {
        this.candidateID = response.body;
        this.jobSearchOnInIt(this.candidateID);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    if(this.accountService.getRole() == "STUDENT_DEFAULT"){
      this.college = this.accountService.get('branch');
      const pursuing = JSON.parse(this.accountService.get('pursuing'));
      this.branch = pursuing?.courseCode;this.year = pursuing?.academicYearCode;this.sem = pursuing?.semesterCode;
    }
  }

  jobSearchOnInIt(candidateID) {
    if(this.accountService.getRole() == "JOBSEEKER"){      
      this.jobListService.jobListGetSeeker(candidateID)
        .subscribe(
          result => {
            this.jobPost = <any[]>result.body;
            this.itemLength = this.jobPost.count;
            this.jobPost = this.jobPost.job_post;

            this.originalJobList = [...this.jobPost];

            if (this.job_title !== '' || this.location !== '') {
              this.jobPost = this.jobPost.filter((job) => {
                const titleMatch = this.job_title === '' || job.jobTitle.toLowerCase().includes(this.job_title.toLowerCase());
                const locationMatch = this.location === '' || job.jobLocation.toLowerCase().includes(this.location.toLowerCase());
                      return titleMatch || locationMatch;
              });
              this.itemLength = this.jobPost.length;
            }
          },
          err => {
            this.popup.Swal.fire({
              icon: 'error',
              title: 'Something went wrong'
            })
          });
    }else{
        this.jobListService.jobListGetStudent(candidateID,this.college,this.branch,this.year,this.sem)
        .subscribe(
          result => {
            this.jobPost = <any[]>result.body;
            this.itemLength = this.jobPost.count;
            this.jobPost = this.jobPost.job_post;
          },
          err => {
            this.popup.Swal.fire({
              icon: 'error',
              title: 'Something went wrong'
            })
          });

      }
   }

  sortBy() {
    const filterElement = document.getElementById('filters') as HTMLSelectElement;
    const filterValue = filterElement.value;
    if (filterValue === 'alljobs') {
      this.ngOnInit();
    } else if (filterValue === 'date') {
      this.jobPost = this.jobPost.sort((a, b) => {
        if (new Date(a.postDate) > new Date(b.postDate)) {
          return -1;
        }
      });
    }
  }

  applyJob(jobid, candidateID) {
    this.jobListService.applyJobPost(jobid, candidateID)
      .subscribe(
        result => {
          if (result) {
            this.popup.Swal.fire({
              icon: 'success',
              title: 'Job Applied successfully'
            });
            this.router.navigate(['/applied-job']);
          }
        }, err => {
          console.log(err.status = 'Failed')
          if (err.status === 'Failed') {
            this.popup.Swal.fire({
              icon: 'error',
              title: 'Please fill profile details first'
            }).then(() => {
              this.router.navigate(['/dashboard', this.candidateID]);
            });

          } else {
            this.popup.Swal.fire({
              icon: 'error',
              title: 'Please try again'
            });
          }
        }
      )



  }

  onSubmit(searchForm: FormGroup) {
    this.jobPost = this.originalJobList;
    this.job_title = searchForm.value.jobTitle;
    this.location = searchForm.value.jobLocation;

    this.jobListService.jobTitleSubject.next(this.job_title);
    this.jobListService.jobLocationSubject.next(this.location);

    if (this.job_title !== '' || this.location !== '') {
      this.jobPost = this.jobPost.filter((job) => {
        const titleMatch = this.job_title === '' || job.jobTitle.toLowerCase().includes(this.job_title?.toLowerCase());
        const locationMatch = this.location === '' || job.jobLocation.toLowerCase().includes(this.location?.toLowerCase());
              return titleMatch || locationMatch;
        });
        this.itemLength = this.jobPost.length;
        if(this.itemLength <= 0 ){
          this.noJobToShowMeassage = `No Result Found`
        }
    }
    

  }


  viewed(jobId) {
    console.log("jobId", jobId)
    this.jobListService.jobViewed(this.candidateID, jobId)
      .subscribe(
        result => {
          if(result){
            console.log(result);
          }
        }
        , err => {
          this.popup.Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })

        }
      )
  }
}

