import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/global';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { PopupService } from '../../services/other/popup.service';

@Injectable({ providedIn: 'root' })
export class AccountService {

    private functionCallSubject = new Subject<void>();
    functionCall$ = this.functionCallSubject.asObservable();
  
    callFunction() {
      this.functionCallSubject.next();
    }
    
    constructor(private router: Router, private http: HttpClient,private popup: PopupService,) { }

    private isLoggedInSubject = new BehaviorSubject<boolean>(this.isUserLoggedIn());
    private userRoleSubject = new BehaviorSubject<string>(this.getRole());

    private set(key: string, value: any) {
        sessionStorage.setItem(key, value);
    }

    public get(key: string) {
        return sessionStorage.getItem(key);
    }

    private remove(key:string){
        sessionStorage.removeItem(key);
    }
    
    isLoggedIn$ = this.isLoggedInSubject.asObservable(); // observable to check if user is logged in 
    userRole$ = this.userRoleSubject.asObservable();

    isUserLoggedIn(): boolean { // method of check is user is loggedin
        let currentUser = JSON.parse(this.get('currentUser'));
        if (currentUser ){
            return true;
        }else if(!currentUser ){
            return false;
        }else{
            return false;
        }
    }
    getUsername():string{
        if(this.isUserLoggedIn){
            let userName = this.get('userName');
            return userName;
        }
    }
    getRole():string {
        if(this.isUserLoggedIn){
            let role = this.get('role');
            return role;
        }else{
            return '';
        }
    }
    getToken():any{
        if(this.isUserLoggedIn){
            let token = this.get('Token');
            return token;
        }
    }
    getUserId():Observable<any>{
        if(this.isUserLoggedIn && this.getRole() == 'RECRUITER'){   
            const userName = this.get('userName');
            return this.http.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile/id?recruiterUsername=${userName}`, {observe: 'response' })
        }else if(this.isUserLoggedIn && this.getRole() == 'TPO'){
            const userName = this.get('userName');
            return this.http.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile/id?tpoRecruiterUsername=${userName}`, {observe:'response'})
        }else if (this.isUserLoggedIn && this.getRole() == 'JOBSEEKER'){
            const userName = this.get('userName')
            return this.http.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile/id?seekerUsername=${userName}`, {observe: 'response' })
        }else if (this.isUserLoggedIn && this.getRole() == 'STUDENT_DEFAULT'){
            const userName = this.get('userName')
            return this.http.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile/id?seekerUsername=${userName}`, {observe: 'response' })
        }
        else{
            return new Observable<any>();
        }
    }
    login(username, password):Observable<any> {
        let data = { "username": username, "password": password }
        return this.http.post(`${GlobalConstants.CONNECT_BASE_URL}/api/auth/login`, data, { responseType: 'text', observe: 'response' },)}

    saveLoginDetails(userName:string, userRole:string, userBranch:any, token:any){
        const user = {
            currentUser: 'true',
            userName : userName,
            branch : userBranch,
            role : userRole,
            Token : token
        }
        this.set('currentUser', 'true');
        this.set('userName', userName);
        this.set('branch' , userBranch);
        this.set('role', userRole);
        this.set('Token', JSON.stringify(token));
        this.set('user', JSON.stringify(user));
    }
    // only for students for jobs shorting
    savePursuing(pursuing:any){
        this.set('pursuing',JSON.stringify(pursuing));
    }
    async refreshToken() {
        let token = this.getToken();
        if (this.isUserLoggedIn()) {
          await this.http.post<any>(GlobalConstants.CONNECT_BASE_URL + '/api/auth/login/refresh-token', token).toPromise().then((refreshToken) => {
            let user = JSON.parse(this.get('user'));
            user.Token = refreshToken.token;
            this.set('Token',JSON.stringify(refreshToken.token));
            this.set('user', JSON.stringify(user));
          }).catch((err) => {
            this.logout();
          });
        }
    }
    logout() {
        this.set('currentUser', 'false');
        this.set('role', '');
        this.remove('userName');
        this.remove('branch');
        this.remove('Token');
        this.remove('user');
        this.isLoggedInSubject.next(false);
        this.userRoleSubject.next('');
        this.router.navigate(['/login']);
    }

    registerTpo(data){
        return this.http.post<any>(GlobalConstants.CONNECT_BASE_URL + '/api/jobportal/user/tpo-signup', data);
    }

    registerRecruiter(data) {
        return this.http.post<any>(GlobalConstants.CONNECT_BASE_URL + '/api/jobportal/user/recruiter-signup', data);
    }

    registerSeeker(data) {
        return this.http.post<any>(GlobalConstants.CONNECT_BASE_URL + '/api/jobportal/user/seeker-signup', data);
    }

    registerStudSeeker(data){
        return this.http.post<any>(GlobalConstants.CONNECT_BASE_URL + '/api/jobportal/user/student-signup', data);
    }
}