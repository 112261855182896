import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ContactUsService } from '../../services/other/contact-us.service';
import { AccountService } from '../../services/acccount/account.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subscribeForm;
  Toast:any;
  role:string;
  constructor(
    private contactUs: ContactUsService,
    private accountService : AccountService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.subscribeForm = new FormGroup({
      email: new FormControl('')
    });
    this.accountService.userRole$.subscribe((resp:any)=>{this.role = resp});
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
  }

  onSubmit() {
    if(this.accountService.isUserLoggedIn()){
      this.contactUs.emailSubject.next(this.subscribeForm.value.email);
      this.subscribeForm.reset();
      this.router.navigate(['contact']);
    }else{
      this.contactUs.emailSubject.next(this.subscribeForm.value.email);
      this.subscribeForm.reset();
      this.Toast.fire({
        icon: 'error',
        title: 'Your Are not Logged In Please Login'
      });
      this.router.navigate(['login']);
    }
    
  }

}
