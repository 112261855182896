import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { UserResume} from './user-interface'
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service'

export class ResumeTemplate2 {
    constructor(private candidateService:CandidateServicesService){}

    private currentY = 20;

    public async twoColumnImageTemplate(user:UserResume){
        this.currentY = 20;
        let image:any;
        let imageFormat:any;
        if(user.profilePicBlob != null && user.profilePicBlob != ""){
            image = await this.downloadTheImageFromS3(user.profilePicBlob);
            const regex = /\.([a-zA-Z]+)$/;
            imageFormat = user.profilePicBlob.match(regex);
            imageFormat = imageFormat[1].toUpperCase();
        }else{
            image="../../../../assets/img/male_profile.png";
            imageFormat="PNG";
        }
        const doc = new jsPDF();
        const rightMargin = doc.internal.pageSize.width - 10;
        doc.setFont('Times');
        doc.setFillColor(70, 70, 70);
        doc.roundedRect(0,0 , 75, doc.internal.pageSize.height,0,0,'F');

        this.addImage(doc,image,imageFormat,15,10,45,45);

        this.addSubHeading(doc,'Contact Info',10,10);
        doc.setFont('Times','Roman');doc.setFontSize(10);doc.setTextColor(255);
        doc.text(`Email: ${user.email}`,10,this.currentY);this.currentY+=5;
        doc.text(`Contact: ${user.contactNumbers[0].number}`,10,this.currentY);this.currentY+=5;
        doc.text(`Address: ${user.strAddress}`, 10, this.currentY, {maxWidth:70});this.currentY+=15;

        this.addSubHeading(doc,"Skills",10,10);
        doc.setFont('Times', 'Bold');doc.setFontSize(10);doc.setTextColor(0);
        user.skills.forEach(skill => {
            doc.setFillColor(252, 255, 150); // Yellow color (RGB)
            // Draw the colored capsule
            doc.roundedRect(10, this.currentY, 55, 8, 5, 5, 'F'); // Adjust dimensions as needed
            doc.setFont('Times', 'Bold');doc.setFontSize(10);doc.setTextColor(0);
            doc.text(`${skill.name} ${skill.skillProficiency}%`, 15, this.currentY + 5,{renderingMode:'fill'});
            this.currentY += 12;
          });this.currentY +=10;
        
        this.addSubHeading(doc,"Social Links",10,10);
        doc.setFont('Times','Roman');doc.setFontSize(12);doc.setTextColor(255);
        doc.textWithLink(`Website`,10,this.currentY,{url:`https://job.campusorbit.com/myonlinecv/${user.email}`});this.currentY+=7;
        doc.textWithLink(`Linkdein`,10,this.currentY,{url:user.socialLinksLinkedin});this.currentY+=7;
        doc.textWithLink(`Twitter`,10,this.currentY,{url:user.socialLinksTwitter});this.currentY+=7;
        doc.textWithLink(`FaceBook`,10,this.currentY,{url:user.socialLinksFacebook});this.currentY+=7;        
        doc.textWithLink(`Instagram`,10,this.currentY,{url:user.socialLinksInstagram});this.currentY+=7;

        doc.line(75, 0, 75, doc.internal.pageSize.height);// this is the dividing line of two column
        this.currentY = 20;

        doc.setFontSize(26);doc.setFont('Times','Bold');doc.setTextColor(0);
        doc.text(`${user.firstName} ${user.lastName}`, 80, this.currentY, { align:"justify"});this.currentY +=10
        if(user.jobTitle){
            doc.setFontSize(15);doc.setTextColor(255, 120, 0);
            doc.text(user.jobTitle,80,this.currentY);doc.setTextColor(0);this.currentY +=10
        };
        this.addLine(doc,80);

        this.addSubHeading(doc,'Objective',10,80);
        this.addText(doc,user.description,80,rightMargin,'Times');
        this.addLine(doc,80);

        this.addSubHeading(doc,'Work Exprienece',10,80);
        this.addText(doc,user.workExperience,80,rightMargin,'Times');
        this.addLine(doc,80);

        this.addSubHeading(doc,'Education',10,80);
        doc.setFont('Times','Roman');
        doc.setFontSize(10);doc.setTextColor(0);
        user.educationalDetails
            .sort((a, b) => parseInt(b.from_year) - parseInt(a.from_year))
            .map(edu => {
                doc.text(`${edu.stream}`,80,this.currentY);this.currentY +=5
                doc.text(`${edu.degree}`,80,this.currentY);this.currentY +=5
                doc.text(`${edu.institute} `,80,this.currentY);
                let text = `---${edu.from_year} - ${edu.to_year}`;
                let x = doc.internal.pageSize.width - doc.getTextWidth(text) - 10
                doc.text(text,x,this.currentY);this.currentY +=10
            });
        this.addLine(doc,80);

        this.addSubHeading(doc,'Research',10,80);
        this.addText(doc,user.research,80,rightMargin,'Times');

        this.addWatermark(doc,'Powered by  Campus Orbit');

        return doc
    }
    
    // Methods for creating different Components of resume
    private addSubHeading(doc: any, text: string,plusY:number,xPos = 10, align: string = 'left') {
        doc.setFont('Times', 'Bold');
        doc.setFontSize(15);
        doc.setTextColor(255, 120, 0);
        doc.text(text, xPos, this.currentY, { align });
        this.currentY += plusY; // Increment Y position
    }
    private addText(doc, text, x, maxWidth,font = 'Times',extraSpace=0) {
        doc.setFont(font, 'Roman');
        doc.setFontSize(10);doc.setTextColor(0);
        // Ensure x + maxWidth does not exceed page width
        if (x + maxWidth > doc.internal.pageSize.width) {
            maxWidth = doc.internal.pageSize.width - x - 10; // Adjust maxWidth
        }
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        doc.text(text, x, this.currentY, { maxWidth: maxWidth - extraSpace});
        this.currentY += blockHeight + 8;
    }
    private addLine(doc: any,xPos=10, height: number = 0.3) {
        doc.setLineWidth(height);
        doc.setDrawColor(0);
        doc.line(xPos, this.currentY, doc.internal.pageSize.width - 10, this.currentY);
        this.currentY += height + 5; // Increment Y position
    }
    private addImage(doc: any, imagePath: string, imageFormat: string, x: number, y: number, width: number, height: number) {
        const imgData = imagePath; // Replace with your image data
        doc.addImage(imgData, imageFormat, x, y, width, height);
        this.currentY += height + 10; // Increment Y position
    }      
    private addWatermark(doc:any, watermarkText: string) {
        doc.setFontSize(12);
        doc.setFont('Times','Italic');
        doc.setTextColor(100, 100, 100);
        const watermarkX = doc.internal.pageSize.width - 10;
        const watermarkY = doc.internal.pageSize.height - 5;
        doc.textWithLink(watermarkText, watermarkX, watermarkY, { url: 'https://job.campusorbit.com', align:'right' });
    }
    private downloadTheImageFromS3(filePath: string): Promise<string> {
        return new Promise((resolve, reject) => {
          this.candidateService.downloadProfilePicPublic(filePath).subscribe(
            (res) => {
              const imageUrl = URL.createObjectURL(res);
              resolve(imageUrl);
            },
            (err) => {
              reject(err);
            }
          );
        });
    }
}