import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PopupService } from '../../services/other/popup.service';
import { AccountService } from '../../services/acccount/account.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-online-cv',
  templateUrl: './online-cv.component.html',
  styleUrls: ['./online-cv.component.scss']
})
export class OnlineCvComponent implements OnInit {
  candidateInfo: any;
  resData: any;
  skillList: any;
  skills_array: any;
  profilePhoto: any;
  userName: string;
  userRole: string;

  constructor(
    private route: ActivatedRoute,
    private candidateServices: CandidateServicesService,
    private popup: PopupService,
    private http:HttpClient,
    private accountService: AccountService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.userName = this.route.snapshot.paramMap.get('user');
    this.candidateServices.getCandidateInfoPublic(this.userName).subscribe((response) => {
      this.candidateInfo = response.body;
      if (this.candidateInfo.gender === 'MALE') {
        this.profilePhoto = 'assets/img/male_profile.png';
      } else if (this.candidateInfo.gender === 'FEMALE') {
        this.profilePhoto = 'assets/img/female_profile.png';
      } else {
        this.profilePhoto = 'assets/img/neutral_profile.jpg'
      }
      if (this.candidateInfo.profilePicBlob != null || this.candidateInfo.profilePicBlob != '') {
        this.downloadProfilePic(this.candidateInfo.profilePicBlob);
      }
    }, err => {
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });

  }

  downloadProfilePic(filePath: string) {
    this.candidateServices.downloadProfilePicPublic(filePath).subscribe((blob: Blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.profilePhoto = this.sanitizer.bypassSecurityTrustUrl(objectURL) as SafeUrl;
    });
  }

  viewResume(url): any {
    this.candidateServices.downloadResume(url).subscribe(res => {
      const fileURL = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
      window.open(fileURL, '_blank');
    });
  }

  downloadResume(filePath, firstName, lastName) {
    this.candidateServices.downloadResume(filePath).subscribe(result => {
      function downloadBlob(blob, name = 'download.pdf') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        document.body.removeChild(link);
      }
      const regex = /\.(.*?)$/;
      const matches = filePath.match(regex);
      let fileEx;
      if (matches) {
        fileEx = matches[0];
      }
      let fileName = "applicats_resume" + fileEx;
      if (firstName != null || lastName != null) {
        fileName = firstName + "_" + lastName + fileEx;
      }
      downloadBlob(result, fileName);
    }, err => {
      console.log(err);

    })
  }
  showSkillData() {
    this.candidateServices.candidateSkillsListGet().subscribe(
      res => {
        this.resData = Object.keys(res).map(e => res[e]);
        this.skills_array = this.resData.filter(skill => this.skillList.includes(Number(skill.id)));

      }
    ), err => { }


  }


}
