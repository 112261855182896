<footer>
    <div class="container">
        <div class="subscribe-area">
            <div class="section-title">
                <h2>Subscribe Newsletter</h2>
            </div>

            <div class="subscribe-shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/2.png" alt="Shape">
            </div>

            <div class="subscribe-item">
                <form class="newsletter-form"  [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" >
                    <input type="email" class="form-control" placeholder="Enter Your Email"  formControlName="email">
                    <button class="btn subscribe-btn" type="submit">Subscribe</button>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <p>Campus Orbit Online Job Portal is a platform that connects job seekers with potential employers. It provides a user-friendly interface for job seekers to search and apply for jobs, and for employers to post job openings and review applications. It provide college campus placement staff a platform to manage internal college placements.</p>
                        <ul>
                            <li><a href="https://www.youtube.com/channel/UCijq6nfPIA8suBdVu9m99dA"><i class="icofont-youtube-play"></i></a></li>
                            <li><a href="https://www.facebook.com/CampusOrbit-103828858247829"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/campus_orbit/"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://twitter.com/CampusOrbit"><i class="icofont-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Quick Links</h3>

                        <ul>
                            <li *ngIf="role=='SEEKER'"> <a routerLink="/" ><i class="icofont-simple-right"> </i> Home</a></li>
                            <li *ngIf="role=='RECRUITER'"> <a routerLink="/recruiter"><i class="icofont-simple-right"></i> Home </a></li>
                            <li><a routerLink="/about"><i class="icofont-simple-right"></i> About Us</a></li>
                            <!-- <li><a routerLink="/company-list"><i class="icofont-simple-right"></i> Companies</a></li>s -->
                            <li><a href="https://www.campusorbit.com/" target="_blank"><i class="icofont-simple-right"></i> Website</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i> Goregaon West, Mumbai</li>
                            <li><i class="icofont-ui-call"></i> <a href="tel:+880123456789">+91 93729 63133</a></li>
                            <li><i class="icofont-ui-email"></i> <a href="mailto:hello@peof.com">support@campusorbit.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>© Copyrights 2024 Campus Orbit All rights reserved</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item copyright-right">
                        <ul>
                            <li><a href="https://www.campusorbit.com/details/disclaimer" target="_blank">Disclaimer</a></li>
                            <li><span>-</span></li>
                            <li><a href="https://www.campusorbit.com/privacy" target="_blank">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>