import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { LocalStorageService } from '../localStorage/localStorage.services';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class S3uploadapiService {
  s3:any
  data
  result:boolean = false;
  constructor(private httpClient: HttpClient,
    private router:Router,
    private localStorageServices:LocalStorageService, )
   {

   }
uploadFileToS3Resume(id,data){
    console.log(data);
    const resumeLink={resume_link:data.Location, resume_name:data.Key}
    return  this.httpClient.put(`/api/user/seeker-update-resume-link/${id}`,resumeLink, { observe: 'response' });
    
  }
uploadFileToS3Image(id,data){
    const profileLink={profile_pic_link:data.Location}
    return  this.httpClient.put(`api/user/update-profile-pic/${id}`,profileLink, { observe: 'response' });
    
  }
  uploadFileToS3Logo(id,data){
    const profileLink={profile_pic_link:data.Location}
    return  this.httpClient.put(`/api/user/update-job-logo/${id}`,profileLink, { observe: 'response' });
    
  }
  uploadFileToS3RecruiterImage(id,data){
    const profileLink={profile_pic_link:data.Location}
    return  this.httpClient.put(`api/user/update-profile-pic/${id}`,profileLink, { observe: 'response' });
    
  }

uploadFile(id,file, type) {
  return new Promise((resolve, reject) => {
    const contentType = file.type;
    const bucket = new S3(
          {
              accessKeyId: 'AKIAV4XUNHUGXEZ5H4C5',
              secretAccessKey: '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',
          }
      );
      const params = {
          Bucket: 'jobportalcampusorbit',
          Key:  file.name,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType,
          class_obj:this,
          type:type
      };
      bucket.upload(params, (err, data) => {
        if (err) {
            reject(err);
        } else {
          if( type =="resume"){

            params.class_obj.uploadFileToS3Resume(id,data).subscribe((res) => {

              resolve({ data });
            }, (err) => {
              reject(err);
            });
          } 
          else{
            params.class_obj.uploadFileToS3Image(id,data).subscribe((res) => {

              resolve({ data });
            }, (err) => {
              reject(err);
            });

          }
      } 
    });

  });
}
uploadFileRecruiter(id,file, type) {
  return new Promise((resolve, reject) => {
    const contentType = file.type;
    const bucket = new S3(
          {
              accessKeyId: 'AKIAV4XUNHUGXEZ5H4C5',
              secretAccessKey: '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',
          }
      );
      const params = {
          Bucket: 'jobportalcampusorbit',
          Key:  file.name,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType,
          class_obj:this,
          type:type
      };
      bucket.upload(params, (err, data) => {
        if (err) {
            reject(err);
        } else {
          if( type =="resume"){

            params.class_obj.uploadFileToS3Resume(id,data).subscribe((res) => {

              resolve({ data });
            }, (err) => {
              reject(err);
            });
          } else if(type=="logo"){

            params.class_obj.uploadFileToS3Logo(id,data).subscribe((res) => {

              resolve({ data });
            }, (err) => {
              reject(err);
            });
          }
          else{
            params.class_obj.uploadFileToS3RecruiterImage(id,data).subscribe((res) => {

              resolve({ data });
            }, (err) => {
              reject(err);
            });

          }
      } 
    });

  });
}
    

}
