import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalConstants } from 'src/app/global';


@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  emailSubject = new BehaviorSubject<string>('');
  emailId$ = this.emailSubject.asObservable();

  constructor(private httpClient: HttpClient) { }
  contactus(data){
    return this.httpClient.post(`/api/contactus`,data, { observe: 'response' });


  }

  sendContactUs(data) {
    return this.httpClient.post(GlobalConstants.CONNECT_BASE_URL + "/api/core/contactus/send", data, { observe: 'response' });
  }

  getJobPortalStats(){
    return this.httpClient.get(GlobalConstants.CONNECT_BASE_URL + "/api/jobportal/jobs/utils", {observe:"response"});
  }


}
