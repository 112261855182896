import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { env } from '../../../../environments/env'
import { GlobalConstants } from '../../../global';
import { UUID } from 'aws-sdk/clients/cloudtrail';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobListService {

  constructor(private httpClient: HttpClient)
  { }

  public jobTitleSubject = new BehaviorSubject<string>('');
  jobTitle$ = this.jobTitleSubject.asObservable();

  public jobLocationSubject = new BehaviorSubject<string>('');
  jobLocation$ = this.jobLocationSubject.asObservable();
// Service to get all jobs
jobListGetSeeker(id){
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost/seeker?seeker_Id=${id}`, { observe: 'response' });
}
jobListGetStudent(id,college,branch,year,sem){
  const params = {
    "student_id": id, 
    "college": college,"branch":branch,"year":year,"sem":sem
  }
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost/student`, { params, observe: 'response' });
}
jobListGetByIdSeeker(JobId :UUID, seekerId:UUID){
  const params = {
    "seeker_Id": seekerId,
    "jobPost_Id": JobId
  }
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost/seeker`, { params, observe: 'response' });
}
jobListGetByIdStudent(JobId :UUID, studentId:UUID){
  const params = {
    "student_id": studentId,
    "jobPost_Id": JobId
  }
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost/student`, { params, observe: 'response' });
}
getAppliedJobs(jobSeekerId){
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/apllied/jobseeker?job_seeker_id=${jobSeekerId}`, { observe: 'response' });
}
// http://localhost:8081/recruiter/1/jobpost
myPostedJobs(recruiterId){
  // http://192.168.29.155:8081/jobs/jobpost?recruiter_id=1 
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost?recruiter_id=${recruiterId}`, { observe: 'response' });
}
editPostedJobs(recruiterId:string, jobId:string){
  const params = {
    recruiter_id: recruiterId,
    job_id: jobId
  };
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost`, { params, observe: 'response' });
}
// initiate a apply request on a jobpost
applyJobPost(jobId, seekerId){
   return this.httpClient.post<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/job-apply?jobPostId=${jobId}&seekerId=${seekerId}`, { observe: 'response' });
}

deleteJob(jobId,recruiterID){
  const params = {
    jobPostId: jobId,
    recruiterId: recruiterID
  };
  return this.httpClient.delete<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost`, { params, observe: 'response' });
}

// jobListSearch(id, title, location){
//   // return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/jobs/job-apply?seeker_id=${id}&is_applied=true&title=${title}&location=${location}`, { observe: 'response' });

//     return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/jobs/job-apply?seeker_id=${id}&title=${title}&location=${location}`, { observe: 'response' });
    
// }


jobListSearch(id: string, title: string, location: string) {
 // alert("called");
  //let url = `/api/jobs/job-apply?seeker_id=${id}&is_applied=true`;
  let url = `${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/job-apply?seeker_id=${id}`;


  function isNotBlankString(str: string): boolean {
     if (str.trim() === ""){
      return false;
    }
    else{
      return true;
    }
      
  } 

  if (isNotBlankString(title)) {
    url += `&title=${encodeURIComponent(title)}`;
  }
  if (isNotBlankString(location) ) {
    url += `&location=${encodeURIComponent(location)}`;
  }

  return this.httpClient.get(url, { observe: 'response' });
}


applyJobGet(id){
  return this.httpClient.get(`/apijobs/jobpost?seeker_id=${id}`, { observe: 'response' });
}

// myPostedJobs(id){
//   return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/mypostedjobs/${id}`, { observe: 'response' });
// }

UpdateJobStatus(id, status){
  let data = {'status':Number(status)}
  return this.httpClient.patch<any>(`${GlobalConstants.CONNECT_BASE_URL}/updatejobstatus/${id}` ,data, { observe: 'response' });
}

getCollegeHierarchy(){
  return this.httpClient.get<any[]>(`${GlobalConstants.CONNECT_BASE_URL}/api/auth/get-college-n-hierarchy`, { observe: 'response'});
}
jobPost(data, username){

  return this.httpClient.post<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost?recruiter_username=${username}`,data ,{ observe: 'response' });
}

jobPostTpo(data, username){
  return this.httpClient.post<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost-college?tpoRecruiterId=${username}`,data ,{ observe: 'response' });
}

saveEditedJob(data){
     return this.httpClient.put<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost`, data ,{ observe: 'response' });

}
saveEditedJobTpo(data){
  return this.httpClient.put<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost-college`, data ,{ observe: 'response' });

}

jobGet(seeker_id,id){

  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/job-apply?job_post_id=${id}&seeker_id=${seeker_id}`, { observe: 'response' });
}
jobViewed(seekerId:string,jobId:string){
  return this.httpClient.post<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost-view?seekerId=${seekerId}&jobPostId=${jobId}`, { observe: 'response' });
}
applicationStaticsGet(jobPostId,recruiterID){
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/job-apply/applicants?jobPostId=${jobPostId}`, { observe: 'response' });
}

applicationStaticsExport(id,recruiter_id){
  
  return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/job-apply/${id}?recruiter_id=${recruiter_id}`, { observe: 'response' });
}

downloadCompanyLogo(filePath:string){
  return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/company-logo?filePath=${filePath}`, { responseType:'blob' });
}

homeJobForNoneUser(){
  return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost/noneUser`,{observe:"response"});
}
}
