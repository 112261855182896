import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { JobListComponent } from './components/pages/job-list/job-list.component';
import { FavouriteJobComponent } from './components/pages/favourite-job/favourite-job.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { CandidateListComponent } from './components/pages/candidate-list/candidate-list.component';
import { CandidateDetailsComponent } from './components/pages/candidate-details/candidate-details.component';
import { RecruiterDetailsComponent } from './components/pages/recruiter-details/recruiter-details.component';
import { SingleResumeComponent } from './components/pages/single-resume/single-resume.component';
import { SubmitResumeComponent } from './components/pages/submit-resume/submit-resume.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { RecruiterDashboardComponent } from './components/pages/recruiter-dashboard/recruiter-dashboard.component';
import { CompanyListComponent } from './components/pages/company-list/company-list.component';
import { CompanyDetailsComponent } from './components/pages/company-details/company-details.component';
import { LoginComponent } from './components/pages/login/login.component';
import { CreateAccountComponent } from './components/pages/create-account/create-account.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { RecruiterProfileComponent } from './components/pages/recruiter-profile/profile.component';
import { SingleProfileComponent } from './components/pages/single-profile/single-profile.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { PostedJobsComponent } from './components/pages/posted-jobs/posted-jobs.component';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationStatisticsComponent } from './components/pages/application-statistics/application-statistics.component';
import { RecruiterHomeComponent } from './components/pages/recruiter-home/recruiter-home.component';
import { AuthGuardService } from './auth-guard.service';
import { EditJobComponent } from './components/pages/edit-job/edit-job.component';
import { AppliedJobComponent } from './components/pages/applied-job/applied-job.component';
import { ResumeBuilderComponent } from './components/pages/resume-builder/resume-builder.component';
import { OnlineCvComponent } from './components/pages/online-cv/online-cv.component';
import { ExamCandiateView, ExamCreationComponent, ExamQuestionView, ExamResultView } from './components/pages/exam-creation/exam-creation.component';
import { InterviewBotComponent } from './components/pages/interview-bot/interview-bot.component';
import { ResumeBuilderMobileComponent } from './components/pages/resume-builder/resume-builder-mobile.component';

 
const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'about', component: AboutComponent},
    {path: 'myonlinecv/:user',component:OnlineCvComponent , data: { showNav: false }},
    {path: 'interview/mobile', component:InterviewBotComponent,data: { showNav: false }},
    {path: 'resume/mobile/:user', component:ResumeBuilderMobileComponent,data: { showNav: false }},
    {path: 'recruiter', component: RecruiterHomeComponent, canActivate: [AuthGuardService]},
    {path: 'aptitude', component:ExamCreationComponent, canActivate:[AuthGuardService]},
    {path: 'aptitude/questions', component:ExamQuestionView, canActivate:[AuthGuardService]},
    {path: 'aptitude/candiate', component:ExamCandiateView, canActivate:[AuthGuardService]},
    {path: 'aptitude/result', component:ExamResultView, canActivate:[AuthGuardService]},
    {path: 'candidate', component: HomeOneComponent , canActivate: [AuthGuardService]},
    {path: 'job-list', component: JobListComponent, canActivate: [AuthGuardService]},
    {path: 'applied-job', component: AppliedJobComponent,  canActivate: [AuthGuardService]},
    {path: 'job-details/:id', component: JobDetailsComponent , canActivate: [AuthGuardService]},
    {path: 'post-a-job', component: PostAJobComponent , canActivate: [AuthGuardService]},
    {path: 'candidate-list', component: CandidateListComponent , canActivate: [AuthGuardService]},
    {path: 'candidate-details', component: CandidateDetailsComponent , canActivate: [AuthGuardService]},
    {path: 'recruiter-details', component: RecruiterDetailsComponent , canActivate: [AuthGuardService]},
    {path: 'resume-builder',component:ResumeBuilderComponent,canActivate:[AuthGuardService]},
    {path: 'dashboard', component: DashboardComponent , canActivate: [AuthGuardService]},
    {path: 'recruiter-dashboard', component: RecruiterDashboardComponent , canActivate: [AuthGuardService]},
    {path: 'login', component: LoginComponent},
    {path: 'create-account', component: CreateAccountComponent},
    {path: 'recruiter-profile', component: RecruiterProfileComponent , canActivate: [AuthGuardService]},
    {path: '404', component: ErrorComponent},
    {path: 'faq', component: FaqComponent },
    {path: 'blog', component: BlogComponent , canActivate: [AuthGuardService]},
    {path: 'blog-details', component: BlogDetailsComponent , canActivate: [AuthGuardService]},
    {path: 'contact', component: ContactComponent , canActivate: [AuthGuardService]},
    {path: 'posted-jobs', component: PostedJobsComponent , canActivate: [AuthGuardService]},
    {path: 'edit-job', component:EditJobComponent,canActivate:[AuthGuardService]},
    {path: 'application-statics/:id', component: ApplicationStatisticsComponent , canActivate: [AuthGuardService]},
    {path: 'interview', component:InterviewBotComponent,canActivate:[AuthGuardService]},
    {path: '**', component: ErrorComponent}, // This line will remain down from the whole pages component list

    // Not in use component
    
    // {path: 'home-three', component: HomeThreeComponent , canActivate: [AuthGuardService]},
    // {path: 'favourite-job', component: FavouriteJobComponent,  canActivate: [AuthGuardService]},
    // {path: 'single-resume', component: SingleResumeComponent , canActivate: [AuthGuardService]},
    // {path: 'submit-resume', component: SubmitResumeComponent , canActivate: [AuthGuardService]},
    // {path: 'pricing', component: PricingComponent , canActivate: [AuthGuardService]},
    // {path: 'company-list', component: CompanyListComponent , canActivate: [AuthGuardService]},
    // {path: 'company-details', component: CompanyDetailsComponent , canActivate: [AuthGuardService]},
    // {path: 'profile', component: ProfileComponent , canActivate: [AuthGuardService]},
    // {path: 'single-profile', component: SingleProfileComponent , canActivate: [AuthGuardService]},
    // {path: 'terms-and-conditions', component: TermsAndConditionsComponent , canActivate: [AuthGuardService]},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent , canActivate: [AuthGuardService]},
    // {path: 'blog', component: BlogComponent , canActivate: [AuthGuardService]},
    // {path: 'blog-details', component: BlogDetailsComponent , canActivate: [AuthGuardService]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }