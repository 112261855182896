import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../global';
import { env } from '../../../../environments/env'



@Injectable({
  providedIn: 'root'
})
export class RecruiterServicesService {

  constructor(private httpClient: HttpClient)
  { }


  recruiterListGet(){
    return this.httpClient.get(`api/recruiter/`, { observe: 'response' });
  }

  recruiterGetByUsername(userName:string){
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile?recruiterUsername=${userName}`, { observe: 'response' });
  }
  recruiterDashboardGetById(id){
    // localhost:8081/user/profile?recruiter_id=1 
    // {{urlgpc}}user/profile?seeker_id=3  
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/dashboard?recruiterId=${id}`, { observe: 'response' });
  }
  
  recruiterInfoPatch(data : any, userName : string){
    return this.httpClient.put<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/recruiter-update/${userName}`,data, { observe: 'response'});
  }

  companyInfoPatch(data : any, userName : string){
    return this.httpClient.put<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/company?recruiterUsername=${userName}`,data, { observe: 'response'});
  }

  updateJobStatus(jobId, status) {
    return this.httpClient.patch(`api/recruiter/${jobId}`,status, { observe: 'response'});
  }

  uploadProfilePic(folder:string,userType:string,collegeCode:string,firstName:string,lastName:string,dateOfBirth:string,id:string, formData){
    return this.httpClient.put(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/update-profile-pic?folder=${folder}&userType=${userType}&collegeCode=${collegeCode}&firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&id=${id}`,formData,{responseType: 'text'});
  }
  downloadProfilePic(filePath:string){
    return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile-pic?filePath=${filePath}`, { responseType:'blob' });
  }
  uploadCOmpanyLogo(folder:string,userType:string,collegeCode:string,firstName:string,lastName:string,dateOfBirth:string,id:string, formData){
    return this.httpClient.put(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/update-company-logo?folder=${folder}&userType=${userType}&collegeCode=${collegeCode}&firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&id=${id}`,formData,{responseType: 'text'});
  }
  downloadCompanyLogo(filePath:string){
    return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/company-logo?filePath=${filePath}`, { responseType:'blob' });
  }

  getMyAllocationDetails() {
    return this.httpClient.get(GlobalConstants.CONNECT_BASE_URL + '/api/core/staff-allocation/by-myId', { observe: 'response' });
  }
  getHierarchyStaff(staffAllocationDetails) {
    return this.httpClient.get(GlobalConstants.CONNECT_BASE_URL + '/api/core/college/get-college-course/by-college-code?collegeCode', { observe: 'response' });
  }
  getAcademicYear(branches, selectedBranch) {
    return branches.filter(x => x.collegeCourseCode === selectedBranch)[0].academicYears;
  }

  getSemesters(academicYears, selectedAcademicYear) {
      return academicYears.filter(x => x.academicYearCode === selectedAcademicYear)[0].semesters;
  }

}
