<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Job Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Job Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="job-details-area pt-100 row m-0">
    <div class="d-none d-md-block col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
    <div class="container col-md-8">
        <div class="row">
            <div class="col-lg-8 py-5">
                <div *ngIf="jobDetail.companyName" class="d-flex justify-content-center mb-4">
                    <span style="font-size: x-large;">
                        <strong>
                            <img *ngIf="companyLogo" [src]="companyLogo"
                                style="height: 3rem; width: 3rem;aspect-ratio: 50%;">&nbsp;&nbsp;&nbsp;{{jobDetail.companyName}}
                        </strong>
                    </span>
                </div>
                <div class="job-details-item">
                    <div class="job-description">
                        <h2>Job Description</h2>
                        <p>{{jobDetail.description }}</p>
                        <span>About company</span>
                        <p>{{jobDetail.companyDescription}}</p>
                    </div>

                    <div class="job-knowledge">
                        <h2>Required Knowledge, Skills, and Abilities</h2>
                        <p>Experince Required: {{jobDetail.experienceRequired}}</p>
                    </div>

                    <div class="job-knowledge">
                        <h2>Education Qualification</h2>
                        <p>{{jobDetail.educationQualification}}</p>

                        <!-- <ul *ngFor="let desc of jobDetail.education_qualification">
                            <li><i class="icofont-hand-drawn-right"></i> {{desc}}</li>
                            
                        </ul> -->
                    </div>

                    <div class="text-center">
                        <div *ngIf="!jobDetail.isAppliedByJobseeker; else alreadyApplied">
                            <div *ngIf="isLastDateExpired(jobDetail.lastDate); else expired">
                                <a class="job-details-btn"
                                    (click)="applyJob(jobDetail.id,candidateID,jobDetail.lastDate); isApplied = true"
                                    [style.pointer-events]="isApplied ? 'none' : 'pointer'">{{ buttonText }}</a>
                            </div>
                            <ng-template #expired>
                                <span class="job-details-btn">date expired</span>
                            </ng-template>
                        </div>
                        <ng-template #alreadyApplied>
                            <span class="job-details-btn">Applied</span>
                        </ng-template>


                    </div>

                </div>
                <div>
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                    </ng-adsense> 
                </div>
            </div>

            <div class="col-lg-4">
                <div class="job-details-item">
                    <div class="job-overview">
                        <h3>Overview</h3>

                        <ul>
                            <li>
                                <i class="icofont-dollar"></i>
                                <p>Offered Salary</p>
                                <!-- <span>{{jobDetail.salaryRange1}} - {{jobDetail.salaryRange2}}</span> -->
                                <ng-container *ngIf="jobDetail.salaryRange1 === 0 && jobDetail.salaryRange2 === 0; else salaryRange">
                                    <span>Not Mentioned</span> 
                                </ng-container>
                                <ng-template #salaryRange>
                                   <span>{{ jobDetail.salaryRange1 }} - {{ jobDetail.salaryRange2 }}</span> 
                                </ng-template>
                            </li>

                            <li>
                                <i class="icofont-users-alt-3"></i>
                                <p>Vacancies</p>
                                <span>{{jobDetail.vacancies}}</span>
                            </li>

                            <li>
                                <i class="icofont-badge"></i>
                                <p>Experince Required</p>
                                <span>{{jobDetail.experienceRequired}} years</span>
                            </li>

                            <li>
                                <i class="icofont-graduate"></i>
                                <p>Qualification</p>
                                <span>{{jobDetail.educationQualification}}</span>
                            </li>
                        </ul>
                    </div>

                    <div class="job-overview job-company">
                        <h3>Company</h3>

                        <ul>
                            <li>
                                <i class="icofont-building-alt"></i>
                                <span>{{jobDetail.companyName}}</span>
                            </li>
                            <li>
                                <i class="icofont-building-alt"></i>
                                <a href={{jobDetail.website}} target="_blank"><small>{{jobDetail.website | slice :0:20 }}..</small></a>
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                <span>{{jobDetail.jobLocation}}</span>
                            </li>
                            <li>
                                <i class="icofont-ui-call"></i>
                                <span>Call : {{jobDetail.contactNo}}</span>
                            </li>

                            <li>
                                <i class="icofont-ui-email"></i>
                                <a href="mailto:{{jobDetail.email}}"><small>{{jobDetail.email | slice :0:18}}..</small></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
</div>