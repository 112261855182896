<div class="exam-creation-area">
    <div class="page-title">
        <span>Aptitude Creation</span>
    </div>
    <div class="conatiner-fluid row m-0">
        <div class="col-md-2">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="container col-md-8 col-sm-12 pb-5">
            <!-- Exam Creation form-->
            <div class="row">
                <form [formGroup]="examForm" (ngSubmit)="createExam()">
                    <div class="row my-4">
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Aptitude Code*</label>
                                <input type="text" class="form-control" placeholder="Test #1" formControlName="examCode"
                                    [ngClass]="{ 'invalid-field': (examForm.get('examCode').invalid && (examForm.get('examCode').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Topic*</label>
                                <input type="text" class="form-control" placeholder="DSA/Sales.."
                                    formControlName="subject"
                                    [ngClass]="{ 'invalid-field': (examForm.get('subject').invalid && (examForm.get('subject').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Exam Date*</label>
                                <input type="date" class="form-control" formControlName="examDate"
                                    [ngClass]="{ 'invalid-field': (examForm.get('examDate').invalid && (examForm.get('examDate').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Start Time*</label>
                                <input type="time" class="form-control" formControlName="startTime"
                                    [ngClass]="{ 'invalid-field': (examForm.get('startTime').invalid && (examForm.get('startTime').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>End Time*</label>
                                <input type="time" class="form-control" formControlName="endTime"
                                    [ngClass]="{ 'invalid-field': (examForm.get('endTime').invalid && (examForm.get('endTime').touched)) }">
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Total Marks*</label>
                                <input type="number" class="form-control" placeholder="100"
                                    formControlName="totalMarksInExam"
                                    [ngClass]="{ 'invalid-field': (examForm.get('totalMarksInExam').invalid && (examForm.get('totalMarksInExam').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Passing Marks*</label>
                                <input type="number" class="form-control" placeholder="35"
                                    formControlName="passingMarksInExam"
                                    [ngClass]="{ 'invalid-field': (examForm.get('passingMarksInExam').invalid && (examForm.get('passingMarksInExam').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>Mandatory Question*</label>
                                <input type="number" class="form-control" placeholder="100"
                                    formControlName="mandatoryQuestn"
                                    [ngClass]="{ 'invalid-field': (examForm.get('mandatoryQuestn').invalid && (examForm.get('mandatoryQuestn').touched)) }">
                            </div>
                        </div>
                        <div class="col-lg-auto">
                            <div class="form-group">
                                <label>No. Of Question*</label>
                                <input type="number" class="form-control" placeholder="100"
                                    formControlName="noOfQuestions"
                                    [ngClass]="{ 'invalid-field': (examForm.get('noOfQuestions').invalid && (examForm.get('noOfQuestions').touched)) }">
                            </div>
                        </div>
                        
                        <div class="col-lg-auto d-flex">
                            <button type="submit" class="btn create-ac-btn">Create</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- Exam List Table-->
            <div class="row" class="exam-list-area">
                <div class="row">
                    <div class="col card card-statistic-4">
                        <div class="align-items-center justify-content-between">
                            <div class="card-content p-3">
                                <h4 class="text-center">Aptitude List</h4>
                                <hr>
                                <span class="SampleLinks"><a href="../../../../assets/Files/questions.xlsx" download="Questions.xlsx">Get Sample Questions Excel</a>&nbsp;&nbsp;&nbsp;<a href="../../../../assets/Files/students.xlsx" download="Students Sample">Get Sample Student Excel</a></span>
                                <hr>
                                <div class="tableBody">
                                    <div class="table-responsive">
                                        <table class="table table-hover ">
                                            <thead>
                                                <tr class="text-center">
                                                    <th>Code</th>
                                                    <th>Topic</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Question</th>
                                                    <th>Candidate</th>
                                                    <th>Exam </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let exam of examsList" class="text-center align-middle">
                                                    <td>{{exam.examCode}}</td>
                                                    <td>{{exam.subject}}</td>
                                                    <td>{{exam.examDate}}</td>
                                                    <td>{{exam.startTime}} - {{exam.endTime}}</td>
                                                    <td>
                                                        <label class="icon-btn" data-info="Upload Questions">
                                                            <input type="file" [disabled]="isOneDayBeforeGivenDate(exam.examDate)"
                                                                (change)="uploadQuestion($event, exam.id)"><i
                                                                class="bi bi-cloud-arrow-up"></i>
                                                        </label>
                                                        &nbsp;
                                                        <a class="icon-btn" routerLink="/aptitude/questions" data-info="View Questions"
                                                            (click)="viewQuesition(exam.id,exam.examCode)"><i class="bi bi-eye"></i>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <label class="icon-btn" data-info="Upload Candidates">
                                                            <input type="file" [disabled]="isOneDayBeforeGivenDate(exam.examDate)"
                                                                (change)="uploadCandidates($event, exam.id)"><i
                                                                class="bi bi-cloud-arrow-up"></i>
                                                        </label>
                                                        &nbsp;
                                                        <a class="icon-btn" routerLink="/aptitude/candiate" data-info="View Candidates"
                                                            (click)="viewCandiate(exam.id,exam.examCode)">
                                                            <i class="bi bi-eye"></i>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a class="icon-btn" target="_blank" data-info="Exam login"
                                                            href="https://app.campusorbit.com/exam/exam-login/{{exam.examCode}}">
                                                            <i class="bi bi-link"></i>
                                                        </a>
                                                        &nbsp;
                                                        <a class="icon-btn" routerLink="/aptitude/result" data-info="Generate result"
                                                            (click)="getResult(exam.id,exam.examCode)">
                                                            <i class="bi bi-table"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>
</div>