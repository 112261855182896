import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../global';
import { HttpClient } from '@angular/common/http';
import { Http } from 'aws-sdk/clients/xray';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamService {

  examIdSubject = new BehaviorSubject<string>('');
  examId$ = this.examIdSubject.asObservable();

  examCodeSubject = new BehaviorSubject<string>('');
  examCode$ = this.examCodeSubject.asObservable();

  constructor(private http:HttpClient) { }

  createExam(formData:any){
    return this.http.post<any>(GlobalConstants.CONNECT_BASE_URL+"/api/jobportal/exam/create-new-exam",formData);
  }
  addQuestion(testId:string,questionList){
    return this.http.put<any>(GlobalConstants.CONNECT_BASE_URL+`/api/jobportal/exam/add-question?testId=${testId}`,questionList, { observe: 'response' });
  }
  addExaminee(testId:string,examineeList){
    return this.http.put<any>(GlobalConstants.CONNECT_BASE_URL+`/api/jobportal/exam/add-examinee?testId=${testId}`,examineeList, { observe: 'response' });
  }
  getAllExamRecruiter(){
    return this.http.get<any>(GlobalConstants.CONNECT_BASE_URL+"/api/exam/staff/all-exam",{observe:'response'});
  }
  getAllQuestion(testId:string){
    return this.http.get<any>(GlobalConstants.CONNECT_BASE_URL+`/api/jobportal/exam/all-question?testId=${testId}`,{observe:'response'})
  }
  getAllCandiate(testId:string){
    return this.http.get<any>(GlobalConstants.CONNECT_BASE_URL+`/api/jobportal/exam/all-examinee?testId=${testId}`,{observe:'response'})
  }
  getExamResult(testId:string){
    return this.http.get<any>(GlobalConstants.CONNECT_BASE_URL+`/api/jobportal/exam/get-result?testId=${testId}`,{observe:'response'})
  }

}
