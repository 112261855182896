import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { JobListComponent } from './components/pages/job-list/job-list.component';
import { FavouriteJobComponent } from './components/pages/favourite-job/favourite-job.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { CandidateListComponent } from './components/pages/candidate-list/candidate-list.component';
import { CandidateDetailsComponent } from './components/pages/candidate-details/candidate-details.component';
import { SingleResumeComponent } from './components/pages/single-resume/single-resume.component';
import { SubmitResumeComponent } from './components/pages/submit-resume/submit-resume.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { RecruiterDashboardComponent } from './components/pages/recruiter-dashboard/recruiter-dashboard.component';
import { CompanyListComponent } from './components/pages/company-list/company-list.component';
import { CompanyDetailsComponent } from './components/pages/company-details/company-details.component';
import { LoginComponent } from './components/pages/login/login.component';
import { CreateAccountComponent } from './components/pages/create-account/create-account.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { SingleProfileComponent } from './components/pages/single-profile/single-profile.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PostedJobsComponent } from './components/pages/posted-jobs/posted-jobs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationStatisticsComponent } from './components/pages/application-statistics/application-statistics.component';
import { RecruiterHomeComponent } from './components/pages/recruiter-home/recruiter-home.component';
import { PopupModalComponentComponent } from './components/pages/popup-modal-component/popup-modal-component.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditJobComponent } from './components/pages/edit-job/edit-job.component';
import { RecruiterProfileComponent } from './components/pages/recruiter-profile/recruiter-profile.component';
import { AppliedHoursPipe } from './components/CustomPipes/AppliedHoursAgoPipe/applied-hours.pipe';
import { RecruiterDetailsComponent } from './components/pages/recruiter-details/recruiter-details.component';
import { AppliedJobComponent } from './components/pages/applied-job/applied-job.component';
import { RequestInterceptor } from './components/services/request.interceptor';
import { AdsenseModule } from 'ng2-adsense';
import { ResumeBuilderComponent } from './components/pages/resume-builder/resume-builder.component';
import { OnlineCvComponent } from './components/pages/online-cv/online-cv.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ExamCandiateView, ExamCreationComponent, ExamQuestionView, ExamResultView } from './components/pages/exam-creation/exam-creation.component';
import { InterviewBotComponent } from './components/pages/interview-bot/interview-bot.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ResumeBuilderMobileComponent } from './components/pages/resume-builder/resume-builder-mobile.component';
@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    JobListComponent,
    FavouriteJobComponent,
    JobDetailsComponent,
    PostAJobComponent,
    CandidateListComponent,
    CandidateDetailsComponent,
    SingleResumeComponent,
    SubmitResumeComponent,
    PricingComponent,
    DashboardComponent,
    CompanyListComponent,
    CompanyDetailsComponent,
    LoginComponent,
    CreateAccountComponent,
    ProfileComponent,
    SingleProfileComponent,
    ErrorComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    BlogDetailsComponent,
    BlogComponent,
    PostedJobsComponent,
    ApplicationStatisticsComponent,
    RecruiterHomeComponent, 
    RecruiterDashboardComponent,
    EditJobComponent, 
    EditJobComponent, 
    RecruiterProfileComponent, 
    AppliedHoursPipe,
    RecruiterDetailsComponent,
    AppliedJobComponent,
    ResumeBuilderComponent,
    OnlineCvComponent,
    ExamCreationComponent,
    ExamCandiateView,
    ExamQuestionView,
    ExamResultView,
    InterviewBotComponent,
    ResumeBuilderMobileComponent,
  ],
  imports: [
    BrowserModule,
    PopupModalComponentComponent,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxDocViewerModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-6119489645323262',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }) 
  ],
  providers: [{ provide: ErrorHandler, useClass: null },{ provide : HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
