import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { GptService } from "../../services/gpt/gpt.service";
import { VoiceRecognitionService } from "../../services/voiceRecognition/voice-recognition.service";
import * as moment from "moment";
import Swal from "sweetalert2";
@Component({
  selector: "app-interview-bot",
  templateUrl: "./interview-bot.component.html",
  styleUrls: ["./interview-bot.component.scss"],
})
export class InterviewBotComponent implements OnInit {
  userInput: string = "";
  botResponse: string = "";
  availableVoices: SpeechSynthesisVoice[] = [];
  selectedVoice: any;
  context = [];
   // ["What is python What is python What is python What is python What is python What is python What is python What is python What is python What is python ","what is java","what is ts","what is js"];
  public isUserSpeaking: boolean = false;

  currentTab = 0;
  btnHint: string = "";
  btnText: string = "Next";
  //Form For the User info
  jobPosition: string = "";
  userEducation: string = "";
  interviewTopic: string = "";
  secretKey: string = "";
  experienceLevel: string = "Fresher";
  questionCount: number = 5;

  //Interview var
  questionGenration = "";
  questionList = [];
  answerList = [];
  timeStamps = [];
  totalTimeTaken: any;
  currentQuestionIndex = 0;
  //Result var
  resultGenration = "";
  resultList = [];
  // { "Grammer": 8, "Vocabulary": 5, "English": 4, "Correctness": 5, "Clarity": 4, "Over_All": 6, 
  // "Conclusion": "The candidate's responses lacked clarity, contained grammatical errors, and had limited technical depth. There is a need for improvement in both language proficiency and technical knowledge."}];
  @ViewChild('chatContainer', { static: false }) chatContainer: ElementRef;
  constructor(
    private gptService: GptService,
    private voiceRecognition: VoiceRecognitionService
  ) {
    this.availableVoices = window.speechSynthesis.getVoices();
  }
  ngOnInit(): void {
    this.selectedVoice = this.availableVoices.find((voice) =>
      voice.name.toLowerCase().includes("mark")
    );
  }
  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }
  startInterviewPopup() {
    let timerInterval;
    let countDown = 10;
    Swal.fire({
      title: "Starting Interview!",
      html: "Generating question please wait <b></b>",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          if (this.questionGenration === "SUCCESSFULL") {
            timer.textContent = countDown.toString() + " sec.";
            countDown--;
            if (countDown <= 0) {
              this.getQuestion();
              Swal.close();
            }
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  }
  generateResultPopup() {
    let timerInterval;
    let countDown = 5;
    Swal.fire({
      title: "Generating Result!",
      html: "Evalutating The Answers Please Wait",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          if (this.resultGenration === "SUCCESSFULL") {
            countDown--;
            if (countDown <= 0) {
              Swal.close();
            }
          }
        }, 500);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  }

  // Tab Controller function
  nextStep() {
    if (this.currentTab === 0) {
      this.currentTab++;
      this.btnText = "Submit";
      this.secretKey = localStorage.getItem("secretKey") || "";
    } else if (this.currentTab === 1) {
      if (
        this.jobPosition != "" &&
        this.userEducation != "" &&
        this.interviewTopic != "" &&
        this.secretKey != ""
      ) {
        localStorage.setItem("secretKey", this.secretKey);
        this.generateQuestion();
        this.currentTab++;
        this.btnText = "Get Results";
        this.totalTimeTaken = moment();
        this.startInterviewPopup();
      }
    } else if (this.currentTab === 2) {
      this.currentTab++;
      const endTime = moment();
      const timeDifference = moment.duration(
        endTime.diff(this.totalTimeTaken)
      );
      this.totalTimeTaken = timeDifference.asMinutes().toFixed(2);
      this.generateResultPopup();
      this.generateResult();
    } else if (this.currentTab === 3) {
      this.currentTab++;
    }
  }
  prevStep() {
    this.currentTab--;
  }
  scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch(err) {}
  }
  speakText(el: string): void {
    const utterance = new SpeechSynthesisUtterance(el);
    const voices = window.speechSynthesis.getVoices();
    const femaleVoice = voices.find((voice) =>
      voice.name.toLowerCase().includes("zira")
    );
    utterance.voice = femaleVoice || this.selectedVoice || voices[0];
    window.speechSynthesis.speak(utterance);
  }
  getQuestion() {
    console.log("speak question of index:", this.currentQuestionIndex);
    let resp = this.context;
    let ques = resp[this.currentQuestionIndex];
    if (!this.questionList.includes(ques)) {
      console.log("adding question to list", this.currentQuestionIndex);
      this.questionList.push(ques);
    }
    this.speakText(ques);
  }
  initVoiceInput() {
    // Subscription for initializing and this will call when user stopped speaking.
    this.voiceRecognition.init().subscribe(() => {
      // User has stopped recording
      // Do whatever when mic finished listening
    });

    // Subscription to detect user input from voice to text.
    this.voiceRecognition.speechInput().subscribe((input) => {
      // Set voice text output to
      this.userInput = input;
    });
  }
  startRecording() {
    this.initVoiceInput();
    this.isUserSpeaking = true;
    this.voiceRecognition.start();
    this.userInput = "";
  }
  stopRecording() {
    if (this.userInput == "") {
      this.userInput = "Don't Know the answer to this question";
    }
    this.voiceRecognition.stop();
    this.isUserSpeaking = false;

    this.answerList.push(this.userInput);
    this.userInput = "";

    this.currentQuestionIndex++;
    if (this.currentQuestionIndex < 5) {
      this.getQuestion();
    }
  }
  clearInput() {
    this.voiceRecognition.stop();
    this.userInput = "";
    this.startRecording();
  }
  generateQuestion() {
    if (
      this.jobPosition != "" &&
      this.userEducation != "" &&
      this.interviewTopic != "" &&
      this.secretKey != ""
    ) {
      let questionSample  = {"QUESTIONS_LIST":["question1","question2",]}
      let questionMessage = [
        {
          role: "system",
          content: "You are an Expert Interviewer's Helper.provide a valid Json questions list based on the user provided prompt, the data schema should be like :" + JSON.stringify(questionSample),
        },
        {
          role: "user",
          content: `Experience Level: ${this.experienceLevel}, Position: ${this.jobPosition}, Topic: ${this.interviewTopic}, Education: ${this.userEducation}, Question Count: ${this.questionCount}`,
        },
      ];
      // "",
      this.gptService
        .generateQuestion(questionMessage, this.secretKey)
        .subscribe(
          (response) => {
            try{
              let data = response.choices[0]?.message.content;
              let parseData = JSON.parse(data);
              this.context = parseData["QUESTIONS_LIST"];console.log(this.context);
              this.questionGenration = "SUCCESSFULL";
            }catch{
              console.log("Unable to unpack the response:",response.choices[0]?.message.content)
              this.generateQuestion();
            }
          },
          (error) => {
            console.error("Error generating response:", error);
            this.botResponse = "Error generating response";
          }
        );
    } else {
      console.log("error.....");
      setTimeout(() => {
        console.log("Timer is done");
        this.questionGenration = "SUCCESSFULL";
      }, 5000);
    }
  }

  generateResult() {
    if (this.questionList.length >= 5 && this.answerList.length >= 5) {
      let resultMessage = [];
      let resultSample = {
            "OVER_ALL_RESULT":{"Grammer": 5,"Vocabulary": 6,"English": 5,"Correctness": 7,"Clarity": 7, 'Over_All':6,"Conclusion":"You Did perform good , need to improve your Communication skill"}
      }
      let c1 = "#Important : You are Task to Evaluate the candidate's response for each question and assing marks on a scale of 0-10, Use a critical approach and Don't be Generous as AI Model.And provide a valid Json Result for overall Interview";
      let c2 = "the data schema should be like :" + JSON.stringify(resultSample);
      resultMessage.push({
        role: "system",
        content: c1 + c2,
      });
      this.questionList.map((question, index) => {
        resultMessage.push({ role: "assistant", content: question });
        resultMessage.push({
          role: "user",
          content: this.answerList[index],
        });
      });

      this.gptService
        .generateResult(resultMessage, this.secretKey)
        .subscribe(
          (response) => {
            try{
              let data = response.choices[0]?.message.content;
              let parseData = JSON.parse(data);
              let resultObj = parseData["OVER_ALL_RESULT"];
              this.resultList = [];
              this.resultList.push(resultObj);
              this.resultGenration = "SUCCESSFULL";
            }catch{
              console.log("Unable to unpack the response:",response.choices[0]?.message.content);
              this.generateResult();
            }
          },
          (error) => {
            console.error("Error generating response:", error);
            this.botResponse = "Error generating response";
            this.resultGenration = "SUCCESSFULL";
          }
        );
    }

  }
  resultRating(marks:number=5){
    const levelArray = [
      "Terrible, Significant improvement needed",
      "Very Poor, Substantial improvement needed",
      "Poor, Considerable improvement needed",
      "Below Average, Moderate improvement needed",
      "Average, Some improvement needed" ,
      "Fair, Adequate performance" ,
      "Good, Solid performance" ,
      "Very Good, Strong performance" ,
      "Excellent, Exceptional performance" ,
      "Outstanding, Outstanding performance" ,
      "Perfect, Exemplary performance"
    ];
    let num = Math.floor(marks);
    return levelArray[num];
  }

}
