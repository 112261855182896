import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appliedHours'
})
export class AppliedHoursPipe implements PipeTransform {


  transform(appliedDate: Date): string {
    const now = moment();
    const duration = moment.duration(now.diff(appliedDate));

    const hours = duration.asHours();
    if (hours < 24) {
        const hoursAgo = moment(appliedDate).fromNow();
        return `${hoursAgo}`;
    } else {
      const daysAgo = Math.floor(duration.asDays());
      return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    }
  }

}
