<!-- <ng-container *ngIf="display$ | async as display">
    <section [class.open]="display === 'open'"
             (click)="close()">
      <div (click)="$event.stopPropagation()">
        <button class="close"
                type="button" 
                (click)="close()">X</button>
  
        <h1>Hello Nayan</h1>
      </div>
    </section>
  </ng-container> -->


  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
    </div>
    <div class="modal-body">
      <img src="assets/img/icons8-ok.gif" alt=""  style="height: 500px; width:600px">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Okay</button>
    </div>
  </ng-template>