import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { UserResume} from './user-interface'

export class ResumeTemplate6{
    currentY = 15;

    public BasicTwo(user:UserResume){
        this.currentY = 15;
        const doc = new jsPDF();
        const rightMargin = doc.internal.pageSize.width - 30;
        const font = "Helvetica";

        doc.setFont(font,'Bold');
        doc.setFontSize(30);doc.setTextColor(57, 21, 107);
        let  fullName = `${user.firstName} ${user.lastName}`;
        let centerPos = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(fullName)/2);
        doc.text(fullName, centerPos, this.currentY);this.currentY += 10;

        doc.setFontSize(12);doc.setFont(font,'',400);doc.setTextColor(0);
        let userContact = `Email: ${user.email} Contact: ${user.contactNumbers[0].number}`;
        let userAccounts = `Address: ${user.strAddress}`
        let centerPos3 = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(userContact)/2);
        doc.text(userContact, centerPos3, this.currentY);this.currentY +=5;
        centerPos3 = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(userAccounts)/2);
        doc.text(userAccounts, centerPos3, this.currentY);this.currentY +=5;
        
        this.addLine(doc);this.currentY+=5;
        this.addSubHeading(doc,'SUMMARY',5);
        this.addText(doc,user.description,15,rightMargin);

        this.addLine(doc);this.currentY+=5;
        this.addSubHeading(doc,'WORK EXPERIENCE',5);
        this.addText(doc,user.workExperience,15,rightMargin);

        this.addLine(doc);this.currentY+=5;
        this.addSubHeading(doc,'EDUCATION',5);
        doc.setFont(font);
        doc.setFontSize(10);doc.setTextColor(38, 38, 38);
        user.educationalDetails
            .sort((a, b) => parseInt(b.from_year) - parseInt(a.from_year))
            .map(edu => {
                // doc.text(`${edu.stream}`,15,this.currentY);this.currentY +=5
                doc.text(`${edu.degree}`,15,this.currentY);this.currentY +=5
                doc.setTextColor(100, 100, 100);
                doc.text(`${edu.institute} `,15,this.currentY);
                doc.setTextColor(38, 38, 38);
                let text = `---${edu.from_year} - ${edu.to_year}`;
                let x = doc.internal.pageSize.width - doc.getTextWidth(text) - 15
                doc.text(text,x,this.currentY);this.currentY +=7
            });this.currentY+=2;

        this.addLine(doc);this.currentY+=5;
        this.addSubHeading(doc,'SKILL',5);
        let skill1 = user.skills.slice(0,5);
        let skill2 = user.skills.slice(5,10);
        let Y = this.currentY;
        skill1
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let level = this.skillLevel(skill.skillProficiency);
                let skillText = `• ${skill.name} | `;
                doc.setFont(font,'Bold');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(skillText,15,this.currentY);
                let x = doc.getTextWidth(skillText) + 15;
                doc.setFont(font,'');doc.setFontSize(10);doc.setTextColor(100, 100, 100);
                doc.text(`${level}`,x,this.currentY);
                this.currentY+=5;
            });
        let Y2 = this.currentY;
        this.currentY = Y;
        skill2
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let mid = doc.internal.pageSize.getWidth()/2 + 5;
                let level = this.skillLevel(skill.skillProficiency);
                let skillText = `• ${skill.name} | `;
                doc.setFont(font,'Bold');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(skillText,mid,this.currentY);
                let x = doc.getTextWidth(skillText);
                doc.setFont(font,'');doc.setFontSize(10);doc.setTextColor(100, 100, 100);
                doc.text(`${level}`,x+mid,this.currentY);
                this.currentY+=5;
            });
        this.currentY = Y2;
        
        this.addLine(doc);this.currentY+=5
        this.addSubHeading(doc,'RESEARCH',5);
        this.addText(doc,user.research,15,rightMargin);

        this.addWatermark(doc,'Powered by  Campus Orbit');
        return doc;
    }

    private addSubHeading(doc: any, text: string,plusY:number, align: string = 'left') {
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.setTextColor(57, 21, 107);
        doc.text(text, 10, this.currentY, { align });
        this.currentY += plusY; // Increment Y position
    }
    private addText(doc, text, x, maxWidth,extraSpace=0) {
        doc.setFont('Helvetica');
        doc.setFontSize(10);doc.setTextColor(38, 38, 38);
        // Ensure x + maxWidth does not exceed page width
        if (x + maxWidth > doc.internal.pageSize.width) {
            maxWidth = doc.internal.pageSize.width - x - 15; // Adjust maxWidth
        }
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        doc.text(text, x, this.currentY, { maxWidth: maxWidth -extraSpace});
        this.currentY += blockHeight + 5;
    }
    private skillLevel(skillProficiency){
        const levelArray = ["Beginner","Intermediate","Proficient","Expert"];
        if(skillProficiency <= 50){return levelArray[0]}
        if(skillProficiency > 50 && skillProficiency <=70){return levelArray[1]}
        if(skillProficiency > 70 && skillProficiency <=85){return levelArray[2]}
        if(skillProficiency > 85 && skillProficiency <=95){return levelArray[3]}
        if(skillProficiency > 95 && skillProficiency <=100){return levelArray[4]}
    }
    private addLine(doc: any, height: number = 0.3) {
        doc.setLineWidth(height);
        doc.setDrawColor(57, 21, 107);
        doc.line(10, this.currentY, doc.internal.pageSize.width - 10, this.currentY);
        this.currentY += height + 5; // Increment Y position
    }
    private addWatermark(doc:any, watermarkText: string) {
        doc.setFontSize(10);
        doc.setFont('Helvetica','Oblique');
        doc.setTextColor(100, 100, 100);
        const watermarkX = (doc.internal.pageSize.width / 2) + (doc.getTextWidth(watermarkText)/2);
        const watermarkY = doc.internal.pageSize.height - 5;
        doc.textWithLink(watermarkText, watermarkX, watermarkY, { url: 'https://job.campusorbit.com', align:'right' });
    }
}