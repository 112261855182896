<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="single-profile-area pt-100 row m-0">
    <div class="d-none d-md-block col-md-2">
        <div class="row ">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
        <div class="row ">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
    </div>
    <div class="container col-md-8" id="candidateProfileContainer">
        <div class="row">
            <div class="col-lg-5">
                <div class="single-profile-item">
                    <div class="image"> <img [src]="profilePhoto" alt="Profile"> </div>
                    <div class="username-div">
                        <h2>{{candidateInfo?.firstName}}&nbsp;&nbsp;{{candidateInfo?.lastName}}</h2>
                        <span *ngIf="candidateInfo?.jobTitle">{{candidateInfo?.jobTitle}}</span>
                    </div>
                    <div class="single-profile-left">
                        <div class="single-profile-contact">
                            <h3>Contact Info</h3>

                            <ul>
                                <li><i class="icofont-email "></i> <a
                                        href="mailto:{{candidateInfo?.email}}">{{candidateInfo?.email}}</a></li>
                                <li><i class="icofont-ui-call "></i> <a
                                        href="{{candidateInfo?.contactNumbers[0]?.number}}">{{candidateInfo?.contactNumbers[0]?.number}}</a>
                                </li>
                                <li><i class="icofont-location-pin "></i>{{candidateInfo?.strAddress}}</li>
                            </ul>
                        </div>

                        <div class="single-profile-social">

                            <h3>Social Links</h3>
                            <ul>
                                <li><i class="icofont-facebook"></i> <a href="{{candidateInfo?.socialLinksFacebook }}"
                                        target="_blank">{{candidateInfo?.socialLinksFacebook | slice:0:30}}</a></li>
                                <li><i class="icofont-instagram"></i> <a href="{{candidateInfo?.socialLinksInstagram}}"
                                        target="_blank">{{candidateInfo?.socialLinksInstagram | slice:0:30}}</a></li>
                                <li><i class="icofont-linkedin"></i> <a href="{{candidateInfo?.socialLinksLinkedin}}"
                                        target="_blank">{{candidateInfo?.socialLinksLinkedin | slice:0:30}}</a></li>
                                <li><i class="icofont-twitter"></i> <a href="{{candidateInfo?.socialLinksTwitter}}"
                                        target="_blank">{{candidateInfo?.socialLinksTwitter | slice:0:30}}</a></li>
                            </ul>
                        </div>
                        <div class="single-profile-skills">
                            <h3>My Skills</h3>

                            <div class="skill" *ngFor="let skill of candidateInfo?.skills; let i = index">
                                <p>{{skill.name}}</p>
                                <div class="skill-bar skill1 wow slideInLeft animated animated animated animated animated"
                                    style="visibility: visible;" [style.width.%]="[skill.skillProficiency]">
                                    <span class="skill-count1">{{skill.skillProficiency}}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="single-profile-item">
                    <div class="single-profile-right">
                        <div class="single-profile-name">
                            <a (click)="viewResume(candidateInfo?.resume_link)" style="cursor: pointer;">View CV <i
                                    class="icofont-eye-alt"></i></a>
                            <a (click)="downloadResume(candidateInfo?.resume_link, candidateInfo?.firstName, candidateInfo?.lastName)"
                                target="_blank" style="cursor: pointer;">Download CV <i
                                    class="icofont-download"></i></a>
                            <a (click)="openModal()" style="cursor: pointer;">Get Online CV</a>
                            <!-- <a (click)="saveProfileAsPDF(candidateInfo?.firstname, candidateInfo?.lastName)" >Download Profile</a> -->
                        </div>

                        <div class="single-profile-textarea">
                            <div class="single-profile-heading">
                                <span> </span>
                                <h3>Description</h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p style="white-space: pre-line;">{{candidateInfo?.description}}</p>
                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Work Experiences</h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p style="white-space: pre-line;">{{candidateInfo?.workExperience}}</p>

                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Research</h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p style="white-space: pre-line;">{{candidateInfo?.research}}</p>

                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>Education</h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Institute</th>
                                                <th>Degree</th>
                                                <th>Stream</th>
                                                <th>From</th>
                                                <th>To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of candidateInfo?.educationalDetails"
                                                style="font-size: small;">
                                                <td>{{ data.institute }}</td>
                                                <td>{{ data.degree }}</td>
                                                <td>{{ data.stream }}</td>
                                                <td>{{ data.from_year }}</td>
                                                <td>{{ data.to_year }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="row ">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
        <div class="row ">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
    </div>
</div>
<!-- Modal code -->
<ng-template #copyOnlineCvModal let-modal>
    <div class="modal-wrapper">
        <div class="modal-content">
            <div class="modal-header">
                <span>Copy Your Online CV Link</span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="border:0;"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <div class="input-group mb-2">
                    <input value="{{url}}/myonlinecv/{{userName}}" type="text" id="cvLinkInput" class="form-control"
                        placeholder="Input group example" aria-label="Input group example"
                        aria-describedby="btnGroupAddon2">
                    <div class="input-group-text" id="btnGroupAddon2" (click)="copyToClipboard()">
                        <i *ngIf="copy == false" class="bi bi-copy"></i>
                        <i *ngIf="copy == true" class="bi bi-check2-circle"></i>
                    </div>
                </div>
                <div class="progress" role="progressbar" aria-label="Success example" [attr.aria-valuenow]="progress"
                    aria-valuemin="0" aria-valuemax="100" style="height: 5px">
                    <div class="progress-bar" [style.width.%]="progress" style="background-color: #4bcc5a;"></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>