import { Component, OnInit } from '@angular/core';
import { CandidateServicesService } from "../../services/candidate-services/candidate-services.service";
import { AccountService } from '../../services/acccount/account.service';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ResumeTemplate1 } from './resume-template1';
import { ResumeTemplate2 } from './resume-template2';
import { ResumeTemplate3 } from './resume-template3';
import { ResumeTemplate4 } from './resume-template4';
import { ResumeTemplate5 } from './resume-template5';
import { ResumeTemplate6 } from './resume-template6';

@Component({
  selector: 'app-resume-builder',
  templateUrl: './resume-builder.component.html',
  styleUrls: ['./resume-builder.component.scss']
})
export class ResumeBuilderComponent implements OnInit {
  pdfMaker1:ResumeTemplate1;pdfMaker2:ResumeTemplate2;pdfMaker3:ResumeTemplate3;pdfMaker4:ResumeTemplate4;pdfMaker5:ResumeTemplate5;
  pdfMaker6:ResumeTemplate6
  data:any;
  userName:string;
  safeResumeUrl: any;
  candidateId:string;
  file: SafeResourceUrl;
  fileForMobile:string;
  Tip:String='Resume Preview';

  constructor(
    private candidateService:CandidateServicesService,private accountService : AccountService,
    private sanitizer: DomSanitizer) {
      this.pdfMaker1 = new ResumeTemplate1();
      this.pdfMaker2 = new ResumeTemplate2(candidateService);
      this.pdfMaker3 = new ResumeTemplate3(candidateService);
      this.pdfMaker4 = new ResumeTemplate4();
      this.pdfMaker5 = new ResumeTemplate5(candidateService);
      this.pdfMaker6 = new ResumeTemplate6();
    }

  ngOnInit(): void {
    this.userName = this.accountService.getUsername()
    this.candidateService.candidateListGetById(this.userName).subscribe((response) => {
      this.data = response.body;
    })
    this.accountService.getUserId().subscribe((resp:any)=>{
      this.candidateId = resp.body
    })
  }
  capitalizeAndTrim(value: string): string {
    return value.trim().toUpperCase();
  }
  showErrorAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: errorMessage,
    });
  }

  tryPopUp(){
    Swal.fire({
      title: 'Creating Resume',
      text: 'Please wait...',
      timer: 2000,
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }
  catchPopUp(errorMessage:string= "Compelete Your Profile To Get Your Resume"){
    this.Tip = errorMessage;
    Swal.fire({
      icon: 'error',
      title: 'Error',
    });
    Swal.fire({
      icon: 'error',
      title: 'Error Occured',
      text: errorMessage,
      timer: 2000,
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }

  loadDocument1(){
    try{
      this.tryPopUp();
      const doc = this.pdfMaker1.basicTemplate(this.data);
      const pdfData = doc.output('datauristring');
      setTimeout(() => {
        this.file = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData); 
        this.fileForMobile = pdfData
      },1500);
      this.Tip='Resume Preview';
    }catch{
      this.catchPopUp();
    }
  }
  async loadDocument2(){
    try{
      this.tryPopUp();
      const doc = await this.pdfMaker2.twoColumnImageTemplate(this.data);
      const pdfData = doc.output('datauristring');
      setTimeout(() => {
        this.file = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData);
        this.fileForMobile = pdfData
      },1500);
      this.Tip = "Tip Upload Circular Photo"
    }catch{
      this.catchPopUp();
    } 
  }
  async loadDocument3(){
    try{
      this.tryPopUp();
      const doc = await this.pdfMaker3.profileBannerTemplate(this.data);
      const pdfData = doc.output('datauristring');
      setTimeout(() => {
        this.file = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData); 
        this.fileForMobile = pdfData
      },1500);
      this.Tip = "Tip Upload A Square Photo"
    }catch{
      this.catchPopUp();
    }
  }
  loadDocument4(){
    try{
      this.tryPopUp();
      const doc = this.pdfMaker4.monoToneTemplate(this.data);
      const pdfData = doc.output('datauristring');
      setTimeout(() => {
        this.file = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData); 
        this.fileForMobile = pdfData
      },1500);
      this.Tip='Resume Preview';
    }catch{
      this.catchPopUp();
    }
    
  }
  async loadDocument5(){
    try{
      this.tryPopUp();
      const doc = await this.pdfMaker5.sunnySectionTemplate(this.data);
      const pdfData = doc.output('datauristring');
      setTimeout(() => {
        this.file = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData); 
        this.fileForMobile = pdfData
      },1500);
      this.Tip = "Tip Upload circular photo With Green Background"
    }catch{
      this.catchPopUp();
    }
    
  }
  loadDocument6(){
    try{
      this.tryPopUp();
    const doc = this.pdfMaker6.BasicTwo(this.data);
    const pdfData = doc.output('datauristring');
    setTimeout(() => {
      this.file = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData); 
      this.fileForMobile = pdfData
    },1500);
    this.Tip='Resume Preview';
  }catch{
    this.catchPopUp();
  }
  }

  previewOnMobile(){
    try{
      const newWindow = window.open(this.fileForMobile, '_blank');

      if (!newWindow) {
        alert('Please allow pop-ups for this site to view the PDF.');
      }
    }catch(error){
      console.error('Error:', error);
      this.catchPopUp();
    }
    
  }
  downloadOnMobile(){
    try {
      if(this.fileForMobile == null || this.fileForMobile == undefined){
        throw Error("No Template Selected, First select a template to download");
      }
      const link = document.createElement('a');
      link.href = this.fileForMobile;
      link.download = 'resume.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.Tip = 'Resume Downloaded';
    } catch (error) {
      console.error('Error:', error);
      this.catchPopUp(error);
    }
  
  }

}
