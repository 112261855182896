import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { UserResume} from './user-interface'
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service'

export class ResumeTemplate4{
    currentY = 15;

    public monoToneTemplate(user:UserResume){
        this.currentY = 15;
        const doc = new jsPDF();
        const rightMargin = doc.internal.pageSize.width - 10;

        doc.setFillColor(50, 50, 50);
        doc.roundedRect(0,0,doc.internal.pageSize.width,40,0,0,'F');
        doc.setFillColor(220, 220, 220);
        doc.roundedRect(0,40,doc.internal.pageSize.width,doc.internal.pageSize.height,0,0,'F');

        doc.setFont('Times', 'Bold',1000);
        doc.setFontSize(30);doc.setTextColor(255);
        let  fullName = `${user.firstName} ${user.lastName}`;
        let centerPos = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(fullName)/2);
        doc.text(fullName, centerPos, this.currentY);this.currentY += 10;
        doc.setFont('Times', 'Roman',300);
        doc.setFontSize(15);
        let  jobTitle = `${user.jobTitle}`;
        let centerPos2 = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(jobTitle)/2);
        doc.text(jobTitle,centerPos2,this.currentY);this.currentY+=10;

        doc.setFontSize(12);doc.setFont('Times','Roman',700);doc.setTextColor(255);
        let userContact = `Email: ${user.email}     Contact: ${user.contactNumbers[0].number}     Address: ${user.strAddress}`;
        let centerPos3 = (doc.internal.pageSize.width / 2) - (doc.getTextWidth(userContact)/2);
        doc.text(userContact, centerPos3, this.currentY);this.currentY +=5;
        
        doc.setDrawColor(255);doc.setLineWidth(1);
        doc.line(0, 40, doc.internal.pageSize.width, 40);this.currentY+=10;

        this.addSubHeading(doc,'SUMMARY',7);
        this.addText(doc,user.description,15,rightMargin);

        this.addSubHeading(doc,'WORK EXPERIENCE',7);
        this.addText(doc,user.workExperience,15,rightMargin);

        this.addSubHeading(doc,'EDUCATION',7);
        doc.setFont("Helvetica");
        doc.setFontSize(10);doc.setTextColor(0);
        user.educationalDetails
            .sort((a, b) => parseInt(b.from_year) - parseInt(a.from_year))
            .map(edu => {
                doc.text(`${edu.stream}`,15,this.currentY);this.currentY +=5
                doc.text(`${edu.degree}`,15,this.currentY);this.currentY +=5
                doc.text(`${edu.institute} `,15,this.currentY);
                let text = `---${edu.from_year} - ${edu.to_year}`;
                let x = doc.internal.pageSize.width - doc.getTextWidth(text) - 10
                doc.text(text,x,this.currentY);this.currentY +=7
            });this.currentY+=2;
    
        this.addSubHeading(doc,'RESEARCH',7);
        this.addText(doc,user.research,15,rightMargin);

        this.addSubHeading(doc,'SKILLS',7);
        let Y = this.currentY; // to make two column
        let skills1 = user.skills.slice(0,5);
        let skills2 = user.skills.slice(5,10);
        skills1
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let level = this.skillLevel(skill.skillProficiency)
                doc.setFont('Helvetica');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(`${skill.name}`,15,this.currentY);
                let r = (doc.internal.pageSize.width /2) - doc.getTextWidth(level) - 10;
                doc.setFont('Helvetica');doc.setFontSize(10);doc.setTextColor(105, 105, 105);
                doc.text(`-- ${level}`,r,this.currentY);
                this.currentY+=5
            })
        this.currentY = Y;
        skills2
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let y = (doc.internal.pageSize.width/2) + 5;
                let level = this.skillLevel(skill.skillProficiency)
                doc.setFont('Helvetica');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(`${skill.name}`,y,this.currentY);
                let r = doc.internal.pageSize.width  - doc.getTextWidth(level) - 15;
                doc.setFont('Helvetica');doc.setFontSize(10);doc.setTextColor(105, 105, 105);
                doc.text(`-- ${level}`,r,this.currentY);
                this.currentY+=5
            })

        this.addWatermark(doc,'Powered by  Campus Orbit');
        return doc
    } 

    private addSubHeading(doc: any, text: string,plusY:number) {
        doc.setFont('Times', 'Bold',1000);
        doc.setFontSize(15);doc.setTextColor(255);
        let textDimen = doc.getTextDimensions(text);
        doc.setFillColor(50, 50, 50);
        doc.roundedRect(8,this.currentY - textDimen.h + 1,textDimen.w + 5,textDimen.h,0,0,'F');
        doc.text(text, 10, this.currentY);
        
        this.currentY += plusY; // Increment Y position
    }
    private addText(doc, text, x, maxWidth,extraSpace=0) {
        doc.setFont('Helvetica');
        doc.setFontSize(10);doc.setTextColor(0);
        // Ensure x + maxWidth does not exceed page width
        if (x + maxWidth > doc.internal.pageSize.width) {
            maxWidth = doc.internal.pageSize.width - x - 10; // Adjust maxWidth
        }
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        doc.text(text, x, this.currentY, { maxWidth: maxWidth -extraSpace});
        this.currentY += blockHeight + 10;
    }
    private addWatermark(doc:any, watermarkText: string) {
        doc.setFontSize(10);
        doc.setFont('italic');
        doc.setTextColor(100, 100, 100);
        const watermarkX = doc.internal.pageSize.width - 10;
        const watermarkY = doc.internal.pageSize.height - 5;
        doc.textWithLink(watermarkText, watermarkX, watermarkY, { url: 'https://job.campusorbit.com', align:'right' });
    }
    private skillLevel(skillProficiency){
        const levelArray = ["Beginner","Intermediate","Proficient","Expert"];
        if(skillProficiency <= 50){return levelArray[0]}
        if(skillProficiency > 50 && skillProficiency <=70){return levelArray[1]}
        if(skillProficiency > 70 && skillProficiency <=85){return levelArray[2]}
        if(skillProficiency > 85 && skillProficiency <=95){return levelArray[3]}
        if(skillProficiency > 95 && skillProficiency <=100){return levelArray[4]}
    }

}