<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!-- Links for non Logged-in Home-->
                        <ng-container *ngIf="!isUserLoggedIn; else loggedIn">
                            <li class="nav-item">
                                <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home </a>
                            </li>
                            <li *ngIf="router.url === '/' " class="nav-item d-none d-lg-block">
                                <a routerLink="" (click)="triggerScrollFunction()" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Jobs</a>
                            </li>
                        </ng-container>
                        <!-- Links for Logged-in Home-->
                        <ng-template #loggedIn>
                            <li class="nav-item" *ngIf=" role === 'JOBSEEKER' || role === 'STUDENT_DEFAULT' ">
                                <a routerLink="/candidate" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home </a>
                            </li>

                            <li class="nav-item" *ngIf="role === 'RECRUITER' || role === 'TPO' ">
                                <a routerLink="/recruiter" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home </a>
                            </li>
                        </ng-template>

                        <!-- Links for Recruiter -->
                        <ng-container *ngIf="(role === 'RECRUITER' || role === 'TPO');else jobSeekerProf ">
                            <li *ngIf="role=='RECRUITER' || role === 'TPO' " class="nav-item">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item" *ngIf="role === 'TPO' ">
                                        <a routerLink="/candidate-list" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Candidate List</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/post-a-job" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Post A Job</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/posted-jobs" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Posted Jobs</a>
                                    </li>
                                </ul>
                            </li>
                            <li *ngIf="role=='RECRUITER' || role === 'TPO' " class="nav-item">
                                <a routerLink="/aptitude" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Aptitude</a>
                            </li>
                        </ng-container>
                        <!-- Links for JobSeeker -->
                        <ng-template #jobSeekerProf>
                            <li class="nav-item" *ngIf="isUserLoggedIn">
                                <a routerLink="/resume-builder" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >Resume</a>
                            </li>
                            <li class="nav-item" *ngIf="isUserLoggedIn && role === 'STUDENT_DEFAULT' " >
                                <a routerLink="/interview" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >AI Interview</a>
                            </li>
                            <li *ngIf="role=='JOBSEEKER' || role === 'STUDENT_DEFAULT' " class="nav-item">
                                <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/job-list" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">All Jobs</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/applied-job" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Applied Jobs</a>
                                    </li>
                                </ul>
                            </li>
                        </ng-template>
                        <li *ngIf="isUserLoggedIn" class="nav-item nav_controlers">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Account</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item nav_controlers"
                                    *ngIf="role =='JOBSEEKER' || role === 'STUDENT_DEFAULT' "><a
                                        routerLink="/candidate-details" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Profile <i
                                            class="icofont-user"></i></a></li>

                                <li class="nav-item nav_controlers " *ngIf="role=='RECRUITER' || role === 'TPO' "><a
                                        routerLink="recruiter-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Profile <i
                                            class="icofont-user"></i></a></li>

                                <li class="nav-item nav_controlers"
                                    *ngIf="role=='JOBSEEKER' || role === 'STUDENT_DEFAULT' "><a routerLink="dashboard"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Edit Profile &nbsp;<i
                                            class="icofont-dashboard"></i></a></li>

                                <li class="nav-item nav_controlers" *ngIf="role=='RECRUITER' || role === 'TPO' "><a
                                        routerLink="recruiter-dashboard" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Edit Profile &nbsp;<i
                                            class="icofont-dashboard"></i></a></li>

                                <li class="nav-item nav_controlers"><a routerLink="/login" class="nav-link"
                                        routerLinkActive="active" (click)="logout()" (touchstart)="logout()"
                                        [routerLinkActiveOptions]="{exact: true}">Logout</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item">
                            <a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a href="https://www.campusorbit.com/" target="_blank">Website</a>
                        </li>
                        <li *ngIf="!isUserLoggedIn" class="nav-item nav_controlers">
                            <a routerLink="/login"><i class="icofont-plus-square"></i> Login</a>
                        </li>
                        <li *ngIf="!isUserLoggedIn" class="nav-item nav_controlers">
                            <a routerLink="/create-account" [queryParams]="{ name: 'Applicant' }"><i class="icofont-user-alt-4"></i>SignUp</a>
                        </li>

                        
                    </ul>

                    <div *ngIf="!isUserLoggedIn" class="common-btn d-none d-lg-block">
                        <a class="login-btn" routerLink="/login"><i class="icofont-plus-square"></i> Login</a>
                        <a class="sign-up-btn" routerLink="/create-account" [queryParams]="{ name: 'Applicant' }"><i class="icofont-user-alt-4"></i>SignUp</a>
                    </div>

                    <ul class="navbar-nav">
                        <li *ngIf="isUserLoggedIn" class="nav-item py-0">
                            <a href="javascript:void(0)" click="mobileNav()" class="nav-link dropdown-toggle ">
                                <i class="bi bi-person-fill-down" style="font-size: 30px;padding: 0;"></i>&nbsp;&nbsp;&nbsp;<span style="color: black;">Profile</span>
                            </a>
                            <ul class="dropdown-menu" *ngIf="isUserLoggedIn ">
                                <li class="nav-item" *ngIf="role=='JOBSEEKER' || role === 'STUDENT_DEFAULT'"><a
                                        routerLink="candidate-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Profile <i
                                            class="icofont-user"></i></a></li>
                                <li class="nav-item" *ngIf="role=='RECRUITER' || role === 'TPO' "><a
                                        routerLink="recruiter-details" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Profile <i
                                            class="icofont-user"></i></a></li>
                                <li class="nav-item" *ngIf="role=='JOBSEEKER' || role === 'STUDENT_DEFAULT' "><a
                                        routerLink="dashboard" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Edit Profile
                                        &nbsp;<i class="icofont-dashboard"></i></a></li>
                                <li class="nav-item" *ngIf="role=='RECRUITER' || role === 'TPO' "><a
                                        routerLink="recruiter-dashboard" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Edit Profile
                                        &nbsp;<i class="icofont-dashboard"></i></a></li>
                                <li class="nav-item" *ngIf="isUserLoggedIn"><a routerLink="/login" class="nav-link"
                                        (click)="logout()" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Logout <i
                                            class="icofont-logout"></i></a></li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </nav>
        </div>
    </div>

</div>