<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Company Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Company Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="company-details-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="company-details-item">
                    <div class="company-details-logo">
                        <img src="assets/img/home-1/companies/1.png" alt="Logo">

                        <div class="company-logo-inner">
                            <h3>Winbrans.com</h3>
                            <p><i class="icofont-location-pin"></i>210-27 Quadra, Market Street, Victoria Canada</p>
                        </div>
                    </div>

                    <div class="company-details-share">
                        <span>Share:</span>

                        <ul>
                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>

                    <div class="company-details-find">
                        <h3>Find Us:</h3>

                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-web"></i> gable.com</a></li>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i> gable.com</a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i> gable.com</a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i> gable.com</a></li>
                            <li><a href="#" target="_blank"><i class="icofont-google-plus"></i> gable.com</a></li>
                        </ul>
                    </div>

                    <div class="company-details-description">
                        <h3>Description</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </div>

                    <div class="text-center">
                        <a class="job-details-btn" routerLink="/create-account">Apply Now</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="company-details-item">
                    <div class="single-resume-category">
                        <h3>Job Categories</h3>

                        <ul>
                            <li><a routerLink="/">Web Developer</a><span>(5)</span></li>
                            <li><a routerLink="/">Web Designer</a><span>(2)</span></li>
                            <li><a routerLink="/">UX/UI Designer</a><span>(7)</span></li>
                            <li><a routerLink="/">Marketing</a><span>(0)</span></li>
                            <li><a routerLink="/">SEO</a><span>(1)</span></li>
                            <li><a routerLink="/">Networking</a><span>(0)</span></li>
                        </ul>
                    </div>

                    <div class="single-resume-category single-resume-types">
                        <h3>Job Locations</h3>

                        <ul>
                            <li><a href="#">New York</a><span>(4)</span></li>
                            <li><a href="#">Washington</a><span>(5)</span></li>
                            <li><a routerLink="/">Chicago</a><span>(3)</span></li>
                            <li><a routerLink="/">Houston</a><span>(1)</span></li>
                            <li><a routerLink="/">Los Angeles</a><span>(0)</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>