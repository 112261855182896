import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { UserResume} from './user-interface'
export class ResumeTemplate1 {

    private currentY = 20;

    public basicTemplate(user: UserResume) {
        this.currentY = 20;
        const doc = new jsPDF()
        const rightMargin = doc.internal.pageSize.width - 20;

        // Add header with user's name
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(24);
        doc.text(`${user.firstName} ${user.lastName}`, 10, this.currentY, { align:"justify"});this.currentY +=5
        if(user.jobTitle){
            doc.setFontSize(10);doc.setTextColor(80, 150, 255);
            doc.text(user.jobTitle,10,this.currentY);doc.setTextColor(0);this.currentY +=5
        };

        // Add contact information
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Email: ${user.email}    Contact: ${user.contactNumbers[0].number}     Address: ${user.strAddress}`, 10, this.currentY);this.currentY +=5
        this.addLine(doc);

        // professional synopsis (description)
        this.addSubHeading(doc,'Professional Synopsis',5);
        this.addText(doc,user.description,10,rightMargin);
        this.addLine(doc);

        // Add a section for work experience
        this.addSubHeading(doc,'Work Experience',5);
        this.addText(doc, user.workExperience, 10, rightMargin);
        this.addLine(doc);

        // Add a section for educational details
        this.addSubHeading(doc, 'Educational Details',5);
        this.addTable(doc, user.educationalDetails
            .sort((a, b) => parseInt(b.from_year) - parseInt(a.from_year))
            .map(edu => [edu.degree, edu.stream, edu.institute, `${edu.from_year} - ${edu.to_year}`]),10);
        this.addLine(doc);

        // Add a section for skills
        this.addSubHeading(doc, 'Skills',5);
        this.addTable(doc, user.skills.map(skill => [skill.name, `${skill.skillProficiency}%`]),5);
        this.addLine(doc);

        this.addSubHeading(doc,'Research',5)
        this.addText(doc,user.research,10, rightMargin);
        this.addLine(doc);

        this.addWatermark(doc,'Powered by  Campus Orbit');
        return doc;
    }

    // Methods for creating different Components of resume
    private addSubHeading(doc: any, text: string,plusY:number, align: string = 'left') {
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        doc.setTextColor(80, 150, 255);
        doc.text(text, 10, this.currentY, { align });
        this.currentY += plusY; // Increment Y position
    }
    private addText(doc, text, x, maxWidth,extraSpace=0) {
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);doc.setTextColor(0);
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        if (x + maxWidth > doc.internal.pageSize.width) {
            maxWidth = doc.internal.pageSize.width - x - 10; // Adjust maxWidth
        }
        doc.text(text, x, this.currentY, { maxWidth: maxWidth - extraSpace});
        this.currentY += blockHeight;
    }
    private addTable(doc: any, data: any[],plusY:number) {
        doc.autoTable({
          head: [],
          body: data,
          startY: this.currentY,
          margin: { top: 10 },
          alternateRowStyles: {fillColor : [200, 255, 170]},
          tableLineColor: [0,0,0],
        });
        this.currentY = doc.autoTable.previous.finalY + plusY; // Update the current Y position
    }
    private addLine(doc: any, height: number = 0.3) {
        doc.setLineWidth(height);
        doc.setDrawColor(0);
        doc.line(10, this.currentY, doc.internal.pageSize.width - 10, this.currentY);
        this.currentY += height + 5; // Increment Y position
    }    
    private addWatermark(doc:any, watermarkText: string) {
        doc.setFontSize(12);
        doc.setFont('italic');
        doc.setTextColor(100, 100, 100);
        const watermarkX = doc.internal.pageSize.width - 10;
        const watermarkY = doc.internal.pageSize.height - 5;
        doc.textWithLink(watermarkText, watermarkX, watermarkY, { url: 'https://job.campusorbit.com', align:'right' });
    }
}