import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service';
import { S3uploadapiService } from '../../services/s3upload/s3uploadapi.service';
import Swal from 'sweetalert2';
import { AccountService } from '../../services/acccount/account.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { HttpResponse } from '@angular/common/http';
import { PopupService } from '../../services/other/popup.service';

function toYearGreaterThanFromYearValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const fromYear = control.get('from_year').value;
    const toYear = control.get('to_year').value;
    if (fromYear !== null && toYear !== null && fromYear > toYear) {
      return { toYearLessThanFromYear: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
  education_id;
  myDate = Date.now()
  candidateForm: FormGroup;
  socialLinks: FormGroup;
  skillsForm: FormGroup;
  educationForm: FormGroup;
  lengthOfEducation: any = [];
  candidateId;
  candidateUsername: string;
  candidateInfo: any = {};
  role: string;
  id: any;
  skills: FormArray;
  lengthOfSkills: any;
  selectedProfile: File;
  selectedResume: File;
  selectedProfileName: any;
  selectedResumeName: any;
  resumeName: string;
  resumeLink: string;
  imageName: string;
  imageLink: string;
  sliderValue = 50;
  isFormChanged: boolean = true;
  profilePhoto: any;
  Toast: any;
  descriptionText: string = '';
  remainingCharacters: number = 750;
  experenceText: string = '';
  remainingCharacters1: number = 1000;
  researchText:string = ''
  remainingCharacters2: number = 500;
  show1:boolean=false;show2:boolean=false;show3:boolean=false;
  submitted=false;

  stringValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    const isValid = /^[a-zA-Z]+$/.test(value);
    return isValid ? null : { invalidString: true };
  };

  emailValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    return isValid ? null : { invalidEmail: true };
  };

  constructor(
    private candidateServices: CandidateServicesService,
    private fb: FormBuilder,
    private s3upload: S3uploadapiService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer,
    private popup: PopupService,
  ) {
    this.candidateForm = this.fb.group({
      id: [''],
      first_name: ['', [Validators.required, this.stringValidator]],
      last_name: ['', [Validators.required, this.stringValidator]],
      phone: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      email: ['', [Validators.required, this.emailValidator]],
      job_title: ['', [Validators.required]],
      address: ['', [Validators.required]],
      description: ['', [Validators.required]],
      work_experience: ['', [Validators.required]],
      research: ['', [Validators.required]],
      date_of_birth: ['', [Validators.required]],
      gender: ['', [Validators.required]]
    });

    this.socialLinks = this.fb.group({
      social_links_facebook: ['', Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)],
      social_links_instagram: ['', Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)],
      social_links_linkedin: ['', Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)],
      social_links_twitter: ['', Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)],
    });

    this.educationForm = this.fb.group({
      educational_details: this.fb.array([]),
    });

    this.skillsForm = this.fb.group({
      skills_array: this.fb.array([]),
    });
  }

  onInput() {
    this.show1 = true;
    this.remainingCharacters = 750 - this.descriptionText.length;
  }
  onInput1() {
    this.show2=true;
    this.remainingCharacters1 = 750 - this.experenceText.length;
  }
  onInput2() {
    this.show3=true;
    this.remainingCharacters2 = 500 - this.researchText.length;
  }


  ngOnInit(): void {
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    this.accountService.getUserId().subscribe(
      (Response) => {
        this.candidateId = Response.body;
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    this.role = this.accountService.getRole();
    this.candidateUsername = this.accountService.getUsername();

    this.educationForm.valueChanges.subscribe(() => { this.isFormChanged = false });

    this.skillsForm = new FormGroup({ skills_array: new FormControl() });

    this.skillsForm = this.fb.group({ skills_array: this.fb.array([]) });

    this.skills = this.skillsForm.get('skills_array') as FormArray;

    this.getCandidateInfo(this.candidateUsername)

  }
  downloadProfilePic(filePath: string) {
    this.candidateServices.downloadProfilePic(filePath).subscribe((blob: Blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.profilePhoto = this.sanitizer.bypassSecurityTrustUrl(objectURL) as SafeUrl;
    });
  }
  showErrorAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: errorMessage,
    });
  }
  capitalizeAndTrim(value: string): string {
    return value.trim().toUpperCase();
  }
  isValidFile(file: File): boolean {
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(file.type);
  }

  isValidFileSize(file: File, maxSize: number): boolean {
    return file.size <= maxSize;
  }
  isValidImage(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    return allowedTypes.includes(file.type);
  }

  // Profile
  onProfileSelect(event) {
    this.selectedProfile = event.target.files[0];
    this.selectedProfileName = this.selectedProfile.name;

    if (!this.isValidImage(this.selectedProfile)) {
      this.showErrorAlert('Please select a JPEG, JPG, or PNG file');
      this.selectedProfileName = "";
      return;
    }

    if (!this.isValidFileSize(this.selectedProfile, 1048576)) {
      this.showErrorAlert('Please choose a Profile less than 1 MB');
      this.selectedProfileName = "";
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedProfile, this.selectedProfileName);

    this.uploadImage(formData);
  }
  uploadImage(formData: FormData) {
    const folder = "JOBPORTAL";
    const userType = this.accountService.get("role");
    const collegeCode = this.accountService.get("branch");
    const id = this.candidateId;
    const firstName = this.capitalizeAndTrim(this.candidateForm.value.first_name);
    const lastName = this.capitalizeAndTrim(this.candidateForm.value.last_name);
    const dateOfBirth = this.candidateForm.value.date_of_birth;

    const swal = Swal.fire({
      title: 'Uploading Image',
      text: 'Please wait...',
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    this.candidateServices.uploadProfilePic(folder, userType, collegeCode, firstName, lastName, dateOfBirth, id, formData)
      .subscribe(
        (res) => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Image uploaded successfully',
            });
            this.ngOnInit();
          }
        },
        (err) => {
          console.log(err);
          this.showErrorAlert('Image upload failed! Please try again');
        }
      );
  }

  // Resume
  onResumeSelect(event) {
    this.selectedResume = event.target.files[0];
    this.selectedResumeName = this.selectedResume.name;
    if (!this.isValidFile(this.selectedResume)) {
      this.showErrorAlert('Please select a PDF, DOC, or DOCX file');
      return;
    }
    if (!this.isValidFileSize(this.selectedResume, 5242880)) {
      this.showErrorAlert('Please choose a file less than 5 MB');
      return;
    }
    this.uploadResume();
  }
  uploadResume() {
    const folder = "JOBPORTAL";
    const userType = this.accountService.get("role");
    const collegeCode = this.accountService.get("branch");
    const id = this.candidateId;
    const firstName = this.capitalizeAndTrim(this.candidateForm.value.first_name);
    const lastName = this.capitalizeAndTrim(this.candidateForm.value.last_name);
    const dateOfBirth = this.candidateForm.value.date_of_birth;

    const file = this.selectedResume;
    const formData = new FormData();

    const swal = Swal.fire({
      title: 'Uploading Resume',
      text: 'Please wait...',
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    formData.append('file', this.selectedResume, this.selectedResume.name);
    this.candidateServices.uploadResume(folder, userType, collegeCode, firstName, lastName, dateOfBirth, id, formData)
      .subscribe(
        (res) => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Resume uploaded successfully',
            });
            this.ngOnInit();
          }
        },
        (error) => {
          console.log(error);
          this.showErrorAlert('Resume upload failed! Please try again');
        }
      );
  }




  getCandidateInfo(seekerUsername) {
    this.candidateServices.candidateListGetById(seekerUsername).subscribe(res => {
      this.candidateInfo = res.body
      if (this.candidateInfo.gender === 'MALE') {
        this.profilePhoto = 'assets/img/male_profile.png'
      } else if (this.candidateInfo.gender === 'FEMALE') {
        this.profilePhoto = 'assets/img/female_profile.png'
      } else {
        this.profilePhoto = 'assets/img/neutral_profile.jpg'
      }
      this.resumeName = this.candidateInfo.resume_name;
      this.resumeLink = this.candidateInfo.resume_link;
      this.imageLink = this.candidateInfo.profilePicBlob;
      if (this.imageLink != null || this.imageLink != '') {
        this.downloadProfilePic(this.imageLink);
      }
      this.showCandidateData(this.candidateInfo);
      this.showSocialData(this.candidateInfo);
      this.showEducationData(this.candidateInfo);
      this.showSkillData(this.candidateInfo);
    }, err => console.log(err))
  }

  showCandidateData(data) {
    this.candidateForm.patchValue({
      id: data.id,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.contactNumbers[0]?.number,
      email: data.email,
      date_of_birth: data.dateOfBirth,
      job_title: data.jobTitle,
      address: data.strAddress,
      gender: data.gender,
      description: data.description,
      research: data.research,
      work_experience: data.workExperience
    });
  }

  showSocialData(data) {
    this.socialLinks.patchValue({
      social_links_facebook: data.socialLinksFacebook,
      social_links_instagram: data.socialLinksInstagram,
      social_links_linkedin: data.socialLinksLinkedin,
      social_links_twitter: data.socialLinksTwitter,
    });
  }
  showEducationData(data) {
    const educationDetails = data.educationalDetails;
    while (this.getEducationFormControls().length > 0) {
      this.getEducationFormControls().removeAt(0);
    }
    educationDetails.forEach(x => {
      let group = this.fb.group({
        institute: x.institute,
        degree: x.degree,
        stream: x.stream,
        from_year: x.from_year,
        to_year: x.to_year,
        id: x.id
      });
      this.getEducationFormControls().push(group);
    });
  }


  showSkillData(data) {
    const skills = data.skills;
    skills.forEach(y => {
      let group = this.fb.group({
        name: y.name,
        skillProficiency: y.skillProficiency,
        id: y.id
      });
      this.getSkillFormControls().push(group);
    });
  }



  onSubmit(form: FormGroup) {
    this.submitted=true;
    if (form.valid) {
      const { address, gender, description, work_experience, research, id, first_name, last_name, email, phone, date_of_birth, job_title } = this.candidateForm.value;
      const { social_links_linkedin, social_links_facebook, social_links_instagram, social_links_twitter } = this.socialLinks.value;
      const educational_details = this.educationForm.value.educational_details;
      const skills = this.skillsForm.value.skills_array;

      const data = {
        "id": this.candidateForm.value.id,
        "username": this.candidateUsername,
        "firstName": this.candidateForm.value.first_name,
        "lastName": this.candidateForm.value.last_name,
        "contactNumbers": [{
          "contactType": "SELF",
          "number": this.candidateForm.value.phone
        }],
        "email": this.candidateForm.value.email,
        "dateOfBirth": this.candidateForm.value.date_of_birth,
        "jobTitle": this.candidateForm.value.job_title,
        "strAddress": this.candidateForm.value.address,
        "gender": this.candidateForm.value.gender,
        "description": this.candidateForm.value.description,
        "research": this.candidateForm.value.research,
        "workExperience": this.candidateForm.value.work_experience,
        "socialLinksFacebook": this.socialLinks.value.social_links_facebook,
        "socialLinksInstagram": this.socialLinks.value.social_links_instagram,
        "socialLinksTwitter": this.socialLinks.value.social_links_twitter,
        "socialLinksLinkedin": this.socialLinks.value.social_links_linkedin,

        "educationalDetails": educational_details,
        "skills": skills,

      };

      this.candidateServices.candidateInfoPatch(data, this.candidateUsername).subscribe(
        res => {
          if (res) {
            Swal.fire(
              'Data saved successfully',
              'Success',
              'success'
            )
          }
          this.ngOnInit();
        }, error => {
          Swal.fire(
            'Some error Occured !',
            'try again',
            'error'
          )
        })
    } else {
      Swal.fire(
        'Opps !',
        'Please fill all fields Correctly',
        'error'
      )
    }
  }


  // Education Add

  addNewEducation(): FormGroup {
    return this.fb.group({
      education_id: [null, []],
      institute: ['', [Validators.required]],
      degree: ['', [Validators.required]],
      stream: ['', [Validators.required]],
      from_year: ['', [Validators.required]],
      to_year: ['', [Validators.required]],
    }, { validator: toYearGreaterThanFromYearValidator() });
  }

  getEducationFormControls(): FormArray {
    this.lengthOfEducation = (this.educationForm.get('educational_details') as FormArray).length;
    return this.educationForm.get('educational_details') as FormArray
  }

  addCreds(): void {
    this.getEducationFormControls().push(this.addNewEducation());
  }

  removeEducationPoup(index) {
    this.popup.Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this Education?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.removeEducation(index)
      }
    });
  }
  isEducationRemoved = true;

  removeEducation(index) {
    const ed_id = this.getEducationFormControls().value[index]["id"]
    this.getEducationFormControls().removeAt(index);
    this.isEducationRemoved = false;
    this.onSubmitEducationDetails(ed_id)
  }


  onSubmitEducationDetails(ed_id) {

    this.candidateServices.candidateEducationDetailRemove(ed_id, this.candidateId).subscribe(
      (res) => {
        if (res) {
          this.Toast.fire({
            icon: 'success',
            title: 'Education remove successfully'
          })
        }
      }, (error) => { console.log(error); });
  }

  // Skills Add
  addNewSkill(): FormGroup {
    return this.fb.group({
      skill_id: [null, []],
      name: ['', [Validators.required]],
      skillProficiency: [50, [Validators.required]]
    })
  }

  getSkillFormControls(): FormArray {
    this.lengthOfSkills = (this.skillsForm.get('skills_array') as FormArray).length;;
    return this.skillsForm.get('skills_array') as FormArray
  }

  addCrSkill(): void {
    this.getSkillFormControls().push(this.addNewSkill());
  }
  removeSkillPoup(index) {
    this.popup.Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this Skill?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.removeSkill(index)
      }
    });
  }
  isSkillRemoved: boolean = true;

  removeSkill(index) {
    const skill_id = this.getSkillFormControls().value[index]["id"];
    this.getSkillFormControls().removeAt(index);
    this.isSkillRemoved = false;
    this.onSubmitSkillDetails(skill_id);
  }

  onSubmitSkillDetails(skill_id) {
    this.candidateServices.candidateSkillRemove(skill_id, this.candidateId).subscribe(
      (res) => {
        this.Toast.fire({
          icon: 'success',
          title: 'skill remove successfully'
        })
      }, (error) => { console.log(error); });
  }
  isInputChanged = true;
  isInputChangedForEducation = true;

  onInputChange() {
    this.isInputChanged = false;
  }
  onInputChangeForEducation() {
    this.isInputChangedForEducation = false;
  }

  isSliderChanged = true;
  onSliderChange(value: number, index: number) {
    const control = this.getSkillFormControls().at(index).get('skillProficiency').value;
    this.isSliderChanged = false;
  }
}
