<div>
    <div class="banner-area banner-img-one row m-0">
        <div class="col-md-2 pt-100 px-0" style="height: 650;">
            <div class="row m-0 ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="container col-md-8 px-0">
            <div class="d-table ">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="banner-text">
                            <h1 style="color :#6666ff;text-shadow: 0 2px 1px black;">Find Your <span>Desire</span> Job
                            </h1>
                            <!-- <p>Jobs, Employment & Future Career Opportunities</p> -->

                            <div class="banner-form-area">
                                <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                                    <div class="row m-0">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><i class="icofont-search-1"></i></label>
                                                <input type="text" class="form-control" placeholder="Job Title"
                                                    formControlName="jobTitle">
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label><i class="icofont-location-pin"></i></label>
                                                <input type="text" class="form-control" placeholder="City or State"
                                                    formControlName="jobLocation">
                                            </div>
                                        </div>

                                    </div>

                                    <button type="submit" [disabled]="searchForm.invalid"
                                        class="btn banner-form-btn">Search</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 pt-100 px-0" style="height: 650;">
            <div class="row m-0 ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>

    <div style="position: relative;transform: scale(.7);">
        <div class="row m-0 profile-progress-wrap d-none d-lg-flex" style="position: absolute;top: 0; left: 50%; transform: translate(-50%,-90%);">
            <label style="text-align: center;font-weight: 700;font-size: 25px;" *ngIf="item1&&item2&&item3&&item4&&item5&&item6">Profile Compeleted</label>
            <label style="text-align: center;font-weight: 700;font-size: 25px;" *ngIf="!item1||!item2||!item3||!item4||!item5||!item6"> Compelete Your Profile</label>
            <div class="col-lg-2">
                <div class="profile-progress-item" [ngClass]="{'compeleted-class': item1}">
                    <i class="bi bi-info-square"></i>
                    <span>Fill</span>
                    <span>Info</span>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="profile-progress-item" [ngClass]="{'compeleted-class': item2}">
                    <i class="bi bi-mortarboard"></i>
                    <span>Fill</span>
                    <span>Education</span>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="profile-progress-item" [ngClass]="{'compeleted-class': item3}">
                    <i class="bi bi-puzzle"></i>
                    <span>Fill</span>
                    <span>Skills</span>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="profile-progress-item" [ngClass]="{'compeleted-class': item4}">
                    <i class="bi bi-briefcase"></i>
                    <span>Work</span>
                    <span>Experience</span>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="profile-progress-item" [ngClass]="{'compeleted-class': item5}">
                    <i class="bi bi-person-circle"></i>
                    <span>Upload</span>
                    <span>Pic</span>
                </div>
            </div>

            <div class="offset-sm-3 offset-lg-0 col-lg-2">
                <div class="profile-progress-item profile-progress-last" [ngClass]="{'compeleted-class': item6}">
                    <i class="bi bi-file-earmark-person-fill"></i>
                    <span>Upload</span>
                    <span>Resume</span>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-area pt-5">
        <div class="container">
            <div class="row m-0">
                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-employee"></i>
                        <h3><span class="counter">{{jobPortalStats?.jobAvailable}}</span></h3>
                        <p>Job Available</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-curriculum"></i>
                        <h3><span class="counter">{{jobPortalStats?.submittedCVs}}</span></h3>
                        <p>CV Submitted</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-enterprise"></i>
                        <h3><span class="counter">{{jobPortalStats?.companies}}</span></h3>
                        <p>Companies</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                        <h3><span class="counter">{{jobPortalStats?.registeredUsers}}</span></h3>
                        <p> Registered Users </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="account-area pb-70">
        <div class="container">
            <div class="row account-wrap">
                <div class="col-sm-6 col-lg-4">
                    <div class="account-item">
                        <i class="flaticon-approved"></i>
                        <span>Build Your Resume</span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="account-item">
                        <i class="flaticon-cv"></i>
                        <span>Upload Your Resume</span>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="account-item account-last">
                        <i class="flaticon-customer-service"></i>
                        <span>Apply for Dream Job</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="popular-area pt-100 pb-70 row m-0">
        <div class="col-md-2 m-0 p-0">
            <div class="row m-0 ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="container col-md-8">
            <div class="row m-0 align-items-center">
                <div class="col-lg-6">
                    <div class="popular-item">
                        <div class="row m-0 align-items-center">
                            <div class="col-lg-7">
                                <img src="assets/img/home-1/8.png" alt="Popular">
                            </div>

                            <div class="col-lg-5">
                                <div class="practice-inner">
                                    <img src="assets/img/home-1/3.png" alt="Popular">
                                    <img src="assets/img/home-1/9.png" alt="Popular">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="popular-item popular-right">
                        <div class="section-title text-start">
                            <h2>Why We are Most Popular</h2>
                        </div>
                        <p>Job seekers can use built-in tools to create or update their resumes.
                            Import existing resumes from cloud storage or other sources.
                            Format, edit, and customize their resumes for specific job applications.
                            Set application deadlines and manage submitted resumes</p>

                        <div class="row m-0 popular-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <ul>
                                    <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                                    <li><i class="flaticon-no-money"></i> No Extra Charge</li>
                                </ul>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <ul>
                                    <li><i class="flaticon-enterprise"></i> Top Companies</li>
                                    <li><i class="flaticon-employee"></i> International Job</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 m-0 p-0">
            <div class="row m-0 ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>




    <div class="app-area">
        <div class="container">
            <div class="row m-0">
                <div class="col-lg-6">
                    <div class="app-item app-left">
                        <img src="assets/img/home-1/6.png" alt="Mobile">
                        <img src="assets/img/home-1/7.png" alt="Mobile">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="app-item">
                        <div class="section-title text-start">
                            <h2>Download The Campus Orbit Mobile App</h2>
                        </div>
                        <p>A Campus Orbit app is a software application designed to help job seekers and employers connect
                            in the job market. It provides a platform for users to search and apply for job openings, post
                            job vacancies, and manage the entire job-seeking or hiring process.</p>
                        <div class="app-btn">
                            <a class="app-btn-one" href="#">
                                <i class="flaticon-apple"></i>
                                <span>Download on the</span>
                                <p>App Store</p>
                            </a>
                            <a class="app-btn-two" href="https://play.google.com/store/search?q=Campus%20Orbit&c=apps&hl=en-IN">
                                <i class="flaticon-playstore"></i>
                                <span>ANDROID APP ON</span>
                                <p>Google Play</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>