<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Post A Job</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Post A Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="post-job-area pt-100 row m-0">
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
    <div class="container col-md-8">
        <div class="post-job-item">
            <form   [formGroup]="jobForm" (ngSubmit)="onSubmit(jobForm)">
                <div class="post-job-heading">
                    <h2>Post Your Job</h2>
                </div>

                <div class="row">

                    <div *ngIf="userRole == 'TPO' " class="col-lg-6">
                        <div class="form-group">
                            <label>Job Type</label>
                            <select class="form-select w-60" (change)="onChange($event.target.value)">
                                <option disabled selected>---Select the job type---</option>
                                <option value="College">All Branch/Students</option>
                                <option value="Branch">Particular Branch</option>
                                <option value="Outsiders">Hiring College Staff</option>
                            </select>
                            
                            <p style="color: red; font-size: 14px; font-weight: 600;">You cannot edit this choice once you save this job post</p>
                        </div>
                    </div>

                    <div *ngIf="userRole == 'TPO' " class="col-lg-6" [hidden]="hiddenFormField">
                        <div class="form-group">
                            <label>Branch</label>
                            <select class="form-select w-60" name="branch" formControlName="branch" (ngModelChange)="onBranchSelect();">
                                <option disabled selected>---Select the Branch---</option>
                                <option *ngFor="let brn of branches" [value]="brn.collegeCourseCode">
                                  {{brn.desc}}
                                </option>
                              </select>
                        </div>
                    </div>

                    <div *ngIf="userRole == 'TPO' " class="col-lg-6" [hidden]="hiddenFormField">
                        <div class="form-group">
                            <label>Course Year</label>
                            <select class="form-select w-60" name="year" formControlName="year" (ngModelChange)="onYearSelect();">
                                <option disabled selected >---Select course year---</option>
                                <option *ngFor="let yr of years" [value]="yr.academicYearCode">
                                  {{yr.name}}
                                </option>
                              </select>
                        </div>
                    </div>

                    <div *ngIf="userRole == 'TPO' " class="col-lg-6" [hidden]="hiddenFormField">
                        <div class="form-group">
                            <label>Semester</label>
                            <select class="form-select w-60" name="sem" formControlName="sem" (ngModelChange)="onSemSelect()">
                                <option disabled selected >---Select semester---</option>
                                <option *ngFor="let sem of semesters" [value]="sem.semesterCode">
                                  {{sem.name}}
                                </option>
                              </select>
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Job Title</label>
                            <input type="text" class="form-control" placeholder="UX/UI Designer" formControlName="jobTitle"
                            [ngClass]="{ 'invalid-field': (jobForm.get('jobTitle').invalid && (jobForm.get('jobTitle').touched || submitted)) }">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Company Website</label>
                            <input type="text" class="form-control" 
                            [ngClass]="{'invalid-field': jobForm.get('website').invalid && jobForm.get('website').touched}"
                            placeholder="mycompany.com" formControlName="website"
                            [ngClass]="{ 'invalid-field': (jobForm.get('website').invalid && (jobForm.get('website').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Company Name</label>
                            <input type="text" class="form-control" placeholder="mycompany.com" formControlName="companyName"
                            [ngClass]="{ 'invalid-field': (jobForm.get('companyName').invalid && (jobForm.get('companyName').touched || submitted)) }">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Vacancies</label>
                            <input type="number" class="form-control" placeholder="30"  formControlName="vacancies"
                            [ngClass]="{ 'invalid-field': (jobForm.get('vacancies').invalid && (jobForm.get('vacancies').touched || submitted)) }">

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control"
                            [ngClass]="{'invalid-field': jobForm.get('email').invalid && jobForm.get('email').touched}"
                            placeholder="abc@gmail.com"  formControlName="email"
                            [ngClass]="{ 'invalid-field': (jobForm.get('email').invalid && (jobForm.get('email').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Salary Range</label>
                            <div class="d-flex">
                            <input type="number" class="form-control" placeholder="10" min="0" max="9999"  formControlName="salaryRange1" style="width: 48%;"
                            [ngClass]="{ 'invalid-field': (jobForm.get('salaryRange1').invalid && (jobForm.get('salaryRange1').touched || submitted)) }">
                            &nbsp;_ &nbsp;
                            <input type="number" class="form-control" placeholder="15" min="0" max="9999"   formControlName="salaryRange2" style="width: 48%;"
                            [ngClass]="{ 'invalid-field': (jobForm.get('salaryRange2').invalid && (jobForm.get('salaryRange2').touched || submitted)) }">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Last Date</label>
                            <input type="date" class="form-control" value="{{myDate | date:'yyyy-MM-dd'}}" min="{{myDate | date:'yyyy-MM-dd'}}" formControlName="lastDate"
                            [ngClass]="{ 'invalid-field': (jobForm.get('lastDate').invalid && (jobForm.get('lastDate').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Experience Required (Years)</label>
                            <input type="number" class="form-control" placeholder="5" formControlName="experienceRequired" 
                            [ngClass]="{ 'invalid-field': (jobForm.get('experienceRequired').invalid && (jobForm.get('experienceRequired').touched || submitted)) }">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Location</label>
                            <input type="text" class="form-control" placeholder="Mumbai, Maharastra"
                            formControlName="jobLocation"
                            [ngClass]="{ 'invalid-field': (jobForm.get('jobLocation').invalid && (jobForm.get('jobLocation').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Qualification</label>
                            <input type="text" class="form-control" placeholder="BTech / MBA"
                            formControlName="educationQualification"
                            [ngClass]="{ 'invalid-field': (jobForm.get('educationQualification').invalid && (jobForm.get('educationQualification').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Address</label>
                            <input type="text" class="form-control" placeholder="210-27 Quadra, Market Street, Victoria Canada"
                            formControlName="address"
                            [ngClass]="{ 'invalid-field': (jobForm.get('address').invalid && (jobForm.get('address').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Contact No</label>
                            <input type="text" class="form-control" placeholder="+91 0000 0000 00"
                            [ngClass]="{'invalid-field': jobForm.get('contactNo').invalid && jobForm.get('contactNo').touched}"
                            formControlName="contactNo"
                            [ngClass]="{ 'invalid-field': (jobForm.get('contactNo').invalid && (jobForm.get('contactNo').touched || submitted)) }">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group col-lg-6">
                            <div class="job-type-area">
                                <span>Job Type</span>
                            
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="jobCategory" name="jobCategory" id="inlineRadio1" value="Full Time" >
                                    <label class="form-check-label" for="inlineRadio1">Full Time</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="jobCategory" name="jobCategory" id="inlineRadio2" value="Part Time" >
                                    <label class="form-check-label" for="inlineRadio2">Part Time</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="jobCategory" name="jobCategory" id="inlineRadio3" value="Intern" >
                                    <label class="form-check-label" for="inlineRadio3">Intern</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group col-lg-6">
                            <div class="job-type-area">
                                <span>Gender</span>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="gender" name="gender" id="genderRadio1" value="Male" >
                                    <label class="form-check-label" for="genderRadio1">Male</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="gender" name="gender" id="genderRadio2" value="Female">
                                    <label class="form-check-label" for="genderRadio2">Female</label>
                                </div>
                            </div>
                        </div> -->
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Company Description</label>
                            <div style="position: relative;">
                            <textarea id="your_message" rows="8" class="form-control"
                            [(ngModel)]="textInput" (input)="onInput()" maxlength="500"
                            formControlName="companyDescription" placeholder="Company Description"
                            [ngClass]="{ 'invalid-field': (jobForm.get('companyDescription').invalid && (jobForm.get('companyDescription').touched || submitted)) }">
                           </textarea>
                           <p style="position: absolute; bottom: 0; right: 5px;margin-right: 5px; color: gray;font-size: small;">Remaining characters: {{ remainingCharacters }}</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Job Description</label>
                            <div style="position: relative;">
                            <textarea
                            [(ngModel)]="textInput1" (input)="onInput1()" maxlength="500"
                             id="your_message" rows="8" class="form-control"
                            formControlName="description" placeholder="Job Description"
                            [ngClass]="{ 'invalid-field': (jobForm.get('description').invalid && (jobForm.get('description').touched || submitted)) }"></textarea>
                            <p style="position: absolute; bottom: 0; right: 5px;margin-right: 5px; color: gray;font-size: small;">Remaining characters: {{ remainingCharacters1 }}</p>
                        </div>
                    </div>
                    </div>
                </div>
                
                <div class="text-left">
                    <button type="submit" class="btn create-ac-btn">Post</button>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
</div>