<div class="job-details-area pt-100 pb-200 row">
  <div class="col-md-2">
    <div class="row">
        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
        </ng-adsense> 
    </div>
    <div class="row">
        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
        </ng-adsense> 
    </div>
</div>
  <div class="container col-md-8">
    <div class="row">
      <div class="col-lg-12">

        <div class="job-details-item; ">
          <div class="job-description ">
            <div style="display:flex;   justify-content: center;">
              <h2>{{job_title}}</h2>
            </div>

          </div>
        </div>

        <div class="m-3">

          <table class="table table-striped" id="Excel-Table">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col" sortable="name">Candidate</th>
                <th scope="col" sortable="area">Email</th>
                <th scope="col" sortable="area">Contact</th>
                <th scope="col" sortable="area">Resume</th>
                <th scope="col" sortable="area">Status</th>

                <th scope="col" sortable="area" style="justify-content: right;" class=" dropdown-toggle">
                  <div ngbDropdown class="d-inline-block">
                    <button type="button" class="btn " id="dropdownBasic1" ngbDropdownToggle>
                      <i class="icofont-listine-dots"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button [disabled]="!collectionSize" ngbDropdownItem (click)="downloadAsCSV(job_title)">Download CSV</button>
                    </div>
                  </div>
                </th>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf=" collectionSize > 0 ; else noRecordFound">
                <tr *ngFor="let data of item;index as i">
                  <td>{{ data.index }}</td>
                  <td class="anchor-link" routerLink="/candidate-details" (click)="viewCandidateProfile(data?.email)">{{ data.firstName }} {{ data.lastName}}</td>
                  <td class="anchor-link" routerLink="/candidate-details" (click)="viewCandidateProfile(data?.email)">{{ data.email }}</td>
                  <td>{{ data.contactNumbers[0].number }}</td>
                  <td><i class="icofont-download btn" (click)="downloadResume(data.resume_link, data.firstName,data.lastName)"></i></td>
                  <td>
                    <select class="form-select w-60 " [(ngModel)]="data.recruiterStatus" (change)="statusChanged($event.target.value, recruiterID, data.jobApplyId)">
                      <option value="{{ status[2] }}">{{ status[2] }}</option>
                      <option value="{{ status[3] }}">{{ status[3] }}</option>
                      <option value="{{ status[4] }}">{{ status[4] }}</option>
                      <option value="{{ status[5] }}">{{ status[5] }}</option>
                    </select>
                  </td>
                  <td></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ng-template #noRecordFound>
            <tr class="text-center">
              <td colspan="6">
                No Record Found
              </td>
            </tr>
          </ng-template>

          <div style="display:flex; justify-content: center; margin: 100px 0px 50px 0px;">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
              (pageChange)="refreshJobs(this.id, this.recruiterID)">
            </ngb-pagination>

          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2">
    <div class="row">
        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
        </ng-adsense> 
    </div>
    <div class="row">
        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
        </ng-adsense> 
    </div>
</div>
</div>
<router-outlet></router-outlet>