<div class="page-title-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="page-title-text">
          <h2>FAQ</h2>
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li><i class="icofont-simple-right"></i></li>
            <li>FAQ</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="d-none d-md-block col-md-2 pt-100">
      <div class="row">
        <ng-adsense [adClient]="'ca-pub-6119489645323262'"   [fullWidthResponsive]="true"></ng-adsense>
      </div>
    </div>
    <section class="faq-area pt-100 col-md-8">
      <div class="container">
        <!-- ads -->
        <div class="row">
          <ng-adsense [adClient]="'ca-pub-6119489645323262'" display="block-inline" [height]="90" [width]="970"></ng-adsense>
        </div>
        <!-- ads -->
        <div class="row faq-wrap">
          <div class="col-lg-12">
            <div class="faq-head">
              <h2>About Job</h2>
            </div>
            <div class="faq-item">
              <ul class="accordion">
                <li class="wow fadeInUp" data-wow-delay=".3s">
                  <div class="accordion-container">
                    <div class="accordion-item" style="margin: 20px 0;border: 1px solid green;"
                      *ngFor="let item of items; let i = index">
                      <h2 class="accordion-header" [id]="'flush-heading' + i">
                        <button class="accordion-button collapsed" type="button" [attr.data-bs-toggle]="'collapse'"
                          [attr.data-bs-target]="'#flush-collapse' + i" [attr.aria-expanded]="false"
                          [attr.aria-controls]="'flush-collapse' + i">
                          {{ item.que }}
                        </button>
                      </h2>
                      <div [id]="'flush-collapse' + i" class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'flush-heading' + i" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">{{ item.ans }}</div>
                      </div>
                    </div>
                  </div>

                </li>


              </ul>
            </div>
          </div>
        </div>
        <!-- ads -->
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" display="block-inline" [height]="90" [width]="970"></ng-adsense>
        </div>
        <!-- ads -->
        <div class="row faq-wrap">
          <div class="col-lg-12">
            <div class="faq-head">
              <h2>Web Development</h2>
            </div>

            <div class="faq-item">
              <ul class="accordion">
                <li class="wow fadeInUp" data-wow-delay=".3s">
                  <div class="accordion-container">
                    <div class="accordion-item" style="margin: 20px 0;border: 1px solid green;"
                      *ngFor="let item of webDevelopment ; let i = index">
                      <h2 class="accordion-header" [id]="'flush-heading' + i">
                        <button class="accordion-button collapsed" type="button" [attr.data-bs-toggle]="'collapse'"
                          [attr.data-bs-target]="'#flush-collapse' + i" [attr.aria-expanded]="false"
                          [attr.aria-controls]="'flush-collapse' + i">
                          {{ item.question }}
                        </button>
                      </h2>
                      <div [id]="'flush-collapse' + i" class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'flush-heading' + i" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">{{ item.answer }}</div>
                      </div>
                    </div>
                  </div>

                </li>


              </ul>
            </div>
          </div>
        </div>
        <!-- ads -->
        <div class="row">
          <ng-adsense [adClient]="'ca-pub-6119489645323262'" display="block-inline"  [height]="90" [width]="970"></ng-adsense>
        </div>
        <!-- ads -->
        <div class="row faq-wrap">
          <div class="col-lg-12">
            <div class="faq-head">
              <h2>UX/UI</h2>
            </div>

            <div class="faq-item">
              <ul class="accordion">
                <li class="wow fadeInUp" data-wow-delay=".3s">
                  <div class="accordion-container">
                    <div class="accordion-item" style="margin: 20px 0;border: 1px solid green;"
                      *ngFor="let item of uiItems ; let i = index">
                      <h2 class="accordion-header" [id]="'flush-heading' + i">
                        <button class="accordion-button collapsed" type="button" [attr.data-bs-toggle]="'collapse'"
                          [attr.data-bs-target]="'#flush-collapse' + i" [attr.aria-expanded]="false"
                          [attr.aria-controls]="'flush-collapse' + i">
                          {{ item.question }}
                        </button>
                      </h2>
                      <div [id]="'flush-collapse' + i" class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'flush-heading' + i" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">{{ item.answer }}</div>
                      </div>
                    </div>
                  </div>

                </li>


              </ul>
            </div>
          </div>
        </div>
        <!-- ads -->
        <div class="row">
          <ng-adsense [adClient]="'ca-pub-6119489645323262'"  display="block-inline"  [height]="90" [width]="970"></ng-adsense>
        </div>
        <!-- ads -->
      </div>
    </section>
    <div class="d-none d-md-block col-md-2 pt-100">
      <div class="row">
        <ng-adsense [adClient]="'ca-pub-6119489645323262'"  [fullWidthResponsive]="true"  ></ng-adsense>
      </div>
    </div>
  </div>
</div>