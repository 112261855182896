<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Log In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Log In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="d-none d-md-block col-md-2 " style="margin-top: 100px;">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>

            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>

            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>

        <div class="login-area pt-100 col-md-8 col-sm-12">
            <div class="container pt-100">
                <h2>Welcome Back!</h2>
                <form class="text-center" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group d-flex justify-content-center">
                        <input type="text" formControlName="username" class="form-control w-50 rounded" placeholder="Email">
                        <span class="icon input-group-text p-0" style="background-color: transparent; border:0;position: relative;">
                            <i class="bi bi-envelope-at" style="position: absolute;left: -45px;"></i>
                        </span>
                    </div>

                    <div class="form-group d-flex justify-content-center">
                        <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password"
                            class="form-control w-50 rounded" [ngModel]="password" placeholder="Password">
                        <span class="icon input-group-text p-0"  style="background-color: transparent; border:0;position: relative;">
                            <i class="bi bi-eye{{ showPassword ? '-slash' : '' }}" (click)="togglePasswordVisibility()" style="position: absolute;left: -45px;"></i></span>
                    </div>
                    <div class="login-sign-in p-0">
                        <ul>
                            <li><a href="https://app.campusorbit.com/auth/forgetpwd" target="_blank">Forgot Password?</a></li>
                        </ul>
                    </div>
                    <div class="text-center">
                        <button type="submit" class="btn login-btn mb-0">LOGIN</button>
                    </div>
                </form>
                <div class="login-sign-in">
                    <ul>
                        <li>Don't have an account ?</li><a routerLink="/create-account">Signin Here</a>
                    </ul>
                </div>
                <br>
                <div class="login-wrap">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6" routerLink="/create-account" [queryParams]="{ name: 'Applicant' }">
                            <div class="jobseeker-item">
                                <div class="jobseeker-icon">
                                    <i class="flaticon-job-search"></i>
                                </div>

                                <div class="jobseeker-inner">
                                    <span>Applicant</span>
                                    <h3>Create account</h3>
                                </div>

                                <a routerLink="/create-account" [queryParams]="{ name: 'Applicant' }">Get Started<i
                                        class="icofont-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6" routerLink="/create-account" [queryParams]="{ name: 'Recruiter' }">
                            <div class="jobseeker-item">
                                <div class="jobseeker-icon">
                                    <i class="flaticon-recruitment"></i>
                                </div>

                                <div class="jobseeker-inner">
                                    <span>Recruiter</span>
                                    <h3>Create Account</h3>
                                </div>

                                <a routerLink="/create-account" [queryParams]="{ name: 'Recruiter' }">Get Started<i
                                        class="icofont-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="login-social">
            <a href="#"><i class="icofont-facebook"></i> Login With Facebook</a>
            <a class="login-google" href="#"><i class="icofont-google-plus"></i> Login With Google</a>
        </div> -->
            </div>
        </div>
        <div class="col-md-2" style="margin-top: 100px;">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>

            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>

            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>
</div>