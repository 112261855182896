import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BYPASS_LOG } from '../request.interceptor';

@Injectable({
  providedIn: 'root'
})
export class GptService {
  private apiUrl = 'https://api.openai.com/v1/chat/completions';
  private modelName = 'gpt-3.5-turbo-1106';
  private OPEN_AI_KEY = "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"


  constructor(private http: HttpClient) {}

  generateQuestion(message:any,token:string): Observable<any> {
    const apiKey = token;
    const headers = { Authorization: `Bearer ${apiKey}` };

    const body = {
      model: this.modelName,
      response_format:{ "type": "json_object" },
      messages:message,
    };

    return this.http.post<any>(this.apiUrl, body, { headers,context:new HttpContext().set(BYPASS_LOG, true) });
  }
  generateResult(message:any,token:string): Observable<any> {
    const apiKey =token;
    const headers = { Authorization: `Bearer ${apiKey}` };

    const body = {
      model: this.modelName,
      response_format:{ "type": "json_object" },
      messages:message,
    };

    return this.http.post<any>(this.apiUrl, body, { headers,context:new HttpContext().set(BYPASS_LOG, true) });
  }
}
