import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators ,FormControl,ValidatorFn} from '@angular/forms';
import { first } from 'rxjs/operators';
import { AccountService } from '../../services/acccount/account.service';
import Swal from 'sweetalert2';

interface ApiResponse {
  status: any;
  // Other properties in the response object, if any
}
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  name
  SignupForm: FormGroup;
  SignupFormObj
  password: string = ''; // Initialize the password
  showPassword: boolean = false;

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
  ) { }

   stringValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    const isValid = /^[a-zA-Z]+$/.test(value);
    return isValid ? null : { invalidString: true };
  };

   emailValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    return isValid ? null : { invalidEmail: true };
  };

  passwordValidator: ValidatorFn = (control: FormControl) => {
    const password = control.value;

    const hasEightCharacters = /.{8,}/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    // const hasSpecialChar = /[^A-Za-z0-9]/.test(password);

    const isValid = hasEightCharacters && hasLowerCase && hasUpperCase && hasDigit;
    return isValid ? null : { invalidPassword: true };
  };

  ngOnInit(): void {
    this.SignupForm = new FormGroup({
      email: new FormControl('', [Validators.required,this.emailValidator]),
      password: new FormControl('', [Validators.required,this.passwordValidator]),
      termsAndCondition:new FormControl(true,Validators.requiredTrue),
    });

    const queryParams = this.route.snapshot.queryParams;
    this.name = queryParams['name'];
    if(this.name == '' || this.name == null){this.name = 'Applicant'}
  }

  onSubmit(SignupForm: FormGroup) {
    if (SignupForm.valid) {
      const formValue = SignupForm.value;  
      if (this.name == 'Applicant') {
        this.accountService.registerSeeker(formValue).subscribe(
          (res: any) => {
            if (res) {
              Swal.fire('Success', 'Account created', 'success');
              SignupForm.reset(); // Clear form fields
            }
          },
          err => {
            console.log(err);
            let errorMessage = err.error?.messages?.[0] || 'An error occurred';
            Swal.fire('Error', errorMessage, 'error');
          }
        );
      } else {
        this.accountService.registerRecruiter(formValue).subscribe(
          (res: any) => {
            if (res) {
              Swal.fire('Success', 'Account created', 'success');
              SignupForm.reset(); // Clear form fields
            }
          },
          err => {
            let errorMessage = '';
            if (err.status === 'CONFLICT' && err.error?.messages?.[0]) {
              errorMessage = 'User already exists';
            }
            Swal.fire('Error', errorMessage || 'An error occurred', 'error');
          }
        );
      }
    }else if(this.SignupForm.invalid && this.SignupForm.value.termsAndCondition == false){
      Swal.fire('Error', 'Please Accept the terms and Condition', 'error');
    } 
    else {
      Swal.fire('Opps !', 'Please fill all form fields', 'error');
    }
  }

  switchToApplicant(){
    this.name = "Applicant"

  }
  switchToRecruiter(){
    this.name = "Recruiter"
  }
  
}
