import { AbstractControl, ValidatorFn } from '@angular/forms';

export function contactNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = /^[6-9]\d{9}$/;// Regular expression to match 10 digit phone numbers or starts with 6 7 8 9
  
      if (control.value && !regex.test(control.value)) {
        console.log("invalid invalidContactNumberer")
        return { 'invalidContactNumber': true };
        
      }
      return null;
    };
  }
