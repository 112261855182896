<div class="resume-builder-area">
    <div class="page-title">
        <h2>Resume Builder</h2>
    </div>
    <div class="conatiner-fluid row m-0">
        <div class="d-none d-md-block col-md-2">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="continer col-md-8 pb-5">
            <div class="row">
                <span class="page-title">Selecte Template</span>
                <div class="col-12 resume-template-design">
                    <button class="resume-template-btn" data-tooltip="Basic Series" ><img src="../../../../assets/img/resume-templates/BasicTemplate.png" alt="" (click)="loadDocument1()"></button>
                    <button class="resume-template-btn" data-tooltip="Two Column" ><img src="../../../../assets/img/resume-templates/twoColumnTemplate.png" alt="" (click)="loadDocument2()"></button>
                    <button class="resume-template-btn" data-tooltip="Profile Banner"><img src="../../../../assets/img/resume-templates/ProfileBannerTemplate.png" alt="" (click)="loadDocument3()"></button>
                    <button class="resume-template-btn" data-tooltip="Mono Tone"><img src="../../../../assets/img/resume-templates/MonoToneTemplate.png" alt="" (click)="loadDocument4()"></button>
                    <button class="resume-template-btn" data-tooltip="Sunny Section"><img src="../../../../assets/img/resume-templates/sunnySectionTemplate.png" alt="" (click)="loadDocument5()"></button>
                    <button class="resume-template-btn" data-tooltip="Basic Fresher"><img src="../../../../assets/img/resume-templates/BasicTwo.png" alt="" (click)="loadDocument6()"></button>
                </div>
                <span class="page-title"><i class="bi bi-stars"></i> {{Tip}}</span>
                <div class="col-12 resume-template-preview-area">
                    <div class="resume-preview">
                        <iframe [src]="file" width="100%" height="100%" type="application/pdf"></iframe>
                    </div>
                    <div class="resume-preview-mobile">
                        <div class="resume-download-btn-div">
                            <!-- <button class="resume-download-btn" (click)="previewOnMobile()"><i class="bi bi-eye"></i>&nbsp;&nbsp;Preview</button> -->
                            &nbsp;&nbsp;
                            <button class="resume-download-btn" (click)="downloadOnMobile()"><i class="bi bi-download"></i>&nbsp;&nbsp;Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>

</div>