import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckboxRequiredValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AccountService } from '../../services/acccount/account.service';
import Swal from 'sweetalert2';
import { ContactUsService } from '../../services/other/contact-us.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  Toast:any;
  roles:any;
  password: string = ''; // Initialize the password
  showPassword: boolean = false;

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private contactUsService: ContactUsService,
  ) { }

  ngOnInit(): void {
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.contactUsService.emailId$.subscribe(
      (email:any)=> {this.loginForm.patchValue({
        username:email,
      })},
      (error:any) => {console.log(error);})
  }

  onSubmit(){    
    if(this.loginForm.valid){
      this.accountService.login(this.loginForm.value.username, this.loginForm.value.password).pipe(first()).subscribe(
        (response)=>{
          if (response.status == 200){
            const jsonResponse = JSON.parse(response.body);
            this.roles = jsonResponse.roles // all the roles from login details
            if (this.roles.length == 0){
              this.Toast.fire({
                icon: 'error',
                title: 'User is not registered'
              });
            }
            this.roles.forEach(role => {
              const userRole = role.roleCode;
              if (userRole == 'RECRUITER'){
                this.accountService.saveLoginDetails(jsonResponse.username, userRole, jsonResponse.collegeCode, jsonResponse.token);
                this.Toast.fire({
                  icon: 'success',
                  title: 'Logged in Successfully'
                });
                window.location.href = "/recruiter";
              }else if (userRole == 'TPO'){
                const data = {
                  "email":this.loginForm.value.username,
                  "password":this.loginForm.value.password,
                }
                this.accountService.registerTpo(data).subscribe(
                  (res: any) => {
                    console.log(res)
                  },
                  err => {
                    console.log(err)
                  }
                );
                this.accountService.saveLoginDetails(jsonResponse.username, userRole, jsonResponse.collegeCode, jsonResponse.token);
                this.Toast.fire({
                  icon: 'success',
                  title: 'Logged in Successfully'
                });
                window.location.href = "/recruiter";
              }
              else if (userRole == 'JOBSEEKER'){
                this.accountService.saveLoginDetails(jsonResponse.username, userRole, jsonResponse.collegeCode, jsonResponse.token);
                this.Toast.fire({
                  icon: 'success',
                  title: 'Logged in Successfully'
                });
                window.location.href = "/candidate";
              }
              else if (userRole == 'STUDENT_DEFAULT'){
                const data = {
                  "email":this.loginForm.value.username,
                  "password":this.loginForm.value.password,
                  "collegeCode": jsonResponse.collegeCode,
                  "branchCode": jsonResponse.pursuing.courseCode,
                  "semesterCode":jsonResponse.pursuing.semesterCode,
                  "academicYearCode":jsonResponse.pursuing.academicYearCode,
                }
                this.accountService.registerStudSeeker(data).subscribe(
                  (res: any) => {
                    console.log(res)
                  },
                  err => {
                    console.log(err)
                  }
                );
                this.accountService.saveLoginDetails(jsonResponse.username, userRole, jsonResponse.collegeCode, jsonResponse.token);
                this.accountService.savePursuing(jsonResponse.pursuing);
                this.Toast.fire({
                  icon: 'success',
                  title: 'Logged in Successfully'
                });
                window.location.href = "/candidate";
              }
            });
          }            
        },
        (error)=>{
            this.Toast.fire({
              icon: 'error',
              title: 'Username or Password is Incorrect'
            });
        }
        )
    }
    else{
      this.Toast.fire({
        icon:"error",
        title:"Please fill the login details"
      })
    }
  }

}
