<section class="profile-area profile-area-two pt-100 row m-0">
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
    </div>
    <div class="container col-md-8">
        <div class="candidate-list-heading">
            <h2>Candidate List</h2>
        </div>
        <div class="col card card-statistic-4 border-0">
            <div class="align-items-center justify-content-between">
                <div class="card-content p-5">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Select Branch</label>
                                <select class="form-select w-60" [(ngModel)]="selectedBranch"
                                    (ngModelChange)="onBranchSelect();" required>
                                    <option *ngFor="let br of branches" [value]="br.collegeCourseCode">
                                        {{br.course.courseName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Select Year</label>
                                <select class="form-select w-60" [(ngModel)]="selectedAcademicYear"
                                    (ngModelChange)="onAcademicYearSelect();" required>
                                    <option *ngFor="let st of acadYear" [value]="st.academicYearCode">
                                        {{st.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Select Semester</label>
                                <select class="form-select w-60" [(ngModel)]="selectedSem" required>
                                    <option *ngFor="let st of sem" [value]="st.semesterCode">
                                        {{st.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-3 d-flex">
                            <div class="example-button-row mx-auto my-auto">
                                <button [disabled]="!selectedSem" class="btn create-ac-btn p-2 fs-5 mt-3"
                                    (click)="getCandidate();">View Candidate</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row pt-5" style="min-height: 300px;">
            <div *ngIf="itemLength; else noOptionsToShow ">
                <div>
                    <div ngbDropdown class="d-inline-block float-end">
                        <button type="button" class="btn " id="dropdownBasic1" ngbDropdownToggle>
                            <i class="icofont-listine-dots"></i>
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button [disabled]="!itemLength" ngbDropdownItem (click)="downloadAsCSV(selectedAcademicYear,selectedSem)">Download
                                CSV</button>
                        </div>
                    </div>
                </div>
                <table class="table table-striped" id="Excel-Table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col" sortable="name">Candidate</th>
                            <th scope="col" sortable="area">Email</th>
                            <th scope="col" sortable="area">Contact</th>
                            <th scope="col" sortable="area">Resume</th>
                            <th scope="col" sortable="area">Profle Progress</th>
                            <!-- this one is for excel  -->
                            <th scope="col" sortable="area" style="display: none;">Profle Progress</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of candidateList;index as i">
                            <td>{{ i + 1 }}</td>
                            <td class="anchor-link" routerLink="/candidate-details"
                                (click)="viewCandidateProfile(data?.email)" style="cursor: pointer;">{{data?.firstName}}</td>
                            <td class="anchor-link" routerLink="/candidate-details"
                                (click)="viewCandidateProfile(data?.email)" style="cursor: pointer;">{{data?.email}}</td>
                            <td>{{ data.contactNumbers[0].number }}</td>
                            <td><button class="btn btn-outline-dark" (click)="downloadResume(data.resume_link, data.firstName,data.lastName)" [disabled]="!data.resume_link"><i class="icofont-download btn"></i></button></td>
                            <td class="star-rating">
                                <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
                                    <span class="star" [class.filled]="star <= data.profileProgress" *ngIf="star <= 4 ">&#9733;</span>
                                    <span class="star" [class.filled]="data.profileProgress == 5" [class.half-filled]="data.profileProgress == 4.5" *ngIf="star == 5 ">&#9733;</span>
                                </ng-container>
                            </td>
                            <!-- this one is for excel  -->
                            <td style="display: none;">{{data.profileProgress}}</td>
                        </tr>
                    </tbody>
                </table>
                <div style="display:flex; justify-content: center; ">
                    <ngb-pagination [collectionSize]="itemLength" [(page)]="page" [pageSize]="pageSize" aria-label="Default pagination bg-warning"></ngb-pagination>
                </div>
            </div>
            <ng-template #noOptionsToShow>
                <div class="text-center">
                    <h2>No records to show</h2>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                            [fullWidthResponsive]="true">
                        </ng-adsense>
                    </div>
                    <div class="col-md-4">
                        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                            [fullWidthResponsive]="true">
                        </ng-adsense>
                    </div>
                    <div class="col-md-4">
                        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                            [fullWidthResponsive]="true">
                        </ng-adsense>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                            [fullWidthResponsive]="true">
                        </ng-adsense>
                    </div>
                    <div class="col-md-4">
                        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                            [fullWidthResponsive]="true">
                        </ng-adsense>
                    </div>
                    <div class="col-md-4">
                        <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                            [fullWidthResponsive]="true">
                        </ng-adsense>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
    </div>
</section>