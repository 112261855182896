<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Favourite Job</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Favourite Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="job-area job-area-two pt-100 row m-0">
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
    <div class="container col-md-8">
        
        <div class="row"  >
            <div  *ngIf="itemLength === 0" >
                <div class="d-flex justify-content-center m-4">
                    <h2 >No posted jobs</h2>
                </div>
                
            </div>
            <div class="col-lg-9 mx-auto"*ngFor="let item of postedJobs  | slice: (page-1) * pageSize : page * pageSize">
               
                <div class="job-item">
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <div style="display: flex; align-items: center; justify-content: space-between;">
                                <!-- http://192.168.29.155:8081/recruiter/applicants?job_post_id=853&recruiter_id=5 -->
                                <!-- <h3 routerLink="/job-details/{{item.job_post_id}}"  class="anchor-link">  {{item.job_title}} </h3>  -->
                                <h3  routerLink="/application-statics/{{item.id}}" [queryParams]="{ job_post_id: item.id}">  {{item.jobTitle}} </h3> 
                                <span > Posted on:  {{item.postDate | date:'mediumDate' }}</span>
                            </div>
                            <ul>
                                <li style="display: flex;flex-wrap: wrap;align-items:baseline;"><i class="icofont-web"></i>Website: <a href="{{item.website}}" target="_blank" style="font-size: small;margin-bottom: 0;">  {{item.website | slice:0:30}}...</a></li>
                                <li><i class="icofont-money-bag"></i>Salary Range:
                                    <ng-container *ngIf="item.salaryRange1 === 0 && item.salaryRange2 === 0; else salaryRange">
                                        Not Mentioned
                                    </ng-container>
                                    <ng-template #salaryRange>
                                        {{ item.salaryRange1 }} - {{ item.salaryRange2 }}
                                    </ng-template>
                                </li>
                                <li><i class="icofont-location-pin"></i>Location:{{item.jobLocation}}</li>
                            </ul>
                        </div>

                        <div class="job-inner-right ">
                            <!-- <i class="icofont-delete position-absolute" (click)="deleteJob(item.id)" style="font-size:32px; top: 0px; left:95%;"></i> -->
                            <button class="editbtn" routerLink="/edit-job" [queryParams]="{jobId: item.id}">Edit</button>
                            <br>
                            <br>
                            <button class="editbtn" routerLink="/application-statics/{{item.id}}" [queryParams]="{ job_post_id: item.id}" >Applicants</button>
                        </div>
                    </div>
                </div>
            </div>

           
        </div>
        <div style="display:flex; justify-content: center; ">
                    
            <ngb-pagination
              [collectionSize]="itemLength"  
              [(page)]="page"
              [pageSize]="pageSize"
              aria-label="Default pagination bg-warning"
              ></ngb-pagination>
        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
</section>