import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from '../../../../environments/env'
import { GlobalConstants } from '../../../global';
@Injectable({
  providedIn: 'root'
})
export class AppliedJobService {

  constructor(

    private httpClient: HttpClient
  ) { }
  // Jobpost list on which a specific seeker applied so far
  seekerAppliedJobListById(userID) {
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/jobpost/list?seeker_id=${userID}&is_applied`, { observe: 'response' });

  }
  candidateListGetById(id) {
    return this.httpClient.get(`/jobpost/list?seeker_id=${id}`, { observe: 'response' });

  }

  appliedJobInfoPatch(recruiterStatus, recruiterID, jobApplyId) { 
    const data = {
      "recruiterStatus": recruiterStatus,
      "jobApplyId":jobApplyId
    }
    return this.httpClient.put<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/job-apply/modifie-applicant-status?recruiterId=${recruiterID}`, data, { observe: 'response' });

  }
}
