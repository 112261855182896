import { Component, OnInit } from '@angular/core';
import { JobListService } from '../../services/job-list/job-list.service';
import { AccountService } from '../../services/acccount/account.service';
@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {
  jobList:[];
  el:HTMLElement;
  constructor(private jobListService : JobListService, private accountService: AccountService,) { 
    accountService.functionCall$.subscribe(() => {
      this.scroll();
    });
  }

  ngOnInit(): void {
    this.jobListService.homeJobForNoneUser().subscribe(
      (res:any) => {if(res){this.jobList = res.body}}
    )
  }


  scroll() {
    const section = document.getElementById("AvailableJobsSection");
    section.scrollIntoView({behavior: 'smooth'});
  }

}
