// import { Component, OnInit } from '@angular/core';
// import {Observable} from 'rxjs';
// import { ModalService } from '../../services/popup-modal/modal.service';

// @Component({
  //   selector: 'app-popup-modal-component',
  //   templateUrl: './popup-modal-component.component.html',
  //   styleUrls: ['./popup-modal-component.component.scss']
  // })
  
  
  // export class PopupModalComponentComponent implements OnInit {
    
    //   display$: Observable<'open' | 'close'>;
    
    //   constructor(
      //       private modalService: ModalService
      //   ) {}
      
      //   ngOnInit() {
        //     this.display$ = this.modalService.watch();
        //   }
        
        //   close() {
          //     this.modalService.close();
          //   }
          //   open() {
            //     this.modalService.open();
            //   }
            // }

import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
            
 @Component({
  selector: 'app-popup-modal-component',
	standalone: true,
  templateUrl: './popup-modal-component.component.html',
	providers: [NgbModalConfig, NgbModal],
})
export class PopupModalComponentComponent {
  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
		config.backdrop = 'static';
		config.keyboard = false;
	}
  
	open(content) {
    this.modalService.open(content);
	}
}