import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactUsService } from '../../services/other/contact-us.service';
import { AccountService } from '../../services/acccount/account.service';
import { RecruiterServicesService } from '../../services/candidate-services copy/recruiter-services.service';

@Component({
  selector: 'app-recruiter-home',
  templateUrl: './recruiter-home.component.html',
  styleUrls: ['./recruiter-home.component.scss']
})
export class RecruiterHomeComponent implements OnInit {
  role;
  category;
  jobPortalStats: any;
  searchForm: FormGroup;

  // Values For profile progress
  item1: boolean; item2: boolean; item3: boolean; item4: boolean;

  constructor(
    private route: ActivatedRoute, 
    private contactUsservice: ContactUsService, 
    private accountService:AccountService,
    private recruiterServices:RecruiterServicesService, 
    ) { }

  ngOnInit(): void {
    this.contactUsservice.getJobPortalStats().subscribe((resp: any) => { this.jobPortalStats = resp.body });
    this.route.queryParams.subscribe((params) => {
      this.role = params.role;

      this.role = "RECRUITER";
    });

    this.searchForm = new FormGroup({
      job_title: new FormControl(''),
      location: new FormControl('')

    })

    this.recruiterServices.recruiterGetByUsername(this.accountService.getUsername()).subscribe(
      res=>{this.calculateRatings(res.body)});
  }

  calculateRatings(data: any): void {
    this.item1 = Boolean(data.firstName && data.lastName && data.email && data.contactNumbers && data.position);
    this.item2 = Boolean(data.companyDetails);
    this.item3 = Boolean(data.recruiterImageUrl);
    this.item4 = Boolean(data.updateLogo);
  }

}
