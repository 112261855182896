 
<div class="post-job-area pt-100 row m-0">
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
    <div class="container col-md-8">
        <div class="post-job-item">
            <form   [formGroup]="jobForm" (ngSubmit)="onSubmit(jobForm)">
                <div class="post-job-heading">
                    <h2>Edit Job</h2>
                    <div *ngIf="userRole == 'TPO' && collegeCode; else elseBlock">
                        <h4 *ngIf="branchCode; else elseBlock">Job Posted For : {{branchCode}} - {{yearCode}} - {{semesterCode}} </h4>
                        <ng-template #elseBlock>
                            <h4>Job Posted For All Branch/Students</h4>
                        </ng-template>
                    </div>
                    <ng-template #elseBlock><h4 *ngIf="userRole == 'TPO'" >Hiring College Staff</h4></ng-template>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Job Title</label>
                            <input type="text" class="form-control" placeholder="UX/UI Designer"  formControlName="job_title"
                            [ngClass]="{ 'invalid-field': (jobForm.get('job_title').invalid && (jobForm.get('job_title').touched || submitted)) }">
                        </div>
                    </div>

                    <!-- <div class="col-lg-6">
                        <div class="form-group">
                            <label>Job Category</label>
                            <div class="job-category-area">
                                <select formControlName="job_category" [(ngModel)]="selectedDomain" >
                                    <option *ngFor="let item of domain"  [value]="item">
                                        {{ item }}  
                                    </option>
                                </select>	
                            </div>
                        </div>
                    </div>
                    </div> -->

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Company Website</label>
                            <input type="text" class="form-control" 
                            [ngClass]="{'invalid-field': jobForm.get('website').invalid && jobForm.get('website').touched}"
                            placeholder="mycompany.com" formControlName="website"
                            [ngClass]="{ 'invalid-field': (jobForm.get('website').invalid && (jobForm.get('website').touched || submitted)) }">
                        </div>
                    </div>

                    <!--  <div class="col-lg-6">
                        <div class="form-group">
                            <div class="job-currency-area">
                                <label>Company Name</label>
                                <input type="text" class="form-control" placeholder="Winbrans.com"  formControlName="job_title">
                                <select>
                                    <option>$USD</option>
                                    <option>EURO</option>
                                    <option>POUND</option>
                                </select>	
                            </div>
                        </div>
                    </div> -->
                    
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Vacancies</label>
                            <input type="number" class="form-control" placeholder="30"  formControlName="vacancies"
                            [ngClass]="{ 'invalid-field': (jobForm.get('vacancies').invalid && (jobForm.get('vacancies').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control"
                            [ngClass]="{'invalid-field': jobForm.get('email').invalid && jobForm.get('email').touched}"
                            placeholder="abc@gmail.com"  formControlName="email"
                            [ngClass]="{ 'invalid-field': (jobForm.get('email').invalid && (jobForm.get('email').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Salary Range</label>
                            <div class="d-flex">
                                <input type="number" class="form-control" placeholder="10" min="0" max="9999"  formControlName="salary_range1" style="width: 48%;"
                                [ngClass]="{ 'invalid-field': (jobForm.get('salary_range1').invalid && (jobForm.get('salary_range1').touched || submitted)) }">
                                &nbsp;_&nbsp;
                                <input type="number" class="form-control" placeholder="15" min="0" max="9999"  formControlName="salary_range2" style="width: 48%;"
                                [ngClass]="{ 'invalid-field': (jobForm.get('salary_range2').invalid && (jobForm.get('salary_range2').touched || submitted)) }">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Last Date</label>
                            <input type="date" class="form-control" value="{{myDate | date:'yyyy-MM-dd'}}" min="{{myDate | date:'yyyy-MM-dd'}}" formControlName="last_date"
                            [ngClass]="{ 'invalid-field': (jobForm.get('last_date').invalid && (jobForm.get('last_date').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Experience Required (Years)</label>
                            <input type="number" class="form-control" placeholder="5" formControlName="experience_required" 
                            [ngClass]="{ 'invalid-field': (jobForm.get('experience_required').invalid && (jobForm.get('experience_required').touched || submitted)) }">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Location</label>
                            <input type="text" class="form-control" placeholder="Mumbai, Maharastra"
                            formControlName="job_location"
                            [ngClass]="{ 'invalid-field': (jobForm.get('job_location').invalid && (jobForm.get('job_location').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>education_qualification</label>
                            <input type="text" class="form-control" placeholder="BTech / MBA"
                            formControlName="education_qualification"
                            [ngClass]="{ 'invalid-field': (jobForm.get('education_qualification').invalid && (jobForm.get('education_qualification').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Address</label>
                            <input type="text" class="form-control" placeholder="210-27 Quadra, Market Street, Victoria Canada"
                            formControlName="address"
                            [ngClass]="{ 'invalid-field': (jobForm.get('address').invalid && (jobForm.get('address').touched || submitted)) }">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Contact No</label>
                            <input type="text" class="form-control" placeholder="0 123 456 7891"
                            [ngClass]="{'invalid-field': jobForm.get('contact_no').invalid && jobForm.get('contact_no').touched}"
                            formControlName="contact_no"
                            [ngClass]="{ 'invalid-field': (jobForm.get('contact_no').invalid && (jobForm.get('contact_no').touched || submitted)) }">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group col-lg-6">
                            <div class="job-type-area">
                                <span>Job Type</span>
                            
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="job_category" name="job_category" id="inlineRadio1" value="Full Time" checked >
                                    <label class="form-check-label" for="inlineRadio1">Full Time</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="job_category" name="job_category" id="inlineRadio2" value="Part Time" >
                                    <label class="form-check-label" for="inlineRadio2">Part Time</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="job_category" name="job_category" id="inlineRadio3" value="Intern">
                                    <label class="form-check-label" for="inlineRadio3">Intern</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6">
                            <!-- <div class="job-type-area">
                                <span>Gender</span>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="gender" name="gender" id="genderRadio1" value="Male" >
                                    <label class="form-check-label" for="genderRadio1">Male</label>
                                </div>

                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="gender" name="gender" id="genderRadio2" value="Female">
                                    <label class="form-check-label" for="genderRadio2">Female</label>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Job Description</label>
                            <div style="position: relative;">
                            <textarea id="your_message" rows="8" class="form-control"
                            [(ngModel)]="textInput1" (input)="onInput1()" maxlength="500"
                            formControlName="description"
                            [ngClass]="{ 'invalid-field': (jobForm.get('description').invalid && (jobForm.get('description').touched || submitted)) }"></textarea>
                            <p style="position: absolute; bottom: 0; right: 0;margin-right: 5px; color: gray;">Remaining characters: {{ remainingCharacters1 }}</p>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Company Description</label>
                            <div style="position: relative;">
                            <textarea id="your_message" rows="8" class="form-control"
                            [(ngModel)]="textInput" (input)="onInput()" maxlength="500"
                            formControlName="company_description"
                            [ngClass]="{ 'invalid-field': (jobForm.get('company_description').invalid && (jobForm.get('company_description').touched || submitted)) }">
                           </textarea>
                           <p style="position: absolute; bottom: 0; right: 0;margin-right: 5px; color: gray;">Remaining characters: {{ remainingCharacters }}</p>
                        </div>
                        </div>
                    </div>
                </div>
                
                <div class="text-left">
                    <button type="submit" class="btn create-ac-btn">{{editbtn}}</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a class="btn create-ac-btn" (click)="deleteJob(jobId)">DELETE</a>
                </div>
            </form>
        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
</div>