<div class="banner-area" style="height: 275px; padding: 0 50px ;background-color: darkgray;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="banner-text" style="padding-top:70px;">

                <div class="banner-form-area">
                    <form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm)">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label><i class="icofont-search-1"></i></label>
                                    <input type="text" class="form-control" placeholder="{{job_title}}"
                                        formControlName="jobTitle">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label><i class="icofont-location-pin"></i></label>
                                    <input type="text" class="form-control" placeholder="{{location}}"
                                        formControlName="jobLocation">
                                </div>
                            </div>

                        </div>

                        <button type="submit" class="btn banner-form-btn">Search</button>
                    </form>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="row d-flex mt-2 mx-0">
    <div class="text-center">
        <h2>Job Search</h2>
    </div>
    <div style="width: 150px;margin-left: auto;">
        <select class="form-select" aria-label="Default select example" value="alljobs" id="filters" (change)="sortBy()">
            <option value="alljobs">All Jobs</option>
            <option Value="date">Filter By Date</option>
        </select>
    </div>
</div>


<section class="job-area job-area-two pt-5 row m-0">
    <div class="d-none d-md-block col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
    <div class="container col-md-8">

        <ng-container *ngIf="itemLength; else noOptionToShow">
            <div class="row">
                <div class="col-lg-9 mx-auto" *ngFor="let item of jobPost | slice: (page-1) * pageSize : page * pageSize">
                    <div class="job-item">
                        <div class="job-inner align-items-center p-0">
                            <div class="job-inner-left">
                                <div style="display: flex; align-items: center;">
                                    <h3 (click)="viewed(item.id)" routerLink="/job-details/{{item.id}}"
                                        [queryParams]="{ lastDateExpired: lastDateExpired}" class="anchor-link">
                                        {{item.jobTitle}} </h3>
                                </div>
                                <ul>
                                    <li><i class="icofont-building-alt"></i> Organization : {{item.companyName}}</li>
                                    <li><i class="icofont-user-alt-2"></i> Recruiter : {{item.recruiterFname}}
                                        {{item.recruiterLname}}</li>
                                    <li>
                                        <i class="icofont-money-bag"></i> Salary Range :
                                        <ng-container *ngIf="item.salaryRange1 === 0 && item.salaryRange2 === 0; else salaryRange">
                                            Not Mentioned
                                        </ng-container>
                                        <ng-template #salaryRange>
                                            {{ item.salaryRange1 }} - {{ item.salaryRange2 }}
                                        </ng-template>
                                    </li>
                                    <li><i class="icofont-location-pin"></i>Location : {{item.jobLocation}}</li>
                                    <li><i class="icofont-calendar"></i>Posted On: {{item.postDate | date:'yyyy-MM-dd'}}
                                    </li>
                                </ul>
                            </div>
                            <div class="job-inner-right">
                                <ul>
                                    <div *ngIf="!item.isAppliedByJobseeker; else alreadyApplied">
                                        <li>
                                            <div [ngClass]="{ 'disabled-link': isLastDateExpired(item.lastDate) }">
                                                <ng-container
                                                    *ngIf="isLastDateExpired(item.lastDate); else applyButton">
                                                    <span>Expired</span>
                                                </ng-container>
                                                <ng-template #applyButton>
                                                    <a (click)="applyJob(item.id, this.candidateID)">Apply</a>
                                                </ng-template>
                                            </div>
                                            <!-- <a [ngClass]="{ 'disabled-link': isLastDateExpired(item.last_date) }" (click)="applyJob(item.job_post_id, this.candidateID)">Apply</a> -->


                                        </li>
                                        <span>
                                            <p>Last Date</p>
                                            <p>{{item.lastDate | date:'mediumDate'}}</p>
                                        </span>
                                    </div>
                                    <div>

                                        <ng-template #alreadyApplied>
                                            <li>
                                                <span>Applied</span>
                                            </li>
                                        </ng-template>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="display:flex; justify-content: center; ">

                    <ngb-pagination [collectionSize]="itemLength" [(page)]="page" [pageSize]="pageSize"
                        aria-label="Default pagination bg-warning"></ngb-pagination>
                </div>


            </div>
        </ng-container>
        <ng-template #noOptionToShow>
            <h2 class="text-center">{{noJobToShowMeassage}}</h2>
        </ng-template>
    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
</section>