import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { RecruiterServicesService } from '../../services/candidate-services copy/recruiter-services.service';
import { S3uploadapiService } from '../../services/s3upload/s3uploadapi.service';
import Swal from 'sweetalert2';
import { AccountService } from '../../services/acccount/account.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

function validateContactNumber(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!/^[0-9]{10}$/.test(value)) {
      return { invalidContactNumber: true };
    }

    return null;
  };
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './recruiter-dashboard.component.html',
  styleUrls: ['./recruiter-dashboard.scss']
})
export class RecruiterDashboardComponent implements OnInit {

  profilePhoto: any;
  userRole: string;
  myDate = Date.now()
  recruiterForm: FormGroup;
  companyForm: FormGroup;
  socialLinks
  recruiterId;
  recruiterUserName: string;
  recruiterInfo: any = {}
  CompanyInfo: any = {}
  id: any
  selectedProfile: File;
  selectedResume: File;
  selectedProfileName: any;
  selectedResumeName: any;
  resumeName: string;
  resumeLink: string;
  imageName: string;
  imageLink: string;
  sliderValue = 50;

  textInput: string = '';
  remainingCharacters: number = 500;
  onInput() {
    this.remainingCharacters = 500 - this.textInput.length;
  }

  stringValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    const isValid = /^[a-zA-Z]+$/.test(value);
    return isValid ? null : { invalidString: true };
  };

  emailValidator: ValidatorFn = (control: FormControl) => {
    const value = control.value;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    return isValid ? null : { invalidEmail: true };
  };
  constructor(
    private recruiterServices: RecruiterServicesService,
    private fb: FormBuilder,
    private s3upload: S3uploadapiService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer

  ) {
    this.recruiterForm = this.fb.group({
      first_name: ['', [Validators.required, this.stringValidator]],
      last_name: ['', [Validators.required, this.stringValidator]],
      contact_number: ['', [Validators.required, validateContactNumber()]],
      email: ['', [Validators.required, this.emailValidator]],
      position: ['', [Validators.required]],
      gender: ['', [Validators.required]],
    });


    this.companyForm = this.fb.group({
      company_name: ['', [Validators.required]],
      company_email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      company_website: ['', [Validators.required]],
      about_company: ['', [Validators.required]],
      company_city: ['', [Validators.required]],
    });




  }

  ngOnInit(): void {
    this.recruiterUserName = this.accountService.getUsername();
    this.getCandidateInfo(this.recruiterUserName);
    this.userRole = this.accountService.getRole();
    this.accountService.getUserId().subscribe(
      (Response) => {
        this.recruiterId = Response.body;
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  // Method to download and Show profile pic
  downloadProfilePic(filePath: string) {
    this.recruiterServices.downloadProfilePic(filePath).subscribe((blob: Blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.profilePhoto = this.sanitizer.bypassSecurityTrustUrl(objectURL) as SafeUrl;
    });
  }
  // Utility method
  capitalizeAndTrim(value: string): string {
    return value.trim().toUpperCase();
  }
  isValidFile(file: File): boolean {
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    return allowedTypes.includes(file.type);
  }
  isValidFileSize(file: File, maxSize: number): boolean {
    return file.size <= maxSize;
  }
  isValidImage(file: File): boolean {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    return allowedTypes.includes(file.type);
  }
  showErrorAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: errorMessage,
    });
  }

  // Profile
  onProfileSelect(event) {
    this.selectedProfile = event.target.files[0];
    this.selectedProfileName = this.selectedProfile.name;
    if (!this.isValidImage(this.selectedProfile)) {
      this.showErrorAlert('Please select a JPEG, JPG, or PNG file');
      this.selectedProfileName = "";
      return;
    }
    if (!this.isValidFileSize(this.selectedProfile, 1048576)) {
      this.showErrorAlert('Please choose a Profile less than 1 MB');
      this.selectedProfileName = "";
      return;
    }
    const formData = new FormData();
    formData.append('file', this.selectedProfile, this.selectedProfileName);

    this.uploadImage(formData);
  }
  uploadImage(formData: FormData) {
    const folder = "JOBPORTAL";
    const userType = this.accountService.get("role");
    const collegeCode = this.accountService.get("branch");
    const id = this.recruiterId;
    const firstName = this.capitalizeAndTrim(this.recruiterForm.value.first_name);
    const lastName = this.capitalizeAndTrim(this.recruiterForm.value.last_name);
    // Since we dont have date of birth for recruiter we can using this date or the contact number 
    // const dateOfBirth = String(new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd'));
    const dateOfBirth = this.recruiterForm.value.contact_number;

    const swal = Swal.fire({
      title: 'Uploading Image',
      text: 'Please wait...',
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.recruiterServices.uploadProfilePic(folder, userType, collegeCode, firstName, lastName, dateOfBirth, id, formData)
      .subscribe(
        (res) => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Image uploaded successfully',
            });
            this.ngOnInit();
          }
        },
        (err) => {
          console.log(err);
          this.showErrorAlert('Image upload failed! Please try again');
        }
      );
  }

  // Resume
  async updateLogo(event) {
    this.selectedResume = event.target.files[0];
    this.selectedResumeName = this.selectedResume.name
    const type = "resume"
    const file = this.selectedResume;
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const maxFileSize = 10485760; // 10 MB

    if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
      try {
        let swal = Swal.fire({
          title: 'Uploading Resume',
          text: 'Please wait...',
          allowEscapeKey: false,
          showConfirmButton: false,
          allowOutsideClick: false,
        });

        let response: Promise<any> = this.s3upload.uploadFile(this.id, file, type)
        response.then((result) => {
          this.resumeName = result.data.Key
          this.resumeLink = result.data.Location
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Resume uploaded successfully',
          });
          this.ngOnInit()

        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Resume upload failed! Please try again',
          })
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Resume upload failed! Please try again',
        });
      }


    }
    else if (allowedTypes.includes(file.type) == false) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select a PDF, DOC, or DOCX file',
      })
    }
    else if (file.size <= maxFileSize == false) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please choose file less than 10 MB',
      })
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  }


  //logo
  updateCompanyLogo(event) {
    this.selectedProfile = event.target.files[0];
    this.selectedProfileName = this.selectedProfile.name;

    if (!this.isValidImage(this.selectedProfile)) {
      this.showErrorAlert('Please select a JPEG, JPG, or PNG file');
      this.selectedProfileName = "";
      return;
    }
    if (!this.isValidFileSize(this.selectedProfile, 1048576)) {
      this.showErrorAlert('Please choose a file less than 1 MB');
      this.selectedProfileName = "";
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedProfile, this.selectedProfileName);

    this.uploadLogo(formData);
  }
  uploadLogo(formData: FormData) {
    const folder = "JOBPORTAL";
    const userType = this.accountService.get("role");
    const collegeCode = this.accountService.get("branch");
    const id = this.recruiterId;
    const firstName = this.capitalizeAndTrim(this.recruiterForm.value.first_name);
    const lastName = this.capitalizeAndTrim(this.recruiterForm.value.last_name);
    // Since we dont have date of birth for recruiter we are using contact number
    const dateOfBirth = this.recruiterForm.value.contact_number;

    const swal = Swal.fire({
      title: 'Uploading Image',
      text: 'Please wait...',
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    this.recruiterServices.uploadCOmpanyLogo(folder, userType, collegeCode, firstName, lastName, dateOfBirth, id, formData)
      .subscribe(
        (res) => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Image uploaded successfully',
            });
            this.ngOnInit();
          }
        },
        (err) => {
          console.log(err);
          this.showErrorAlert('Image upload failed! Please try again');
        }
      );
  }

  getCandidateInfo(recruiterUserName: string) {
    this.recruiterServices.recruiterGetByUsername(recruiterUserName).subscribe(res => {
      this.recruiterInfo = res.body;
      this.resumeLink = this.recruiterInfo.resume_link;
      this.showCandidateData(this.recruiterInfo);
      this.showCompanyData(this.recruiterInfo.companyDetails);
      if (this.recruiterInfo.gender === 'MALE') {
        this.profilePhoto = 'assets/img/male_profile.png';
      } else if (this.recruiterInfo.gender === 'FEMALE') {
        this.profilePhoto = 'assets/img/female_profile.png';
      } else {
        this.profilePhoto = 'assets/img/neutral_profile.jpg'
      }
      if (this.recruiterInfo.recruiterImageUrl != null) {
        this.profilePhoto = this.recruiterInfo.recruiterImageUrl;
      }
      if (this.recruiterInfo.recruiterImageUrl != '' || this.recruiterInfo.recruiterImageUrl != null) {
        this.downloadProfilePic(this.recruiterInfo.recruiterImageUrl);
      }
    }, err => console.log(err)
    )
  }


  showCompanyData(data) {
    const handleNull = (value) => value || '';
    this.companyForm.patchValue({
      company_name: handleNull(data?.companyName),
      company_email: handleNull(data?.companyEmail),
      address: handleNull(data?.address),
      company_website: handleNull(data?.companyWebsite),
      about_company: handleNull(data?.aboutCompany),
      company_city: handleNull(data?.companyCity),
    });
  }

  showCandidateData(data) {
    const handleNull = (value) => value || '';

    this.recruiterForm.patchValue({
      first_name: handleNull(data.firstName),
      last_name: handleNull(data.lastName),
      email: handleNull(data.email),
      position: handleNull(data.position),
      contact_number: handleNull(data.contactNumbers[0]?.number),
      gender: handleNull(data.gender),
    });
  }



  onSubmit(form) {
    console.log(form);
    if (form.valid) {
      const recruiterPdForm = {
        "firstName": form.value.first_name,
        "lastName": form.value.last_name,
        "gender": form.value.gender,
        "email": form.value.email,
        "position": form.value.position,
        "contactNumbers": [{
          "contactType": "SELF",
          "number": form.value.contact_number
        }],
      }
      this.recruiterServices.recruiterInfoPatch(recruiterPdForm, this.recruiterUserName).subscribe(
        (res) => {
          if (res) {
            Swal.fire(
              'Data saved successfully',
              'Success',
              'success'
            )
            this.ngOnInit();
          }
        }, err => {
          console.log(err);
          let errorMessage = err.error?.messages?.[0] || 'An error occurred';
          Swal.fire('Error', errorMessage, 'error');
        })
    } else {
      Swal.fire(
        'Opps !',
        'Please fill in all fields Correctly',
        'error'
      )
    }
  }

  onSubmit2(form) {
    if (form.valid) {
      const comapnyForm = {
        "companyName": form.value.company_name,
        "companyEmail": form.value.company_email,
        "companyWebsite": form.value.company_website,
        "companyCity": form.value.company_city,
        "aboutCompany": form.value.about_company,
        "address": form.value.address,
      }
      this.recruiterServices.companyInfoPatch(comapnyForm, this.recruiterUserName).subscribe(
        (res) => {
          if (res) {
            Swal.fire('Data saved successfully', 'Success', 'success'
            )
            this.ngOnInit();
          }
        },
        error => {
          console.log(error);
          Swal.fire('Some error Occured !', 'try again', 'error');
        })
    } else {
      Swal.fire(
        'Opps !',
        'Please fill in all fields Correctly',
        'error'
      )
    }
  }


}
