import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators,} from '@angular/forms';
import { JobListService } from '../../services/job-list/job-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { env } from '../../../../environments/env'
import { AccountService } from '../../services/acccount/account.service';
import { PopupService } from '../../services/other/popup.service';


@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit {
  editbtn = "Save";
  postedJobs
  itemLength
  recruiterId: string;
  jobId: any;
  jobinfo: any = {};
  jobForm: FormGroup;
  isSubmitted = false;
  domain = ["Backend", "Frontend", "UI/UX", "SEO"]
  selectedDomain
  myDate = Date.now()
  jobFormObj;
  userRole:string;
  collegeCode:String;
  branchCode:String;
  semesterCode:string;
  yearCode:string;
  submitted=false;
  constructor(
    private jobListService: JobListService,
    private accountService:AccountService,    
    private route: ActivatedRoute,
    private http: HttpClient,
    private popup: PopupService,
    private router: Router,

  ) { }

  textInput: string = '';
  remainingCharacters: number = 500;

  onInput() {
    this.remainingCharacters = 500 - this.textInput.length;
  }
  textInput1: string = '';
  remainingCharacters1: number = 500;

  onInput1() {
    this.remainingCharacters1 = 500 - this.textInput1.length;
  }

  ngOnInit(): void {
    this.jobForm = new FormGroup({
      job_title: new FormControl('', [Validators.required,]),
      website: new FormControl('', [Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)]),
      vacancies: new FormControl('', [Validators.required]),
      salary_range1: new FormControl('', [Validators.required]),
      salary_range2: new FormControl('', [Validators.required]),
      last_date: new FormControl('', [Validators.required]),
      experience_required: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      job_location: new FormControl('', [Validators.required]),
      education_qualification: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', [Validators.required]),
      contact_no: new FormControl('', [Validators.required,]),
      description: new FormControl('', [Validators.required]),
      company_description: new FormControl('', [Validators.required]),
      job_category: new FormControl('', [Validators.required]),
    
    });

    this.route.queryParams.subscribe(params => {
      this.jobId = params['jobId'];
    });
    this.userRole = this.accountService.getRole();
    this.accountService.getUserId().subscribe(
      (response) => {
        this.recruiterId = response.body;

        this.jobListService.editPostedJobs(this.recruiterId, this.jobId).subscribe(res => {
          this.jobinfo = res.body.job_post[0];
          this.collegeCode = this.jobinfo.college;
          this.branchCode = this.jobinfo.branch?.replace(/^[^_]+_/, '');
          this.semesterCode = this.jobinfo.sem?.replace(/^[^_]*_[^_]*_/, '');
          this.yearCode = this.jobinfo.year?.replace(/^[^_]*_[^_]*_/, '');
          this.showJodData(this.jobinfo);
        });
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  showJodData(data: any) {
    this.jobForm.patchValue({
      job_title: data.jobTitle,
      job_category: data.jobCategory,
      job_location: data.jobLocation,
      last_date: data.lastDate,
      education_qualification: data.educationQualification,
      vacancies: data.vacancies,
      salary_range1: data.salaryRange1,
      salary_range2: data.salaryRange2,
      email: data.email,
      experience_required: Number(data.experienceRequired.replace(/ Years/g, "")),
      contact_no: data.contactNo,
      website: data.website,
      address: data.address,
      description: data.description,
      company_description: data.companyDescription,
      recruiterId:data.recruiterId
    })
  }

  deleteJobCall(jobId){
    this.jobListService.deleteJob(jobId, this.recruiterId)
    .subscribe(resp => {
      if(resp.status == 200){
        console.log(resp)
        this.popup.Swal.fire(
          'Deleted!',
          'The job has been deleted.',
          'success'
        );
        this.router.navigate(['/posted-jobs'])
      }else{
        this.popup.Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong',
        });
      }
    }, err => {
      console.log(err)
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while deleing job!',
      });
    });
  }
  deleteJob(jobId) {
    this.popup.Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this job?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteJobCall(jobId)
      }
    });
  }

  onSubmit(jobForm: FormGroup) {
    this.submitted=true;
    this.jobFormObj = jobForm.value
    let start_salary = Number(jobForm.value['salary_range1']);
    let end_salary = Number(jobForm.value['salary_range2']);
    console.log(start_salary,end_salary);
    console.log(this.userRole == "RECRUITER" , start_salary <=0 || end_salary <= 0 || start_salary >= end_salary);
    if (this.userRole == "RECRUITER" && (start_salary <=0 || end_salary <=0 )) {
      if (start_salary >= end_salary) {
        Swal.fire(
          'Error !',
          'To salary-1 should be greater than from salary-2',
          'question'
        );
      }
    } else if (jobForm.valid && this.userRole == "TPO") {
      const jobFormData = {
        id: this.jobId,
        jobTitle: jobForm.value.job_title,
        jobCategory: jobForm.value.job_category,
        jobLocation: jobForm.value.job_location,
        lastDate: jobForm.value.last_date,
        educationQualification: jobForm.value.education_qualification,
        vacancies: jobForm.value.vacancies,
        salaryRange1: jobForm.value.salary_range1,
        salaryRange2: jobForm.value.salary_range2,
        email: jobForm.value.email,
        experienceRequired:jobForm.value.experience_required,
        contactNo: jobForm.value.contact_no,
        website: jobForm.value.website,
        address: jobForm.value.address,
        description: jobForm.value.description,
        companyDescription: jobForm.value.company_description,
        recruiterId:this.recruiterId,
        postDate:this.jobinfo.postDate,
        college:this.jobinfo.college,
        branch:this.jobinfo.branch,
        year:this.jobinfo.year,
        sem:this.jobinfo.sem,
      }
      this.jobListService.saveEditedJobTpo(jobFormData).subscribe(
        res => {
          if (res) {
            Swal.fire('Success', 'Changes saved', 'success');
            this.editbtn = "Saved";
          } else {
            Swal.fire('Error', 'Some error occurred', 'error');
          }
        },
        err => {
          Swal.fire('Error', 'Some error occurred', 'error');
        }
      );
    } else if (jobForm.valid && this.userRole == "RECRUITER") {
      const jobFormData = {
        id: this.jobId,
        jobTitle: jobForm.value.job_title,
        jobCategory: jobForm.value.job_category,
        jobLocation: jobForm.value.job_location,
        lastDate: jobForm.value.last_date,
        educationQualification: jobForm.value.education_qualification,
        vacancies: jobForm.value.vacancies,
        salaryRange1: jobForm.value.salary_range1,
        salaryRange2: jobForm.value.salary_range2,
        email: jobForm.value.email,
        experienceRequired:jobForm.value.experience_required,
        contactNo: jobForm.value.contact_no,
        website: jobForm.value.website,
        address: jobForm.value.address,
        description: jobForm.value.description,
        companyDescription: jobForm.value.company_description,
        recruiterId:this.recruiterId,
        postDate:this.jobinfo.postDate
      }
      this.jobListService.saveEditedJob(jobFormData).subscribe(
        res => {
          if (res) {
            Swal.fire('Success', 'Changes saved', 'success');
            this.editbtn = "Saved";
          } else {
            Swal.fire('Error', 'Some error occurred', 'error');
          }
        },
        err => {
          Swal.fire('Error', 'Some error occurred', 'error');
        }
      );
    }
     else {
      Swal.fire(
        'Opps !',
        'Please fill all form fields',
        'error'
      );
    }
  }

}
