<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>


<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="container emp-profile">
<div class="container bootstrap snippets bootdey">
<div class="row">
  <div class="profile-pnav col-md-3">
      <div class="panel">
          <div class="user-heading round">
              <a> <img [src]="profile_picture" alt=""> </a>
              <h1 class="text-white" style="font-weight: bold;">{{recruiterInfo?.firstName}} &nbsp; {{recruiterInfo?.lastName}}</h1>
              <p>{{recruiterInfo?.email}}</p>
          </div>

          <ul class="pnav pnav-pills pnav-stacked">
              <li class="active"><a > <i class="fa fa-user"></i> Profile</a></li>
              <li><a routerLink="/posted-jobs"> <i class="fa fa-calendar"></i> Posted Jobs</a></li>
              <!-- <span class="label label-warning pull-right r-activity">9</span> -->
              <li><a routerLink="/recruiter-dashboard"> <i class="fa fa-edit"></i> Edit profile</a></li>
          </ul>
      </div>
  </div>
  <div class="profile-info col-md-9">
      <!-- <div class="panel">
          <form>
              <textarea placeholder="Whats in your mind today?" rows="2" class="form-control input-lg p-text-area"></textarea>
          </form>
          <footer class="panel-footer">
              <button class="btn btn-warning pull-right">Post</button>
              <ul class="pnav pnav-pills">
                  <li>
                      <a href="#"><i class="fa fa-map-marker"></i></a>
                  </li>
                  <li>
                      <a href="#"><i class="fa fa-camera"></i></a>
                  </li>
                  <li>
                      <a href="#"><i class=" fa fa-film"></i></a>
                  </li>
                  <li>
                      <a href="#"><i class="fa fa-microphone"></i></a>
                  </li>
              </ul>
          </footer> 
      </div> -->

      <!-- {{recruiterInfo | json }} -->
      <div class="panel">
          <div class="panel-body bio-graph-info">
              <!-- <h1>Bio</h1> -->
              <div class="row">

                  <div class="bio-row">
                      <p ><span>Name </span>:&nbsp;{{recruiterInfo?.firstName}}</p>
                  </div>
                  <div class="bio-row">
                      <p><span>Surname </span>:&nbsp;{{recruiterInfo?.lastName}}</p>
                  </div>
                  <!-- <div class="bio-row">
                      <p><span>College</span>: &nbsp;{{recruiterInfo.college_comp_name}}</p>
                  </div>
                  <div class="bio-row">
                      <p><span>Location </span>:&nbsp; {{recruiterInfo.address}}</p>
                  </div> -->
                  <div class="bio-row">
                      <p><span>Email </span>:&nbsp;{{recruiterInfo?.email}}</p>
                  </div>
                
                  <div class="bio-row">
                      <p><span>Phone </span>:&nbsp;{{contact}}</p>
                  </div>
              </div>
          </div>
          
      </div>
      <div class="panel" style="margin-top: 10px;">
        <!-- <div class="panel-body bio-graph-info">
            <h1>About Company</h1>
            <div class="row">
                <div class="bio-row">
                    <p><span>Name </span>:{{recruiterInfo.company.company_name}}</p>
                </div>
                <div class="bio-row">
                    <p><span>Email </span>:{{recruiterInfo.company.company_email}}</p>
                </div>
                <div class="bio-row">
                    <p><span>City</span>:{{recruiterInfo.company.company_city}}</p>
                </div>
                <div class="bio-row">
                    <p><span>Size </span>:{{recruiterInfo.company.company_size}}</p>
                </div>
                <div class="bio-row">
                    <p><span>Address </span>:{{recruiterInfo.company.company_address}}</p>
                </div>
               
            </div>
        </div> -->
        
      </div>
      <div class="panel">
        <div class="panel-body bio-graph-info">
          <div class="row">
              <div class="col-md-6">
                  <div class="panel">
                      <div class="panel-body">
                          <div class="bio-chart">
                              <div style="display:inline;width:100px;height:100px;"><canvas width="100" height="100px"></canvas><input class="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" value="{{dashboard?.job_post_count}}" data-fgcolor="#e06b7d" data-bgcolor="#e8e8e8" style="width: 54px; height: 33px; position: absolute; vertical-align: middle; margin-top: 33px; margin-left: -77px; border: 0px; font-weight: bold; font-style: normal; font-variant: normal; font-stretch: normal; font-size: 20px; line-height: normal; font-family: Arial; text-align: center; color: rgb(150, 190, 75); padding: 0px; appearance: none; background: none;"></div>
                          </div>
                          <div class="bio-desk">
                              <h4 class="green">Posted jobs</h4>
                              <p>from : 15 july</p>
                              <p>to : 15 August</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="panel">
                      <div class="panel-body">
                          <div class="bio-chart">
                              <div style="display:inline;width:100px;height:100px;"><canvas width="100" height="100px"></canvas><input class="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" value="{{dashboard?.job_view_count}}" data-fgcolor="#4CC5CD" data-bgcolor="#e8e8e8" style="width: 54px; height: 33px; position: absolute; vertical-align: middle; margin-top: 33px; margin-left: -77px; border: 0px; font-weight: bold; font-style: normal; font-variant: normal; font-stretch: normal; font-size: 20px; line-height: normal; font-family: Arial; text-align: center; color: rgb(150, 190, 75); padding: 0px;appearance: none; background: none;"></div>
                          </div>
                          <div class="bio-desk">
                              <h4 class="green">Viewed</h4>
                              <p>from : 15 July</p>
                              <p>to : 15 August</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="panel">
                      <div class="panel-body">
                          <div class="bio-chart">
                              <div style="display:inline;width:100px;height:100px;"><canvas width="100" height="100px"></canvas><input class="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" value="{{dashboard?.selected_count}}" data-fgcolor="#96be4b" data-bgcolor="#e8e8e8" style="width: 54px; height: 33px; position: absolute; vertical-align: middle; margin-top: 33px; margin-left: -77px; border: 0px; font-weight: bold; font-style: normal; font-variant: normal; font-stretch: normal; font-size: 20px; line-height: normal; font-family: Arial; text-align: center; color: rgb(150, 190, 75); padding: 0px; appearance: none; background: none;"></div>
                          </div>
                          <div class="bio-desk">
                              <h4 class="green">Selected</h4>
                              <p>from : 15 July</p>
                              <p>to : 15 August</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="panel">
                      <div class="panel-body">
                          <div class="bio-chart">
                              <div style="display:inline;width:100px;height:100px;"><canvas width="100" height="100px"></canvas><input class="knob" data-width="100" data-height="100" data-displayprevious="true" data-thickness=".2" value="{{dashboard?.rejected_count}}" data-fgcolor="#cba4db" data-bgcolor="#e8e8e8" style="width: 54px; height: 33px; position: absolute; vertical-align: middle; margin-top: 33px; margin-left: -77px; border: 0px; font-weight: bold; font-style: normal; font-variant: normal; font-stretch: normal; font-size: 20px; line-height: normal; font-family: Arial; text-align: center; color: rgb(150, 190, 75); padding: 0px; appearance: none; background: none;"></div>
                          </div>
                          <div class="bio-desk">
                              <h4 class="green">Rejected</h4>
                              <p>from : 15 July</p>
                              <p>to : 15 August</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
</div>
</div>
<br>
<br>
<br>


<!------ Include the above in your HEAD tag ---------->

<!-- <div class="container emp-profile">
            <form method="post">
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile-img">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog" alt=""/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="profile-head">
                                    <h5>
                                        {{recruiterInfo.f_name}} &nbsp; {{recruiterInfo.l_name}}
                                    </h5>
                                    <h6>
                                        Web Developer and Designer
                                    </h6>
                                    <p class="proile-rating">RANKINGS : <span>8/10</span></p>
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Company</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <input type="submit" class="profile-edit-btn" name="btnAddMore" value="Edit Profile"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="profile-work">
                            <p>company Links</p>
                            <a href="{{recruiterInfo.company.company_website}}">Website Link</a><br/>
                            <a href="">Bootsnipp </a><br/>
                            <a href="">Bootply Profile</a>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="tab-content profile-tab" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>User Id</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>Kshiti123</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Name</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.name}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.email}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Mobile No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.mobile_no}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Profession</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>Web Developer and Designer</p>
                                            </div>
                                        </div>

                                        <h3>About Company</h3>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Name </label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.company.company_name}}</p>
                                            </div>
                                        </div>                                        
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Email</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.company.company_email}}</p>
                                            </div>
                                        </div>
                                      <div class="row">
                                            <div class="col-md-6">
                                                <label>City</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.company.company_city}}</p>
                                            </div>
                                        </div>                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Address</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{recruiterInfo.company.company_address}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
            </form>           
        </div> -->