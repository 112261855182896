<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="d-none d-md-block col-md-2" style="margin-top: 100px;">
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="portal-area pt-100 pb-70 col-md-8 col-sm-12">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="portal-item">
                            <div class="row">
                                <div class="col-lg-7">
                                    <img src="assets/img/home-1/10.png" alt="Portal">
                                </div>

                                <div class="col-lg-5">
                                    <img src="assets/img/home-1/11.png" alt="Portal">
                                </div>
                            </div>

                            <div class="portal-trusted">
                                <span>100% Trusted</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="portal-item portal-right portal-right-two">
                            <h2>Trusted & Popular Job Portal</h2>
                            <p>Our mission is to support College Training and Placement Officers to build bridge between
                                education and employment by facilitating the growth and development of our students,
                                empowering them with the skills, knowledge, and opportunities necessary to excel in
                                their careers. We are committed to fostering meaningful partnerships with both students
                                and employers, creating a dynamic ecosystem where talent meets opportunity. Our primary
                                goal is to ensure that every student not only receives a quality education but also
                                gains practical experience and access to a wide range of career pathways. Through
                                continuous innovation, mentorship, and industry collaboration.</p>

                            <div class="portal-counter-area">
                                <div class="row">
                                    <div class="col-6 col-sm-4 col-lg-4">
                                        <div class="counter-item">
                                            <h3><span class="counter">{{jobPortalStats.jobAvailable}}</span></h3>
                                            <p>Job Available</p>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-4 col-lg-4">
                                        <div class="counter-item">
                                            <h3><span class="counter">{{jobPortalStats.submittedCVs}}</span></h3>
                                            <p>Submitted CV</p>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-4 col-lg-4">
                                        <div class="counter-item">
                                            <h3><span class="counter">{{jobPortalStats.companies}}</span></h3>
                                            <p>Company</p>
                                        </div>
                                    </div>

                                    <div class="col-6 col-sm-6 col-lg-4 offset-lg-2">
                                        <div class="counter-item">
                                            <h3><span class="counter">{{jobPortalStats.registeredUsers}}</span></h3>
                                            <p>Registered Users</p>
                                        </div>
                                    </div>

                                    <!-- <div class="col-6 col-sm-6 col-lg-4">
                                        <div class="counter-item">
                                            <h3><span class="counter">55</span>K+</h3>
                                            <p>Appointed to Job</p>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-md-block col-md-2" style="margin-top: 100px;">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense></div>
            
        </div>
    </div>
</div>

        <div class="counter-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-6 col-sm-3 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-employee"></i>
                            <h3><span class="counter">{{jobPortalStats.jobAvailable}}</span></h3>
                            <p>Job Available</p>
                        </div>
                    </div>

                    <div class="col-6 col-sm-3 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-curriculum"></i>
                            <h3><span class="counter">{{jobPortalStats.submittedCVs}}</span></h3>
                            <p>CV Submitted</p>
                        </div>
                    </div>

                    <div class="col-6 col-sm-3 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-enterprise"></i>
                            <h3><span class="counter">{{jobPortalStats.companies}}</span></h3>
                            <p>Companies</p>
                        </div>
                    </div>

                    <div class="col-6 col-sm-3 col-lg-3">
                        <div class="counter-item">
                            <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                            <h3><span class="counter">{{jobPortalStats.registeredUsers}}</span></h3>
                            <p>Registered Users</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popular-area pt-100 pb-170">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="popular-item">
                            <div class="row align-items-center">
                                <div class="col-lg-7">
                                    <img src="assets/img/home-1/8.png" alt="Popular">
                                </div>

                                <div class="col-lg-5">
                                    <div class="practice-inner">
                                        <img src="assets/img/home-1/3.png" alt="Popular">
                                        <img src="assets/img/home-1/9.png" alt="Popular">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="popular-item popular-right">
                            <div class="section-title text-start">
                                <h2>Why We are Most Popular</h2>
                            </div>
                            <p>we aim to empower our students to become future-ready professionals, while also providing
                                our partner organizations with the talent they need to thrive in a competitive global
                                landscape. Our unwavering dedication to excellence and integrity drives us to serve as
                                catalysts for the professional success of our students and the continued growth of our
                                institution.</p>

                            <div class="row popular-wrap">
                                <div class="col-sm-6 col-lg-6">
                                    <ul>
                                        <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                                        <li><i class="flaticon-no-money"></i> No Extra Charge</li>
                                    </ul>
                                </div>

                                <div class="col-sm-6 col-lg-6">
                                    <ul>
                                        <li><i class="flaticon-enterprise"></i> Top Companies</li>
                                        <li><i class="flaticon-employee"></i> International Job</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
