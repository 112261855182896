import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobListService } from '../../services/job-list/job-list.service';
import { PopupService } from '../../services/other/popup.service';
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service';
import { AppliedJobService } from '../../services/appliedJobs/applied-job.service';
import { AccountService } from '../../services/acccount/account.service';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-application-statistics',
  templateUrl: './application-statistics.component.html',
  styleUrls: ['./application-statistics.component.scss'],
})
export class ApplicationStatisticsComponent implements OnInit {
  selectedOption
  jobPostid
  // @Input() item:any
  page = 1;
  pageSize = 2;
  collectionSize: number
  job_title
  item: any
  id: any
  dataCSV: any
  recruiterID: any
  status = {
    1: '',
    2: "Viewed",
    3: "Selected",
    4: "Rejected",
    5: "Pending"
  }
  recruiterStatus: string = "1";
  constructor(private http: HttpClient, private route: ActivatedRoute,
    private jobListService: JobListService,
    private appliedJobService: AppliedJobService,
    private popup: PopupService,
    private accountService:AccountService,
    private candidateServices:CandidateServicesService,
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['job_post_id'];
    });

    this.accountService.getUserId().subscribe(
      (response) => {
        this.recruiterID = response.body;
        this.refreshJobs(this.id, this.recruiterID);
      },
      (error) => {
        console.error('Error:', error);
      });
  }

  refreshJobs(jobId, recruiterID) {
    this.jobListService.applicationStaticsGet(jobId, recruiterID)
      .subscribe(
        result => {
          // this.item = result.body;
          this.jobPostid = result.body.job_post.id;
          // this.item = Object.values(this.item);
          this.item = result.body.all_applicants;
          // this.item[0].selected = true;
          this.collectionSize = result.body.applied_count;
          this.item = this.item.map((value, i) => ({ index: i + 1, ...value })).slice(
            (this.page - 1) * this.pageSize,
            (this.page - 1) * this.pageSize + this.pageSize,
          );
          this.job_title = result.body.job_post.jobTitle;
        },
        err => {
          this.popup.Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        }
      );
  }

  statusChanged(recruiterStatus, recruiterID, jobApplyId) {
    // console.log('This the data from the statusChnaged', recruiterStatus, recruiterID,jobApplyId);
    this.appliedJobService.appliedJobInfoPatch(recruiterStatus, recruiterID, jobApplyId).subscribe(
      res => { if (res) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });

        Toast.fire({
          icon: 'success',
          title: 'Updated Status'
        });
      }},error => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1100,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });

        Toast.fire({
          icon: 'error',
          title: 'Status not updated'
        });
      }
    )
  }

  UpdateMyJobStatus(id, status) {
    console.log("Status", id, "<-ID", status)
    this.jobListService.UpdateJobStatus(id, status.split(":")[0].trim()).subscribe(res => {

      this.popup.Swal.fire({
        icon: 'success',
        title: 'Success !',
        text: 'Status Upadated Successfully!',
      })
    }, err => {
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    })

  }
  
  downloadAsCSV(job_title: string): void {
    try {
      let element = document.getElementById("Excel-Table");
  
      if (!element) {
        console.error("Table element not found");
        return;
      }
  
      const filteredColumns = [1, 2, 3];
      let ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, {raw: true});
  
      const data = XLSX.utils.sheet_to_json(ws, {header: 1});

      const filteredData = data.map(row => filteredColumns.map(colIndex => row[colIndex]));
      const newWs: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(filteredData);
  
      let wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, newWs, 'Sheet1');
  
      XLSX.writeFile(wb, job_title + 'Applicants.xlsx');
  
      Swal.fire(
        'File Download Started',
        '',
        'success'
      );
    } catch (error) {
      console.error("Error in downloadAsCSV:", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  }
  


  

  downloadResume(filePath,firstName,lastName) {
    this.candidateServices.downloadResume(filePath).subscribe(result => {
      function downloadBlob(blob, name = 'download.pdf') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        document.body.removeChild(link);
      }
      const regex = /\.(.*?)$/;
      const matches = filePath.match(regex);
      let fileEx;
      if (matches) {
        fileEx = matches[0];
      }
      let fileName = "applicats_resume" + fileEx;
      if(firstName != null || lastName != null){
        fileName = firstName + "_" + lastName + fileEx;
      }
      downloadBlob(result,  fileName);
    }, err => {
      console.log(err);
  
    })
  }

  viewCandidateProfile(userName){
    this.candidateServices.usernameSubject.next(userName);
  }
}
