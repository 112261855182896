<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Favourite Job</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Favourite Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>











<section class="job-area job-area-two pt-100">
    <div class="container">
        <div class="row"  >
            <div class="col-lg-9 mx-auto" *ngFor="let item of myAppliedJobs | slice: (page-1) * pageSize : page * pageSize">
                <div class="job-item">
                    <img src="{{item.job.company.company_logo_link}}" alt="Job">
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <div style="display: flex; align-items: center;">
                                <h3 routerLink="/job-details/{{item.job.id}}"   class="anchor-link">  {{item.job.job_title}} </h3> 
                                <!-- <span class="p-2"> </span> -->
                                
                            </div>
                            <a routerLink="/company-details"></a>
                            <ul>
                                <li><i class="icofont-web"></i> {{item.job.company.company_website | slice:0:30}}...</li>
                                <li><i class="icofont-money-bag"></i> {{item.job.salary_range_1}} - {{item.job.salary_range_2}} (LPA)</li>
                                <li><i class="icofont-location-pin"></i> {{item.job.job_location}}</li>

                            </ul>
                        </div>

                        <div class="job-inner-right">
                                <div style="font-size: 15px; font-weight: 300; color: blue;">  {{item.applied_at | appliedHours}}</div>
                                <span>Application Status : 
                                <span style="font-size: 15px; color: brown;"> {{ status[item.status]}}</span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display:flex; justify-content: center; ">

                <ngb-pagination  [collectionSize]="itemLength"  [(page)]="page" aria-label="Default pagination bg-warning"></ngb-pagination>
            </div>
           
        </div>
    </div>
</section>
