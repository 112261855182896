import { Component, OnInit } from '@angular/core';
import { JobListService } from '../../services/job-list/job-list.service';
import { PopupService } from '../../services/other/popup.service';
import { HttpClient } from '@angular/common/http';
import { env } from '../../../../environments/env'
import { Router } from '@angular/router';
import { AccountService } from '../../services/acccount/account.service';

@Component({
  selector: 'app-posted-jobs',
  templateUrl: './posted-jobs.component.html',
  styleUrls: ['./posted-jobs.component.scss']
})
export class PostedJobsComponent implements OnInit{

  postedJobs
  
  recruiterID:any;
  itemLength=0
  page=1
  pageSize=10
  jobCategorey={
    "1":"Full Time",
    "2":"Part Time",
    "3":"Internship",
}


  constructor(private jobListService: JobListService,
   private accountService:AccountService,
   private popup: PopupService,
   private http: HttpClient,
   private router: Router ) { }

  ngOnInit(): void {
    this.accountService.getUserId().subscribe(
      (response) => {
        this.recruiterID = response.body;
        this.myPostedJobs()
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }


  myPostedJobs(){    
    this.jobListService.myPostedJobs(this.recruiterID)
    .subscribe(resp => {
      this.postedJobs = <any[]>resp.body;
      this.itemLength= this.postedJobs.count; 
      this.postedJobs = this.postedJobs.job_post
    },err => {
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })

    })
  }

  deleteJobCall(jobId){
    this.jobListService.deleteJob(jobId, this.recruiterID)
    .subscribe(resp => {
      if(resp.status == 200){
        console.log(resp)
        this.popup.Swal.fire(
          'Deleted!',
          'The job has been deleted.',
          'success'
        );
        this.ngOnInit();
      }else{
        this.popup.Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong',
        });
      }
     
      
    }, err => {
      console.log(err)
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while deleing job!',
      });
    });
  }

  deleteJob(jobId) {
    this.popup.Swal.fire({
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete this job?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteJobCall(jobId)
      }
    });
  }
  

}
