<section class="content">
    <div class="exam-creation-area">
        <div class="page-title">
            <span>Aptitude Question</span>
        </div>
        <div class="conatiner-fluid row m-0">
            <div class="col-md-2">
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
            </div>
            <div class="container col-md-8 col-sm-12 pb-5">
                <div class="row">
                    <div *ngIf="questionList?.length>0;else elseBlock">
                        <h4 class="text-center">Question list for exam-code: {{selectedExam}}</h4>
                        <div class="col-12"
                            *ngFor="let question of questionList | slice: (page1-1) * pageSize1 : page1 * pageSize1;index as i">
                            <div class="card card-statistic-4 my-2">
                                <div class="align-item-center justfy-content-between">
                                    <div class="card-content p-2">
                                        <div class="card-body position-relative py-4 px-2">
                                            <span class="card-title text-break fs-5">Q{{i+1}}) {{question?.question}}</span>
                                            <p class="card-text my-1 fs-6 text-muted">1) {{question?.options[0]}}</p>
                                            <p class="card-text my-1 fs-6 text-muted">2) {{question?.options[1]}}</p>
                                            <p class="card-text my-1 fs-6 text-muted">3) {{question?.options[2]}}</p>
                                            <p class="card-text my-1 fs-6 text-muted">4) {{question?.options[3]}}</p>
                                            <span class="card-subtitle fs-6">Ans: {{question?.correctanswer}}</span>
                                            <span class="badge bg-success position-absolute top-0 end-0">Marks: {{question?.mark}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex; justify-content: center; margin:1rem 0;">
                            <ngb-pagination [collectionSize]="questionList?.length" [(page)]="page1"
                                [pageSize]="pageSize1" aria-label="Default pagination bg-warning"></ngb-pagination>
                        </div>
                    </div>
                    <ng-template #elseBlock>
                        <div class="text-center my-5 ">
                            <div class="my-5">
                               <strong> No Record Found for {{selectedExam}}</strong>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="col-md-2">
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
            </div>
        </div>
    </div>
</section>