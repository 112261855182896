import { Component, OnInit } from '@angular/core';
import { ContactUsService } from '../../services/other/contact-us.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  jobPortalStats:any;
  constructor(private contactUsservice: ContactUsService) { }

  ngOnInit(): void {
    this.contactUsservice.getJobPortalStats().subscribe((resp:any) => {this.jobPortalStats = resp.body});
  }
}
