import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service';
import { PopupService } from '../../services/other/popup.service';
import { HttpClient } from '@angular/common/http';
// import { saveAs } from 'file-saver';
import { AccountService } from '../../services/acccount/account.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as html2pdf from 'html2pdf.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss']
})
export class CandidateDetailsComponent implements OnInit {
  @ViewChild('copyOnlineCvModal') modal: any;

  candidateInfo:any;
  id:any;
  resData:any;
  skillList:any;
  skills_array:any;
  profilePhoto:any;
  userName:string;
  userRole:string;
  copy=false;
  progress=0;
  url:string;
  constructor( 
    private route: ActivatedRoute, 
    private candidateServices:CandidateServicesService, 
    private http: HttpClient,
    private popup: PopupService,
    private accountService : AccountService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
    )
    {
      this.id = this.route.snapshot.paramMap.get('id');
      this.url = window.location.origin;
   }
  openModal() {
    this.modalService.open(this.modal, { size: 'md' });
  }
  copyToClipboard(): void {
    const cvLinkInput = document.getElementById('cvLinkInput') as HTMLInputElement;
    cvLinkInput.select();
    document.execCommand('copy');
    this.copy=true;
    const interval = setInterval(() => {
      this.progress += 5;

      if (this.progress >= 100) {
        clearInterval(interval);
        this.copy=true
        setTimeout(() => {
          this.copy = false;
          this.progress = 0;
          this.modalService.dismissAll();
        }, 500);
      }
    }, 100);
  }

   openResume(resume_link) {
    const resumeUrl = resume_link;

    // this.http.get(resumeUrl, { responseType: 'blob' })
    //   .subscribe(response => {
    //     const file = new Blob([response], { type: 'application/pdf' });
    //     const fileURL = URL.createObjectURL(file);
    //     window.open(fileURL);
    //   });
  }
  ngOnInit(): void {
    // this.getCandidateInfo(this.id)
    // this.showSkillData();
    this.userRole = this.accountService.getRole()
    if(this.accountService.getRole() == "JOBSEEKER"){
        this.userName = this.accountService.getUsername()
    }
    if(this.accountService.getRole() == "STUDENT_DEFAULT"){
      this.userName = this.accountService.getUsername()
  }
    if(this.accountService.getRole() == "RECRUITER" || this.accountService.getRole() == "TPO" ){
      this.candidateServices.userName$.subscribe(userName=>
        this.userName = userName);
    }
    this.candidateServices.candidateListGetById(this.userName).subscribe((response) => {
      this.candidateInfo = response.body;
      if(this.candidateInfo.gender === 'MALE'){
        this.profilePhoto = 'assets/img/male_profile.png';
      }else if(this.candidateInfo.gender === 'FEMALE'){
        this.profilePhoto = 'assets/img/female_profile.png';
      }else{
        this.profilePhoto = 'assets/img/neutral_profile.jpg'
      }
      if(this.candidateInfo.profilePicBlob  != null || this.candidateInfo.profilePicBlob != ''){
        this.downloadProfilePic(this.candidateInfo.profilePicBlob );
      }
    },err => {
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });

  }

  downloadProfilePic(filePath: string) {
    this.candidateServices.downloadProfilePic(filePath).subscribe((blob: Blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.profilePhoto = this.sanitizer.bypassSecurityTrustUrl(objectURL) as SafeUrl;
    });
  }

viewResume(url): any {
  this.candidateServices.downloadResume(url).subscribe(res => {
    const fileURL = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
    window.open(fileURL, '_blank');
  });
}

downloadResume(filePath,firstName,lastName) {
  this.candidateServices.downloadResume(filePath).subscribe(result => {
    function downloadBlob(blob, name = 'download.pdf') {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = name;
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    }
    const regex = /\.(.*?)$/;
    const matches = filePath.match(regex);
    let fileEx;
    if (matches) {
      fileEx = matches[0];
    }
    let fileName = "applicats_resume" + fileEx;
    if(firstName != null || lastName != null){
      fileName = firstName + "_" + lastName + fileEx;
    }
    downloadBlob(result,  fileName);
  }, err => {
    console.log(err);

  })
}


showSkillData(){
  this.candidateServices.candidateSkillsListGet().subscribe(
    res=>{ 
       this.resData = Object.keys(res).map(e=>res[e]);
      this.skills_array= this.resData.filter(skill =>  this.skillList.includes(Number(skill.id)));
      
      }
  ),err=>{}
}
// saveProfileAsPDF(fname:string,lname:string) {
//   let element = document.getElementById('candidateProfileContainer'); // replace 'profile' with the id of your element
//   let options = {
//     margin:       1,
//     filename:     fname + "_" + lname+ '_profile.pdf',
//     image:        { type: 'jpeg', quality: 0.98 },
//     html2canvas:  { scale: 2 },
//     jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
//   };

//   html2pdf().from(element).set(options).save();
// }


}
