import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobListService } from '../../services/job-list/job-list.service';
import Swal from 'sweetalert2';
import { contactNumberValidator } from './post-a-job-validator';
import { AccountService } from '../../services/acccount/account.service';



@Component({
  selector: 'app-post-a-job',
  templateUrl: './post-a-job.component.html',
  styleUrls: ['./post-a-job.component.scss']
})
export class PostAJobComponent implements OnInit {
  [x: string]: any;

  jobForm: FormGroup;
  submitted = false;
  domain = ["Backend", "Frontend", "UI/UX", "SEO"]
  selectedDomain
  myDate = Date.now()
  jobFormObj;
  userRole :string;
  userName:string;
  userId:string;
  collegeCode:string;
  hiddenFormField:boolean = true;
  collegeList: any[];
  branches: any[] ;
  years: any[];
  semesters = [];

  constructor(
    private jobListService: JobListService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private router:Router,
  ) { }
  textInput: string = '';
  remainingCharacters: number = 500;

  onInput() {
    this.remainingCharacters = 500 - this.textInput.length;
  }
  textInput1: string = '';
  remainingCharacters1: number = 500;

  onInput1() {
    this.remainingCharacters1 = 500 - this.textInput1.length;
  }
  ngOnInit(): void {
    this.userName = this.accountService.getUsername();
    this.userRole = this.accountService.getRole();
    this.collegeCode = this.accountService.get('branch');
    if (this.userRole == "RECRUITER"){
      this.jobForm = this.formBuilder.group({
        jobTitle: ['', [Validators.required]],
        website: ['', [Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)]],
        vacancies: ['', [Validators.required]],
        salaryRange1: ['', [Validators.required]],
        salaryRange2: ['', [Validators.required]],
        lastDate: ['', [Validators.required]],
        experienceRequired: ['', [Validators.required]],
        jobLocation: ['', [Validators.required]],
        educationQualification: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        address: ['', [Validators.required]],
        contactNo: ['', [Validators.required, contactNumberValidator()]],
        companyDescription: ['', [Validators.required]],
        jobCategory: ['', [Validators.required]],
        companyName: ['', [Validators.required]],
        description: ['', [Validators.required]]
      });
    }else if(this.userRole == "TPO"){
      this.jobForm = this.formBuilder.group({
        jobTitle: ['', [Validators.required]],
        website: ['', [Validators.pattern(/^(http|https):\/\/[^\s/$.?#].[^\s]*$/)]],
        vacancies: ['', [Validators.required]],
        salaryRange1: [null],
        salaryRange2: [null],
        lastDate: ['', [Validators.required]],
        experienceRequired: ['', [Validators.required]],
        jobLocation: ['', [Validators.required]],
        educationQualification: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        address: ['', [Validators.required]],
        contactNo: ['', [Validators.required, contactNumberValidator()]],
        companyDescription: ['', [Validators.required]],
        jobCategory: ['', [Validators.required]],
        companyName: ['', [Validators.required]],
        description: ['', [Validators.required]],
        college: [null, [Validators.required]],
        branch: [null],
        year: [null],
        sem: [null]
      });

      this.accountService.getUserId().subscribe(
        (response) => {
          this.userId = response.body;
        },
        (error) => {
          console.error('Error:', error);
        });
    }

  }

  // This is for the college TPO
  onChange(value){
    if(value == 'Branch'){
      this.hiddenFormField = false;

      this.jobForm.patchValue({
        college:this.collegeCode
      })
      
      this.jobListService.getCollegeHierarchy().subscribe(x=>{
        this.collegeList=x.body;
        this.branches=this.collegeList.filter(x=>x.collegeCode===this.collegeCode)[0].courses;
      });

    }else if(value == 'Outsiders'){
      this.jobForm.get('college').clearValidators();
      this.jobForm.get('college').updateValueAndValidity();
    }
    else{
      this.hiddenFormField = true;
      this.jobForm.patchValue({
        college:this.collegeCode
      })
    }
  }

  onBranchSelect() {
    let selBranch = this.jobForm.value.branch;
    this.years=this.branches.filter(x=>x.collegeCourseCode===selBranch)[0].academicYears;
    this.semesters=[];
  }

  onYearSelect() {
    let selectedYear = this.jobForm.value.year;
    this.semesters = this.years.filter(x=>x.academicYearCode===selectedYear)[0].semesters;
  }

  onSemSelect(){
    let selectedSem = this.jobForm.value.sem;
    this.divisions = this.semesters.filter(x=>x.semesterCode===selectedSem)[0].divisions;

  }
  //  Methods for tpo ends 

  onSubmit(jobForm) {
    this.submitted = true;
    this.jobFormObj = jobForm.value
    let start_salary = jobForm.value['salaryRange1'];
    let end_salary = jobForm.value['salaryRange2'];
    if ((start_salary == '' || end_salary == '' || start_salary >= end_salary) && this.userRole == "RECRUITER") {
      if (start_salary == '' || end_salary == '') {
        Swal.fire(
          'Error !',
          'Please fill the Salary',
          'error'
        );
      } else {
        Swal.fire(
          'Error !',
          'To salary-1 should be greater than from salary-2',
          'question'
        );
      }

    } else if (jobForm.valid) {
      if(this.userRole == 'TPO'){
        this.jobListService.jobPostTpo(this.jobFormObj, this.userId).subscribe(
          res => {
            if (res) {
              Swal.fire('Success', 'You have successfully posted a job', 'success');
              this.router.navigate(['posted-jobs']);
              this.jobForm.reset();
            } else {

              Swal.fire('Error', 'Some error occurred', 'error');
            }
          },
          err => {
            Swal.fire('Error', 'Some error occurred', 'error');
          }
        );
      }else{
        this.jobListService.jobPost(this.jobFormObj, this.userName).subscribe(
          res => {

            if (res) {
              Swal.fire('Success', 'You have successfully posted a job', 'success');
              this.router.navigate(['posted-jobs']);
              this.jobForm.reset();
            } else {

              Swal.fire('Error', 'Some error occurred', 'error');
            }
          },
          err => {
            Swal.fire('Error', 'Some error occurred', 'error');
          }
        );
      }
    } else {
      Swal.fire(
        'Opps !',
        'Please fill all form fields',
        'error'
      );
    }
  }


}
