import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactUsService } from '../../services/other/contact-us.service';
import { PopupService } from '../../services/other/popup.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  Toast: any;
  constructor(
    private contactUsService: ContactUsService,
  ) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required])
    });
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });
    this.contactUsService.emailId$.subscribe(
      (email:any)=> {this.contactForm.patchValue({
        email:email,
      })},
      (error:any) => {console.log(error);})
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      this.Toast.fire({
        icon: 'error',
        title: 'Please fill all fields'
      });
      return;
    } else {
      let data = {
        name: this.contactForm.value.name,
        email: this.contactForm.value.email,
        message: this.contactForm.value.message
      }
      this.contactUsService.sendContactUs(data)
        .subscribe(
          (resp) => {
            if (resp.status === 200) {
              this.Toast.fire({
                icon: 'success',
                title: 'Thanks for contacting us, we will reach to you soon!'
              });
              this.contactForm.reset();
            } else {
              this.Toast.fire({
                icon: 'error',
                title: 'Unable to proceed your request!'
              });
            }
          },
          (error) => {
            this.Toast.fire({
              icon: 'error',
              title: 'Unable to proceed your request!'
            });
          });
    }
  }
}
