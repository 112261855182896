<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate Dashboard</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dashboard-area pt-100 row m-0">
    <div class="d-none d-md-block col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
    </div>
    <div class="container col-md-8">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="dashboard-img">
                    <div class="pic-holder">
                        <img class="pic" [src]="profilePhoto" alt="candidate-image" />
                        <input class="uploadProfileInput" type="file" accept="image/jpeg,image/jpg,image/png"
                            (change)="onProfileSelect($event)" #hiddenfileinput1 name="profile_pic"
                            id="hiddenfileinput1" style="opacity: 0;" />
                        <label for="hiddenfileinput1" class="upload-file-block">
                            <div class="text-center">
                                <div class="mb-2">
                                    <i class="icofont-camera"></i>
                                </div>
                                <div class="text-uppercase">
                                    Update <br /> Profile Photo
                                </div>
                            </div>
                        </label>
                    </div>
                    <h3>{{ candidateInfo.first_name }}</h3>
                    <p>{{ candidateInfo.profile }}</p>
                    <!-- <p>
                        <input style="display: none" type="file" (change)="onResumeSelect($event)"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            #hiddenfileinput2 />
                        Resume :
                        <a href="{{ candidateInfo.resume_link}}" target="_blank">{{
                            candidateInfo.resume_name | slice:0:30
                            }}</a>
                    </p> -->
                    <!-- commeted because no use -by Arpit singh -->
                </div>
            </div>


            <div class="col-lg-8">
                <div class="dashboard-nav">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" id="pills-home-tab" data-toggle="pill" c routerLink="/candidate-details"
                                role="tab" aria-controls="pills-home" aria-selected="false">My Profile</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill"
                                (click)="hiddenfileinput2.click()" role="tab" aria-controls="pills-profile"
                                aria-selected="false">Update Resume</a>
                            <input style="display: none" type="file" (change)="onResumeSelect($event)"
                                accept="application/pdf"
                                #hiddenfileinput2 />
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" routerLink="/applied-job"
                                role="tab" aria-controls="pills-contact" aria-selected="false">Applied Jobs</a>
                        </li>

                        <li class="nav-item">
                            <!-- this link is to view the resume -->
                            <!-- <a class="nav-link" id="pills-logout-tab" data-toggle="pill" href="{{candidateInfo.resume_link}}" target="_blank" role="tab" aria-controls="pills-logout" aria-selected="false">{{candidateInfo.resumeName}}</a> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="create-information">
                    <form id="ngFormCandidateForm" [formGroup]="candidateForm" (ngSubmit)="onSubmit(candidateForm)">
                        <h3>Basic Information</h3>

                        <div class="create-information-btn row">

                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input type="text" class="form-control"
                                        [ngClass]="{'invalid-field': candidateForm.get('first_name').invalid && candidateForm.get('first_name').touched}"
                                        formControlName="first_name" placeholder="Aikin Ward"
                                        [readonly]="role === 'STUDENT_DEFAULT'" 
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('first_name').invalid && (candidateForm.get('first_name').touched || submitted)) }"/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control"
                                        [ngClass]="{'invalid-field': candidateForm.get('last_name').invalid && candidateForm.get('last_name').touched}"
                                        formControlName="last_name" placeholder="Aikin Ward"
                                        [readonly]="role === 'STUDENT_DEFAULT'" 
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('last_name').invalid && (candidateForm.get('last_name').touched || submitted)) }"/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Your Email</label>
                                    <input type="email" class="form-control"
                                        [ngClass]="{'invalid-field': candidateForm.get('email').invalid && candidateForm.get('email').touched}"
                                        formControlName="email" placeholder="hello@gable.com" [readOnly]="true"
                                        [readonly]="role === 'STUDENT_DEFAULT'"
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('email').invalid && (candidateForm.get('email').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Date of Birth</label>
                                    <input type="date" class="form-control" max="{{ myDate | date : 'yyyy-MM-dd' }}"
                                        formControlName="date_of_birth" placeholder="21 / 2 / 1997"
                                        [readonly]="role === 'STUDENT_DEFAULT'" 
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('date_of_birth').invalid && (candidateForm.get('date_of_birth').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Your Phone</label>
                                    <input type="number"
                                        [ngClass]="{'invalid-field': candidateForm.get('phone').invalid && candidateForm.get('phone').touched}"
                                        class="form-control" formControlName="phone" placeholder="8982166224" 
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('phone').invalid && (candidateForm.get('phone').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Job Title</label>
                                    <input type="text" class="form-control" formControlName="job_title"
                                        placeholder="UX/UI Designer" 
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('job_title').invalid && (candidateForm.get('job_title').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Address:</label>
                                    <input type="text" class="form-control" formControlName="address"
                                        placeholder="210-27 Quadra, Market Street, Victoria Canada" maxlength="50" minlength="1"
                                        [ngClass]="{ 'invalid-field': (candidateForm.get('email').invalid && (candidateForm.get('email').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div class="gender-area">
                                        <span>Gender</span>
                                        <input type="radio" name="gender" id="male" value="MALE"
                                            formControlName="gender" checked />
                                        <label for="male">Male</label>
                                        <input type="radio" name="gender" id="female" formControlName="gender"
                                            value="FEMALE" />
                                        <label for="female">Female</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div style="position: relative;">
                                        <label>Description</label>
                                        <textarea id="your_message" class="form-control" formControlName="description"
                                            [(ngModel)]="descriptionText" (focus)="onInput()" (input)="onInput()" minlength="0" maxlength="750" rows="8"
                                            placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, quaerat aliquid. Aperiam reprehenderit, id dolorem reiciendis atque sit. Mollitia consequatur assumenda autem tempora cum quas blanditiis aspernatur dolore accusantium ipsam!"
                                            [ngClass]="{ 'invalid-field': (candidateForm.get('description').invalid && (candidateForm.get('description').touched || submitted)) }"></textarea>
                                        <span  *ngIf="show1"
                                            style="margin-left: auto; color: gray; font-size: small;">
                                            Remaining characters: {{ remainingCharacters }}</span>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Work Experience</label>
                                        <div style="position: relative;">
                                            <textarea id="your_message" class="form-control"
                                                formControlName="work_experience" [(ngModel)]="experenceText"
                                                (focus)="onInput1()" (input)="onInput1()" minlength="0" maxlength="750" rows="7"
                                                placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, quaerat aliquid. Aperiam reprehenderit, id dolorem reiciendis atque sit. Mollitia consequatur assumenda autem tempora cum quas blanditiis aspernatur dolore accusantium ipsam!"
                                                [ngClass]="{ 'invalid-field': (candidateForm.get('work_experience').invalid && (candidateForm.get('work_experience').touched || submitted)) }"></textarea>
                                            <span *ngIf="show2"
                                                style="margin-left: auto; color: gray; font-size: small;">
                                                Remaining characters: {{ remainingCharacters1 }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Research</label>

                                        <textarea id="your_message" class="form-control" formControlName="research" (focus)="onInput2()" (input)="onInput2()"
                                            rows="7" placeholder="Lorem ipsum dolor sit amet consectetur" [(ngModel)]="researchText" minlength="0" maxlength="500"
                                            [ngClass]="{ 'invalid-field': (candidateForm.get('research').invalid && (candidateForm.get('research').touched || submitted)) }"></textarea>
                                        <span  *ngIf="show3"
                                            style="margin-left: auto; color: gray; font-size: small;">
                                            Remaining characters: {{ remainingCharacters2 }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <!-- <button
                        form="ngFormCandidateForm"
                        type="submit"
                        class="btn create-ac-btn mb-4"
                        [disabled]="!candidateForm.dirty"
                        [ngClass]="{'disabled-button': candidateForm.dirty}"
                    >
                        Save
                    </button> -->
                </div>

                <div class="create-education create-education-two">
                    <div class="create-education-wrap">
                        <div class="create-education-left">
                            <h3>Education Details</h3>
                        </div>

                        <div *ngIf="lengthOfEducation <= 5" class="create-education-right">
                            <a (click)="addCreds()"> <i class="icofont-plus"></i> &nbsp;Add Education</a>
                        </div>
                    </div>
                    <form id="ngFormEducationForm" [formGroup]="educationForm">
                        <div formArrayName="educational_details">
                            <div *ngFor="
                                    let creds of getEducationFormControls().controls;
                                    let i = index
                                ">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Stream</label>
                                            <input type="text" class="form-control" formControlName="stream"
                                                placeholder="Under Graduate" (keyup)="onInputChangeForEducation()" />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Degree</label>
                                            <input type="text" class="form-control" formControlName="degree"
                                                placeholder="BSC in Computer Science"
                                                (keyup)="onInputChangeForEducation()" />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Institute</label>
                                            <input type="text" class="form-control" formControlName="institute"
                                                placeholder="Gable University of Design & Arts"
                                                (keyup)="onInputChangeForEducation()" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>From Year</label>
                                            <input type="number" class="form-control" formControlName="from_year"
                                                placeholder="2016" (keyup)="onInputChangeForEducation()" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>To Year</label>
                                            <input type="number" class="form-control" formControlName="to_year"
                                                placeholder="2019" (keyup)="onInputChangeForEducation()" />
                                        </div>
                                    </div>
                                    <hr *ngIf="i == 0" />
                                    <div class="remove-skills-wrap">
                                        <div class="remove-skills-right">
                                            <a *ngIf="i > 0" (click)="removeEducationPoup(i)">Remove Education</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


                <div class="create-skills">
                    <div class="create-skills-wrap">
                        <div class="create-skills-left">
                            <h3>Skills</h3>
                        </div>

                        <div *ngIf="lengthOfSkills < 9 " class="create-skills-right">
                            <a (click)="addCrSkill()"><i class="icofont-plus"></i> &nbsp;Add Skill</a>
                        </div>
                    </div>

                    <form id="ngFormSkillsForm" [formGroup]="skillsForm">

                        <div class="row" formArrayName="skills_array">

                            <div *ngFor="let skillFetch of getSkillFormControls().controls;
                                    let i = index" [formGroupName]="i">
                                <div>
                                    <div class="col-lg-6">
                                        <div class="d-flex justify-content-between">
                                            <label>Skill {{ i + 1 }}</label>
                                            <span style="cursor: pointer;" *ngIf="i > 0" (click)="removeSkillPoup(i)"><i
                                                    class="icofont-ui-delete" style="color: red;"></i>Delete</span>
                                        </div>
                                        <div class="form-group">
                                            <input class="form-control" placeholder="UX/UI Design"
                                                formControlName="name" type="text" (keyup)="onInputChange()" />
                                            <div>
                                            </div>
                                            <div class="col-lg-6">
                                                <input formControlName="skillProficiency" class="skillSlider"
                                                    id="skillRange" type="range" min="0" max="100"
                                                    (input)="onSliderChange($event.target.value, i)">
                                                <span>{{ skillFetch.get('skillProficiency').value }} %</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>

                </div>

                <div class="create-skills">
                    <div class="create-skills-wrap">
                        <div class="create-skills-left">
                            <h3>Social Links</h3>
                        </div>
                    </div>

                    <form id="ngFormSocialLinks" [formGroup]="socialLinks" (ngSubmit)="onSubmit(socialLinks)">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Facebook</label>
                                    <input type="url"
                                        [ngClass]="{'invalid-field': socialLinks.get('social_links_facebook').invalid && socialLinks.get('social_links_facebook').touched}"
                                        class="form-control" formControlName="social_links_facebook"
                                        placeholder="https://www.facebook.com" 
                                        [ngClass]="{ 'invalid-field': (socialLinks.get('social_links_facebook').invalid && (socialLinks.get('social_links_facebook').touched || submitted)) }"/>

                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Instagram</label>
                                    <input type="url" class="form-control"
                                        [ngClass]="{'invalid-field': socialLinks.get('social_links_instagram').invalid && socialLinks.get('social_links_instagram').touched}"
                                        formControlName="social_links_instagram"
                                        placeholder="https://www.instagram.com" 
                                        [ngClass]="{ 'invalid-field': (socialLinks.get('social_links_instagram').invalid && (socialLinks.get('social_links_instagram').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>LinkedIn</label>
                                    <input type="url"
                                        [ngClass]="{'invalid-field': socialLinks.get('social_links_linkedin').invalid && socialLinks.get('social_links_linkedin').touched}"
                                        class="form-control" formControlName="social_links_linkedin"
                                        placeholder="https://www.linkedin.com" 
                                        [ngClass]="{ 'invalid-field': (socialLinks.get('social_links_linkedin').invalid && (socialLinks.get('social_links_linkedin').touched || submitted)) }"/>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Twitter</label>
                                    <input type="url" class="form-control"
                                        [ngClass]="{'invalid-field': socialLinks.get('social_links_twitter').invalid && socialLinks.get('social_links_twitter').touched}"
                                        formControlName="social_links_twitter" placeholder="https://www.dribble.com" 
                                        [ngClass]="{ 'invalid-field': (socialLinks.get('social_links_twitter').invalid && (socialLinks.get('social_links_twitter').touched || submitted)) }"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <button form="ngFormCandidateForm" type="submit" class="btn create-ac-btn mb-4"
                    [disabled]="!candidateForm.dirty && !socialLinks.dirty && !educationForm.dirty && !skillsForm.dirty && isSkillRemoved && isEducationRemoved"
                    [ngClass]="{'disabled-button': candidateForm.dirty}">
                    Save
                </button>

            </div>

        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                [fullWidthResponsive]="true">
            </ng-adsense>
        </div>
    </div>
</div>