import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global';
import { JobListService } from '../../services/job-list/job-list.service';
import { LocalStorageService } from '../../services/localStorage/localStorage.services';
import { PopupService } from '../../services/other/popup.service';
import { AccountService } from '../../services/acccount/account.service';

@Component({
  selector: 'app-applied-job',
  templateUrl: './applied-job.component.html',
  styleUrls: ['./applied-job.component.scss']
})
export class AppliedJobComponent implements OnInit {
  myAppliedJobs
  jobCategorey
  page=1
  candidateID:any
  pageSize=10
  itemLength
  status ={
    'Applied':"Applied",
    'Viewed':"Viewed",
    'Selected':"Selected",
    'Rejected':"Rejected",
    'Pending':'Pending'
  }


  constructor(
    private jobListService: JobListService,
    private localStorageService:LocalStorageService,
    private accountService: AccountService,
    private popup: PopupService) { }

  ngOnInit(): void {
    this.accountService.getUserId().subscribe(
      (response) => {
        this.candidateID = response.body;
        this.appliedJobs(this.candidateID);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
    
  }
  
  appliedJobs(candidateID){
      this.jobListService.getAppliedJobs(candidateID)
      .subscribe(resp => {
        this.myAppliedJobs = <any[]>resp.body;
        this.itemLength = this.myAppliedJobs.count
        this.myAppliedJobs = this.myAppliedJobs.job_post
      //   const count = this.myAppliedJobs.reduce((acc, obj) => {
      //     if (obj.is_applied === true) {
      //         return acc + 1;
      //     } else {
      //         return acc;
      //     }
      // }, 0);
    }
      ,err => {
      
        this.popup.Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })})
    }


}
