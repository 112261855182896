import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { JobListService } from "../../services/job-list/job-list.service";
import { AccountService } from "../../services/acccount/account.service";
import { ContactUsService } from "../../services/other/contact-us.service";
import { CandidateServicesService } from "../../services/candidate-services/candidate-services.service";

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    role;
    category;
    jobPortalStats:any;
    searchForm: FormGroup;

    // Values For profile progress
    item1:boolean;item2:boolean;item3:boolean;item4:boolean;item5:boolean;item6:boolean;
    constructor(
        private jobListService: JobListService,
        private router: Router,
        private accountService : AccountService,
        private formBuilder: FormBuilder,
        private contactUsservice: ContactUsService,
        private candidateServices:CandidateServicesService, 

    ) {}



    ngOnInit(): void {
        this.contactUsservice.getJobPortalStats().subscribe((resp:any) => {this.jobPortalStats = resp.body});
        this.role = this.accountService.getRole();

        this.searchForm = this.formBuilder.group({
            jobTitle: ['', [Validators.required]],
            jobLocation: [''],
          });
        this.candidateServices.candidateListGetById(this.accountService.getUsername()).subscribe((response) => {
           this.calculateRatings(response.body);
        })
    }

    onSubmit() {
        this.jobListService.jobTitleSubject.next(this.searchForm.value.jobTitle);
        this.jobListService.jobLocationSubject.next(this.searchForm.value.jobLocation);
        this.router.navigate(["/job-list"]);
    }

    calculateRatings(data: any): void {
        this.item1 = Boolean(data.firstName && data.lastName && data.username && data.email && data.contactNumbers && data.dateOfBirth);
        this.item2 = Boolean(data.workExperience);
        this.item3 = Boolean(data.educationalDetails && data.educationalDetails.length > 0);
        this.item4 = Boolean(data.skills && data.skills.length > 0);
        this.item5 = Boolean(data.profilePicBlob);
        this.item6 = Boolean(data.resume_link)
    }
}
