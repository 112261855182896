import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExamService } from '../../services/exam/exam.service';
import { AccountService } from '../../services/acccount/account.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-exam-creation',
  templateUrl: './exam-creation.component.html',
  styleUrls: ['./exam-creation.component.scss']
})
export class ExamCreationComponent implements OnInit {
  examForm: FormGroup;
  userName: string;
  collegeCode: string;
  candidateList = [];
  page1 = 1;
  page2 = 1;
  pageSize1 = 5;
  pageSize2 = 25;
  questionList = [];
  selectedExamCan: string;
  selectedExamQues: string;
  constructor(
    private formBuilder: FormBuilder,
    private examService: ExamService,
    private accountService: AccountService,
  ) { }

  examsList = [
    {
      "id": "1",
      "examId": "A296578",
      "examDate": "18-07-2021",
      "subject": "Sample Exam",
      "examCode": "EXAM1",
    },

  ];
  ngOnInit(): void {
    this.userName = this.accountService.getUsername();
    this.collegeCode = this.accountService.get('branch');
    this.examForm = this.formBuilder.group({
      examCode: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      noOfQuestions: [0, [Validators.required]],
      totalMarksInExam: [0, [Validators.required]],
      passingMarksInExam: [0, [Validators.required]],
      mandatoryQuestn: [0, [Validators.required]],
      examDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      endTime: ['', [Validators.required]],

      collegeCode: [this.collegeCode, [Validators.required]],
      teacherId: [this.userName, [Validators.required]],
    });
    this.examService.getAllExamRecruiter().subscribe((res: any) => {
      this.examsList = res.body;
    })
  }

  scroll(id) {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: 'smooth' });
  }



  createExam() {
    const examForm = {
      "examCode": this.examForm.value.examCode,
      "collegeCode": this.examForm.value.collegeCode,
      "subject": this.examForm.value.subject,
      "noOfQuestions": this.examForm.value.noOfQuestions,
      "totalMarksInExam": this.examForm.value.totalMarksInExam,
      "passingMarksInExam": this.examForm.value.passingMarksInExam,
      "mandatoryQuestn": this.examForm.value.mandatoryQuestn,
      "teacherId": this.examForm.value.teacherId,
      "examDate": this.examForm.value.examDate,
      "startTime": this.examForm.value.startTime + ":00",
      "endTime": this.examForm.value.endTime + ":00",
    }
    this.examService.createExam(examForm).subscribe(
      (res: any) => {
        if (res.status == 'OK') { Swal.fire('Success', 'Exam Created', 'success'); this.examForm.reset }
      }, (err: any) => {
        if (err) { Swal.fire('Error', 'Some Error Occured', 'error'); }
      });
  }
  uploadQuestion(event: any, examId: string) {
    const selectedFile = event.target.files[0];
    const fileName = selectedFile.name;
    const formData = new FormData();
    formData.append('questionList', selectedFile, fileName);
    this.examService.addQuestion(examId, formData).subscribe((res: any) => {
      if (res.status == "OK") { Swal.fire('Success', 'Question List Uploaded', 'success'); this.ngOnInit(); }
    }, (err) => {
      if (err) { Swal.fire('Error', 'Some Error Occured', 'error'); }
    });
  }
  uploadCandidates(event: any, examId: string) {
    const selectedFile = event.target.files[0];
    const fileName = selectedFile.name;
    const formData = new FormData();
    formData.append('examineeList', selectedFile, fileName);
    this.examService.addExaminee(examId, formData).subscribe((res: any) => {
      if (res.status == "OK") { Swal.fire('Success', 'Candidate List Uploaded', 'success'); this.ngOnInit(); }
    }, (err) => {
      if (err) { Swal.fire('Error', 'Some Error Occured', 'error'); }

    });
  }
  viewQuesition(examId, examCode: string) {
    this.examService.examIdSubject.next(examId);
    this.examService.examCodeSubject.next(examCode);
  }
  viewCandiate(examId, examCode: string) {
    this.examService.examIdSubject.next(examId);
    this.examService.examCodeSubject.next(examCode);
  }
  getResult(examId, examCode: string) {
    this.examService.examIdSubject.next(examId);
    this.examService.examCodeSubject.next(examCode);
  }

  isOneDayBeforeGivenDate(givenDateString: string): boolean {
    const givenDate = moment(givenDateString, 'YYYY MM DD');
    const today = moment().startOf('day');

    // Calculate the difference in days
    const differenceInDays = givenDate.diff(today, 'days');

    // Check if the difference is less than 1 day
    return differenceInDays < 1;
  }

}

@Component({
  templateUrl: './exam-candiate.component.html',
  styleUrls: ['./exam-creation.component.scss']
})
export class ExamCandiateView implements OnInit {
  page1 = 1;
  pageSize1 = 25;
  selectedExam: string;
  candidateList = [];
  examCode: string;
  examId: string;
  constructor(private examService: ExamService,) { }
  ngOnInit(): void {
    this.examService.examCode$.subscribe(res => { this.examCode = res })
    this.examService.examId$.subscribe(res => { this.examId = res })
    this.viewCandiate();
  }
  viewCandiate() {
    this.selectedExam = this.examCode;
    this.examService.getAllCandiate(this.examId).subscribe((res: any) => {
      if (res) { this.candidateList = res.body }
    })
  }
}

@Component({
  templateUrl: './exam-question.component.html',
  styleUrls: ['./exam-creation.component.scss']
})
export class ExamQuestionView implements OnInit {
  page1 = 1;
  pageSize1 = 5;
  selectedExam: string;
  questionList = [];
  examCode: string;
  examId: string;
  constructor(private examService: ExamService,) { }
  ngOnInit(): void {
    this.examService.examCode$.subscribe(res => { this.examCode = res })
    this.examService.examId$.subscribe(res => { this.examId = res })
    this.viewQuesition();
  }
  viewQuesition() {
    this.selectedExam = this.examCode;
    this.examService.getAllQuestion(this.examId).subscribe((res: any) => {
      if (res) { this.questionList = res.body; }
    })
  }
}

@Component({
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-creation.component.scss']
})
export class ExamResultView implements OnInit {
  page1 = 1;
  pageSize1 = 50;
  selectedExam: string;
  candidateList = [];
  examCode: string;
  examId: string;
  constructor(private examService: ExamService,) { }
  ngOnInit(): void {
    this.examService.examCode$.subscribe(res => { this.examCode = res })
    this.examService.examId$.subscribe(res => { this.examId = res })
    this.viewResult();
  }
  viewResult() {
    this.selectedExam = this.examCode;
    this.examService.getExamResult(this.examId).subscribe((res: any) => {
      if (res) { this.candidateList = res.body }
    })
  }
  convertToExcel(){
    try {
      const headers = [
        'Sr No.',
        'Email',
        'Correct Question',
        'Total Marks',
        'Marks Obtain',
        'Remark'
      ];

      const data = this.candidateList.map((candidate, index) => ({
        'Sr No.': index + 1,
        'Email': candidate.username,
        'Correct Question': candidate.totalCorrectQues,
        'Total Marks': candidate.totalMarks,
        'Marks Obtain': candidate.totalMarkObtain,
        'Remark': '',
      }));
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header: headers });
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      Swal.fire('File Downloaded','','success');

      XLSX.writeFile(wb, this.examCode + '-Result' + '.xlsx');
    } catch (error) {
      console.error("Error in downloadig:", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  }
}