<link href="https://getbootstrap.com/examples/jumbotron-narrow/jumbotron-narrow.css" rel="stylesheet">
<link rel="stylesheet" type="text/css" href="//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css">
<div class="container bootstrap snippets bootdey text-center">
<div class="col-md-10 text-center">
    <div class="jumbotron">
        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" class="img-circle img-thumbnail img-user" alt="">    
        <div class="content">
            <ul class="brands brands-inline hidden-xs">
                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
            </ul>   
            <h2 class="name">El Grone's</h2>
            <ul class="brands brands-inline hidden-xs">
                <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
            </ul>       
        </div>
        <!-- Slideshow start -->                          
        <div class="carousel slide vertical" data-ride="carousel">
            <div class="carousel-inner">
                <!-- Slideshow item 1 active --> 
                <div class="item next left"><h3>I <i class="fa fa-heart"></i> webdesign</h3></div>
                <!-- Slideshow item 2 -->     
                <div class="item"><h3>Creative coder <i class="fa fa-code icon"></i></h3></div>
                <!-- Slideshow item 3 -->   
                <div class="item active left"><h3><i class="fa fa-trophy"></i> Awesome designer</h3></div>
            </div>
        </div>
        <!-- Slideshow end -->
        <div class="row">
            <a href="#" class="btn btn-info btn-lg text-success" >
                <i class="glyphicon glyphicon-share"></i> Option one
            </a>
            <a href="#" class="btn btn-info btn-lg text-success" >
                <i class="glyphicon glyphicon-check"></i>Option two
            </a>
        </div>
    </div>
</div>
</div>