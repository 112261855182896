import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../../global';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CandidateServicesService {

  // this is for the candidate list component to presist the data dont delete
  private cachedData: any;
  private branch:any;private year:any;private sem:any;
  private sbranch:any;private syear:any;private ssem:any;
  setCachedData(data: any) {this.cachedData = data;}
  getCachedData() {return this.cachedData;}
  setBranchYearSem(branch,year,sem){this.branch = branch ; this.year = year;this.sem = sem;}
  getBranch(){ return this.branch;}; getYear(){ return this.year;}; getSem(){ return this.sem;}
  setSBranchSYearSSem(sbranch,syear,ssem){this.sbranch = sbranch ; this.syear = syear;this.ssem = ssem;}
  getSBranch(){ return this.sbranch;}; getSYear(){ return this.syear;}; getSSem(){ return this.ssem;}


  // 

  constructor(private httpClient: HttpClient)
  { }

  // For recruiter to view the cnadiate profile
  usernameSubject = new BehaviorSubject<string>('');
  userName$ = this.usernameSubject.asObservable();
  // For recruiter to view the cnadiate profile

  candidateListGet(collegeCode,branchCode,yearCode,SemCode){  
    const params ={
      "collegeCode":collegeCode,
      "branchCode":branchCode,
      "yearCode":yearCode,
      "semCode":SemCode
    }
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/jobs/jobpost/allseeker`, {params, observe: 'response' });
    
  }

  candidateSkillsListGet(){
    return this.httpClient.get(`api/skills-array/`);
  
  }
  candidateSkillRemove(skill_id,seeker_id){
    return this.httpClient.delete<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/remove-skill/${skill_id}?seeker_id=${seeker_id}`);
  }

  candidateEducationDetailListGet(){
    return this.httpClient.get(`api/education-detail-array/`);
  
  }
  candidateEducationDetailRemove(education_id,seeker_id){
    return this.httpClient.delete<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/remove-education-details/${education_id}?seeker_id=${seeker_id}`);
  
  }
  candidateListGetById(seekerUsername){
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile?seekerUsername=${seekerUsername}`, { observe: 'response' });
  }

  getCandidateInfoPublic(seekerUsername){
    return this.httpClient.get<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/online-cv?seekerUsername=${seekerUsername}`, { observe: 'response' });
  }
  
  candidateInfoPatch(data, seekerUsername){
    // {{urlgpc}}user/seeker-update/1  
    return this.httpClient.put<any>(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/seeker-update/${seekerUsername}`,data, { observe: 'response' });

  } 

  downloadProfilePic(filePath:string){
    return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile-pic?filePath=${filePath}`, { responseType:'blob' });
  }
  downloadProfilePicPublic(filePath:string){
    return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/profile-pic-online-cv?filePath=${filePath}`, { responseType:'blob' });
  }
  downloadResume(filePath:string){
    return this.httpClient.get(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/resume?filePath=${filePath}`, { responseType : 'blob' });
  }
  uploadProfilePic(folder:string,userType:string,collegeCode:string,firstName:string,lastName:string,dateOfBirth:string,id:string, formData){
    return this.httpClient.put(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/update-profile-pic?folder=${folder}&userType=${userType}&collegeCode=${collegeCode}&firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&id=${id}`,formData,{responseType: 'text'});
  }
  uploadResume(folder:string,userType:string,collegeCode:string,firstName:string,lastName:string,dateOfBirth:string,id:string, formData){
    return this.httpClient.put(`${GlobalConstants.CONNECT_BASE_URL}/api/jobportal/user/update-resume-link?folder=${folder}&userType=${userType}&collegeCode=${collegeCode}&firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&id=${id}`,formData,{responseType: 'text'});
  }

}
