<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="d-none d-md-block col-md-2 " style="margin-top: 100px;">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>

        <div class="contact-form-area ptb-100 col-md-8">
            <div class="container-fluid">
                <h1 class="text-center p-4">Contact Us</h1>
                <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="row contact-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <input type="text" formControlName="name" name="name" id="name" class="form-control"
                                    required placeholder="Your Full Name">
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <input formControlName="email" type="email" name="email" id="email" class="form-control"
                                    required placeholder="Your Email">
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="form-group">
                                <textarea name="message" formControlName="message" class="form-control" id="message"
                                    cols="30" rows="8" required placeholder="Message"></textarea>
                            </div>
                        </div>

                        <div class="col-md-12 col-lg-12">
                            <div class="text-center">
                                <button type="submit" class="btn contact-btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-none d-md-block col-md-2" style="margin-top: 100px;">
            <div class="row ">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>
</div>
<div class="row col-lg-12">
    <div class="container-fluid">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6119489645323262"
        crossorigin="anonymous"></script>
    <ins class="adsbygoogle"
        style="display:block"
        data-ad-format="autorelaxed"
        data-ad-client="ca-pub-6119489645323262"
        data-ad-slot="1998180248"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script></div>
</div>

<div class="map-area pb-100">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.738892630883!2d72.84793831446538!3d19.164165987033817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6ad317d6a37%3A0x4c8f24e65f8b21b0!2sGoregaon%20West%2C%20Mumbai%2C%20Maharashtra%2C%20India!5e0!3m2!1sen!2sbd!4v1587549815840!5m2!1sen!2sbd"></iframe>
</div>