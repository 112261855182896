import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global';
import { JobListService } from '../../services/job-list/job-list.service';
import { LocalStorageService } from '../../services/localStorage/localStorage.services';
import { PopupService } from '../../services/other/popup.service';

@Component({
  selector: 'app-favourite-job',
  templateUrl: './favourite-job.component.html',
  styleUrls: ['./favourite-job.component.scss']
})
export class FavouriteJobComponent implements OnInit {
  myAppliedJobs
  jobCategorey
  page=1
  pageSize=10
  itemLength
  status ={
    1:"Applied",
    2:"Viewed",
    3:"Selected",
    4:"Rejected",
  }


  constructor(
    private jobListService: JobListService,
    private localStorageService:LocalStorageService,
    private popup: PopupService) { }

  ngOnInit(): void {
    this.appliedJobs();
  }
  
  appliedJobs(){

    this.jobListService.applyJobGet(this.localStorageService.getItem("seekerId"))
    .subscribe(resp => {
      this.myAppliedJobs = <any[]>resp.body;
      this.itemLength = this.myAppliedJobs.length
 
      
    }
    ,err => {
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })

    }
)
    this.jobCategorey={
      "1":"Full Time",
      "2":"Part Time",
      "3":"Internship",
  }

  }

}
