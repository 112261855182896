import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { UserResume} from './user-interface'
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service'

export class ResumeTemplate3{
    constructor(private candidateService:CandidateServicesService){}
    private currentY = 20;

    public async profileBannerTemplate(user:UserResume){
        this.currentY = 25;
        const doc = new jsPDF();
        let image:any;
        let imageFormat:any;
        let callImg = "../../../../assets/img/call.png";
        let emailImg = "../../../../assets/img/email.png";
        let locImg = "../../../../assets/img/location.png";
        const rightMargin = doc.internal.pageSize.width - 10;
        const font = "Helvetica";
        if(user.profilePicBlob != null && user.profilePicBlob != ""){
            image = await this.downloadTheImageFromS3(user.profilePicBlob);
            const regex = /\.([a-zA-Z]+)$/;
            imageFormat = user.profilePicBlob.match(regex);
            imageFormat = imageFormat[1].toUpperCase();
        }else{
            image="../../../../assets/img/neutral_profile.jpg";
            imageFormat="JPG";
        }

        //Top Banner
        doc.setFillColor(120, 120, 195);
        doc.roundedRect(10,10,rightMargin - 10,50,0,0,'F');
        // doc.addImage(image, imageFormat, 10,10, 50, 50);
        this.addImage(doc,image,imageFormat,10,10,50,50);
        doc.setFontSize(34);doc.setFont(font,'Bold');doc.setTextColor(0);
        doc.text(`${user.firstName} ${user.lastName}`,70,this.currentY);this.currentY+=10;
        doc.setFontSize(18);doc.setFont(font,'Bold');doc.setTextColor(0);
        doc.text(user.jobTitle,70,this.currentY);this.currentY+=5;
        doc.setFontSize(12);doc.setFont(font,'Oblique');doc.setTextColor(0);
        doc.addImage(emailImg, "PNG", 70,this.currentY, 5, 5);doc.text(user.email,77,this.currentY+4);
        doc.addImage(callImg, "PNG", 80 + doc.getTextWidth(user.email),this.currentY, 5, 5);
        doc.text(user.contactNumbers[0].number,87+doc.getTextWidth(user.email),this.currentY+4);this.currentY+=7
        doc.addImage(locImg, "PNG", 70,this.currentY, 5, 5);doc.text(user.strAddress,77,this.currentY+4);

        // Main Body
        this.currentY = 70;
        let maxWidth = (doc.internal.pageSize.width - 15);

        // About
        this.addSubHeading(doc,"ABOUT",8);
        this.addText(doc,user.description,15,maxWidth,10);
        // Work Experienece
        this.addSubHeading(doc,'WORK HISTORY',8);
        this.addText(doc,user.workExperience,15,maxWidth);
        //Education
        this.addSubHeading(doc,'EDUCATION',8);
        doc.setFont("Helvetica", 'Oblique');
        doc.setFontSize(10);doc.setTextColor(0);
        user.educationalDetails
            .sort((a, b) => parseInt(b.from_year) - parseInt(a.from_year))
            .map(edu => {
                doc.text(`${edu.stream}`,15,this.currentY);this.currentY +=5
                doc.text(`${edu.degree}`,15,this.currentY);this.currentY +=5
                doc.text(`${edu.institute} `,15,this.currentY);
                let text = `---${edu.from_year} - ${edu.to_year}`;
                let x = doc.internal.pageSize.width - doc.getTextWidth(text) - 10
                doc.text(text,x,this.currentY);this.currentY +=7
            });this.currentY+=2;
        // Research / Projects
        this.addSubHeading(doc,'RESEARCH',8)
        this.addText(doc,user.research,15,maxWidth);
        // Skills
        this.addSubHeading(doc,"SKILLS",8);
        let Y = this.currentY; // to make two column
        let skills1 = user.skills.slice(0,5);
        let skills2 = user.skills.slice(5,10);
        skills1
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let level = this.skillLevel(skill.skillProficiency)
                doc.setFont('Helvetica', 'BoldOblique');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(`> ${skill.name}`,15,this.currentY);
                let r = (doc.internal.pageSize.width /2) - doc.getTextWidth(level) - 10;
                doc.setFont('Helvetica');doc.setFontSize(10);doc.setTextColor(105, 105, 105);
                doc.text(`-- ${level}`,r,this.currentY);
                this.currentY+=5
            })
        this.currentY = Y;
        skills2
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let y = (doc.internal.pageSize.width/2) + 5;
                let level = this.skillLevel(skill.skillProficiency)
                doc.setFont('Helvetica', 'BoldOblique');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(`> ${skill.name}`,y,this.currentY);
                let r = doc.internal.pageSize.width  - doc.getTextWidth(level) - 15;
                doc.setFont('Helvetica');doc.setFontSize(10);doc.setTextColor(105, 105, 105);
                doc.text(`-- ${level}`,r,this.currentY);
                this.currentY+=5
            })
        
        this.addWatermark(doc,'Powered by  Campus Orbit');
        return doc
    }
    private addSubHeading(doc: any, text: string,plusY:number, align: string = 'left') {
        doc.setFont('Helvetica', 'Bold');
        doc.setFontSize(14);doc.setTextColor(120, 120, 195);
        doc.text(text, 10, this.currentY, { align }); 
        doc.setLineWidth(1);doc.setDrawColor(120, 120, 195);
        let textWidth = doc.getTextWidth(text) + 12;
        doc.line(textWidth, this.currentY, doc.internal.pageSize.width - 10, this.currentY,'FD');
        this.currentY += plusY; // Increment Y position
    }
    private addText(doc, text, x, maxWidth,extraSpace=0) {
        doc.setFont("Helvetica", 'Oblique');
        doc.setFontSize(10);doc.setTextColor(0);
        // Ensure x + maxWidth does not exceed page width
        if (x + maxWidth > doc.internal.pageSize.width) {
            maxWidth = doc.internal.pageSize.width - x - 15; // Adjust maxWidth
        }
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        doc.text(text, x, this.currentY, { maxWidth: maxWidth - extraSpace });
        this.currentY += blockHeight + 8;
    }
    private skillLevel(skillProficiency){
        const levelArray = ["Beginner","Intermediate","Proficient","Expert"];
        if(skillProficiency <= 50){return levelArray[0]}
        if(skillProficiency > 50 && skillProficiency <=70){return levelArray[1]}
        if(skillProficiency > 70 && skillProficiency <=85){return levelArray[2]}
        if(skillProficiency > 85 && skillProficiency <=95){return levelArray[3]}
        if(skillProficiency > 95 && skillProficiency <=100){return levelArray[4]}
    }
    private addWatermark(doc:any, watermarkText: string) {
        doc.setFontSize(12);
        doc.setFont('Times','Italic');
        doc.setTextColor(100, 100, 100);
        const watermarkX = doc.internal.pageSize.width - 10;
        const watermarkY = doc.internal.pageSize.height - 5;
        doc.textWithLink(watermarkText, watermarkX, watermarkY, { url: 'https://job.campusorbit.com', align:'right' });
    }
    private addImage(doc: any, imagePath: string, imageFormat: string, x: number, y: number, width: number, height: number) {
        const imgData = imagePath; // Replace with your image data
        doc.addImage(imgData, imageFormat, x, y, width, height);
    }    
    private downloadTheImageFromS3(filePath: string): Promise<string> {
        return new Promise((resolve, reject) => {
          this.candidateService.downloadProfilePicPublic(filePath).subscribe(
            (res) => {
              const imageUrl = URL.createObjectURL(res);
              resolve(imageUrl);
            },
            (err) => {
              reject(err);
            }
          );
        });
    }
}