import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpContextToken
} from '@angular/common/http';
import { Observable, } from 'rxjs';
import { AccountService } from '../services/acccount/account.service';
export const BYPASS_LOG = new HttpContextToken(() => false);
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  CONTENT_TYPE: string = 'content-type';
  constructor(private accountService : AccountService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.context.get(BYPASS_LOG) === true){
      console.log("By passed The interceptor.")
      return next.handle(request);
    }
    let user: any = null;
    user = JSON.parse(this.accountService.get('user'));
    if (this.checkUrlIfNeedRefresh(user)) this.accountService.refreshToken();
  
    if (user !== null && user !== undefined) {
      request = request.clone({
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + user.Token.jwtToken
        })
      });
    }
    return next.handle(request);
  }

  private checkUrlIfNeedRefresh(user: any): boolean {
    let now = new Date();
    let expiry = now; 
    let utcExpiry

    if (user !== null && user !== undefined) {
      utcExpiry = new Date(user.Token.expiry);
      now = new Date(now.toISOString());
    }
  
    let refreshRequired = now > utcExpiry;
    return refreshRequired;
  }

}
