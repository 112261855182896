import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/localStorage/localStorage.services';
import { AccountService } from '../../services/acccount/account.service';
import { HomeTwoComponent } from '../../pages/home-two/home-two.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  role: string;
  switchUser: string;
  userId: any;
  isUserLoggedIn: boolean;
  isHomePage :boolean;

  constructor(
    private localStorageServices: LocalStorageService,
    public router: Router,
    private accountService: AccountService,

  ) { }

  ngOnInit(): void {
    this.accountService.isLoggedIn$.subscribe((loggedIn) => {this.isUserLoggedIn = loggedIn;});
    this.accountService.userRole$.subscribe((role) => {this.role = role;});
    this.accountService.getUserId().subscribe(
      (response) => {
        this.userId = response.body
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  triggerScrollFunction() {
    this.accountService.callFunction();
  }

  logout() {
    this.accountService.logout();
  }


  mobileNavActive = false;
  mobileNav() {
    this.mobileNavActive = true;
  }
}
