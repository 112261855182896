import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecruiterServicesService } from '../../services/candidate-services copy/recruiter-services.service';
import { PopupService } from '../../services/other/popup.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { CommonModule } from '@angular/common';
import { env } from '../../../../environments/env'
import { AccountService } from '../../services/acccount/account.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './recruiter-details.component.html',
  styleUrls: ['./recruiter-details.component.scss']
})
export class RecruiterDetailsComponent implements OnInit {
  recruiterInfo:any;
  id:any
  dashboard
  userName:string;
  contact:any;
  profile_picture:any;
  constructor( 
    private route: ActivatedRoute, 
    private recruiterServices:RecruiterServicesService, 
    private http: HttpClient,
    private popup: PopupService,
    private accountService:AccountService,
    private sanitizer: DomSanitizer
    )
    {
      this.id = this.route.snapshot.paramMap.get('id');
      this.userName = this.accountService.getUsername();
   }

   
  ngOnInit(): void {
    this.getRecruiterInfo(this.userName);
  }
  downloadProfilePic(filePath: string) {
    this.recruiterServices.downloadProfilePic(filePath).subscribe((blob: Blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.profile_picture = this.sanitizer.bypassSecurityTrustUrl(objectURL) as SafeUrl;
    });
  }


getRecruiterInfo(userName:string){
this.recruiterServices.recruiterGetByUsername(userName).subscribe(
  res=>{
  this.recruiterInfo=res.body
  this.contact = this.recruiterInfo.contactNumbers[0].number;
  if(this.recruiterInfo.gender === 'MALE'){
    this.profile_picture = 'assets/img/male_profile.png';
  }else if(this.recruiterInfo.gender === 'FEMALE'){
    this.profile_picture = 'assets/img/female_profile.png';
  }else{
    this.profile_picture = 'assets/img/neutral_profile.jpg'
  }
  if(this.recruiterInfo.recruiterImageUrl != null){
    this.profile_picture = this.recruiterInfo.recruiterImageUrl;
  }

  // this.getDashBoard(this.recruiterInfo.recruiteId);
  if(this.recruiterInfo.recruiterImageUrl != '' || this.recruiterInfo.recruiterImageUrl != null){
    this.downloadProfilePic(this.recruiterInfo.recruiterImageUrl);
  }
  },(err)=> {
    console.log(err)
    this.popup.Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
  })
})
}

getDashBoard(recruiter_id){
  this.recruiterServices.recruiterDashboardGetById(recruiter_id).subscribe(res=>{
    this.dashboard=res.body
    console.log("dashboad",this.dashboard);
  },err=> {
    this.popup.Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
    })
  })
}
downloadResume(link, name) {
  this.http.get(link, { responseType: 'blob' })
    .subscribe((res: Blob) => {
      saveAs(res, name+'.pdf');
    });
}

}
