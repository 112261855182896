<section class="content">
    <div class="exam-creation-area">
        <div class="page-title">
            <span>Aptitude Candiate</span>
        </div>
        <div class="conatiner-fluid row m-0">
            <div class="col-md-2">
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
            </div>
            <div class="container col-md-8 col-sm-12 pb-5">
                <div class="row" >
                    <div class="col card card-statistic-4" *ngIf="candidateList.length > 0;else elseBlock">
                        <div class="align-items-center justify-content-between">
                            <div class="card-content p-3">
                                <h4 class="text-center">Candidate list for exam-code: {{selectedExam}}</h4>
                                <hr>
                                <div class="tableBody">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr class="text-center align-middle">
                                                    <th>Sr No.</th>
                                                    <th>Number</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-center align-middle"
                                                    *ngFor="let candiate of candidateList | slice: (page1-1) * pageSize1 : page1 * pageSize1;index as i">
                                                    <td>{{i+1}}</td>
                                                    <td>{{candiate?.contactNumber}}</td>
                                                    <td>{{candiate?.studentUsername}}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div style="display:flex; justify-content: center;width: 100%; ">
                                        <ngb-pagination [collectionSize]="candidateList?.length" [(page)]="page1"
                                            [pageSize]="pageSize1"
                                            aria-label="Default pagination bg-warning"></ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #elseBlock>
                    <div class="text-center my-5 ">
                        <div class="my-5">
                           <strong> No Record Found for {{selectedExam}}</strong>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="col-md-2">
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
                <div class="row ">
                    <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false"
                        [fullWidthResponsive]="true">
                    </ng-adsense>
                </div>
            </div>
        </div>
    </div>
</section>