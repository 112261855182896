import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/global';
import { JobListService } from '../../services/job-list/job-list.service';
import { LocalStorageService } from '../../services/localStorage/localStorage.services';
import Swal from 'sweetalert2';
import { PopupService } from '../../services/other/popup.service';
import { AccountService } from '../../services/acccount/account.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  isApplied = false;
  buttonText = 'Apply';
  lastDate 
  public id: string;
  candidateID;
  jobPost:any
  jobDetail:any
  candidateApplied :any
  companyLogo:any;
  role:string
  constructor(
    private route: ActivatedRoute,
    private jobListService: JobListService,
    private popup: PopupService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer
    ) {

   }

  isLastDateExpired(lastDate: string): boolean {
    const currentDate = new Date();
    const lastDateObj = new Date(lastDate);
    return lastDateObj > currentDate;
  }
  ngOnInit(): void {    
    this.route.queryParams.subscribe(params => {
      this.lastDate = params['lastDateExpired'];
    });
    this.role = this.accountService.getRole();
    this.id = this.route.snapshot.paramMap.get('id');
    this.accountService.getUserId().subscribe(
      (response) => {
        this.candidateID = response.body;
        this.getJobDetails(this.id, this.candidateID);
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
  downloadCompanyLogo(filePath: string) {
    this.jobListService.downloadCompanyLogo(filePath).subscribe((blob: Blob) => {
      const objectURL = URL.createObjectURL(blob);
      this.companyLogo = this.sanitizer.bypassSecurityTrustUrl(objectURL) as SafeUrl;
    });
  }

  getJobDetails(jobId, candidateID){
    if(this.role == "JOBSEEKER"){
    this.jobListService.jobListGetByIdSeeker(jobId, candidateID)
    .subscribe(
      result=>{
        this.jobDetail = result.body.job_post;
        this.downloadCompanyLogo(this.jobDetail.companyLogo);
      }
      ,err=>{
        this.popup.Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      })}
    else{
      this.jobListService.jobListGetByIdStudent(jobId, candidateID)
      .subscribe(
        result=>{
          this.jobDetail = result.body.job_post;
          this.downloadCompanyLogo(this.jobDetail.companyLogo);
        }
        ,err=>{
          this.popup.Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          })
        })
    }
    }

    
  applyJob(jobId, seekerId, lastDate){
    const currentDate = new Date();
    if(lastDate < currentDate){
      this.popup.Toast.fire({
        icon: 'error',
        title: 'job expired'
      })
      return;
    }
    this.jobListService.applyJobPost(jobId, seekerId)
    .subscribe(
      result=>{
        if(result){
          this.isApplied = true;
          this.buttonText = 'Applied';
          
          this.popup.Swal.fire({
            icon: 'success',
            title: 'Job Applied successfully'
          })
      }
      },err=>{
        this.popup.Toast.fire({
          icon: 'error',
          title: 'Try Again'
        })
      }
    )
    

  }
 

}


