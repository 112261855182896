<div class="page-title-area pt-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Candidate Dashboard</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Candidate Dashboard</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="dashboard-area pt-100 row m-0">
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
    <div class="container col-md-8">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="dashboard-img">
                    <div class="pic-holder">
                    <img class="pic"
                        [src]="profilePhoto"
                    />
                    <input class="uploadProfileInput" type="file" 
                    accept="image/jpeg,image/jpg,image/png"
                    (change)="onProfileSelect($event)"
                    #hiddenfileinput1
                    name="profile_pic" id="hiddenfileinput1" style="opacity: 0;" />
                    <label for="hiddenfileinput1" class="upload-file-block">
                        <div class="text-center">
                          <div class="mb-2">
                            <i class="icofont-camera" ></i>
                          </div>
                          <div class="text-uppercase">
                            Update <br /> Profile Photo
                          </div>
                        </div>
                      </label>
                    </div>
                    <h3>{{ recruiterInfo?.firstName }}&nbsp;{{recruiterInfo?.lastName}}</h3>
                    <p>{{ recruiterInfo?.position }}</p>
                    <p>
                        <input
                        style="display: none"
                        type="file"
                        (change)="updateCompanyLogo($event)"
                        accept="image/jpeg,image/jpg,image/png"
                        #hiddenfileinput2 
                    />

                    </p>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="dashboard-nav">
                    <ul
                        class="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                    >
                        <li class="nav-item">
                            <a class="nav-link"
                                id="pills-home-tab"
                                data-toggle="pill"
                                role="tab"
                                routerLink="/recruiter-details"
                                aria-controls="pills-home"
                                aria-selected="false"
                                >My Profile</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" 
                            id="pills-profile-tab" 
                            data-toggle="pill" 
                            (click)="hiddenfileinput2.click()" 
                            role="tab" 
                            aria-controls="pills-profile"
                             aria-selected="false">Update Logo</a>
                             <input
                             style="display: none"
                             type="file"
                             
                             (change)="updateLogo($event)"
                             accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                             #hiddenfileinput2 
                         />
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                id="pills-contact-tab"
                                data-toggle="pill"
                                routerLink="/posted-jobs"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                                >Posted Jobs</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" id="pills-logout-tab" data-toggle="pill" href="{{recruiterInfo.resume_link}}" target="_blank" role="tab" aria-controls="pills-logout" aria-selected="false">{{recruiterInfo.resume_name}}</a> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab">
                
                <div class="create-information">
                    <form id="ngFormrecruiterForm"
                        [formGroup]="recruiterForm"
                        (ngSubmit)="onSubmit(recruiterForm)">
                        <h3>Basic Information</h3>

                        <div class="create-information-btn row"></div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Aikin Ward"
                                        formControlName="first_name"
                                        [readonly]="userRole == 'TPO' "
                                        [ngClass]="{'invalid-field': recruiterForm.get('first_name').invalid && recruiterForm.get('first_name').touched}"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Aikin Ward"
                                        formControlName="last_name"
                                        [readonly]="userRole == 'TPO' "
                                        [ngClass]="{'invalid-field': recruiterForm.get('last_name').invalid && recruiterForm.get('last_name').touched}"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Your Email</label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        formControlName="email"
                                        placeholder="hello@gable.com"
                                        [readonly]="true"
                                    />
                                </div>
                            </div>  
                            
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Your Position</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="position"
                                        placeholder="Human Resource"
                                    />
                                </div>
                            </div> 

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Your Phone</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="contact_number"
                                        placeholder="8982166224"
                                        [ngClass]="{'invalid-field': recruiterForm.get('contact_number').invalid && recruiterForm.get('contact_number').touched}"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div class="gender-area">
                                        <span>Gender</span>
                                        <input
                                            type="radio"
                                            name="gender"
                                            id="male"
                                            value="MALE"
                                            formControlName="gender"
                                            checked
                                        />
                                        <label for="male">Male</label>
                                        <input
                                            type="radio"
                                            name="gender"
                                            formControlName="gender"
                                            id="female"
                                            value="FEMALE"
                                        />
                                        <label for="female">Female</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button
                        form="ngFormrecruiterForm"
                        type="submit"
                        class="btn create-ac-btn mb-4"
                    >
                        Save
                    </button>
                </div>
                <div class="text-left"></div>
            </div>
        </div>
        <div *ngIf="userRole == 'RECRUITER' " class="create-education  create-information create-education-two">
            <div class="create-education-wrap">
                <div class="create-education-left">
                    <h3>Company Details</h3>
                </div>
            </div>
            <form id="ngFormCompanyForm"
                [formGroup]="companyForm"
                (ngSubmit)="onSubmit2(companyForm)">

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="company_name"
                                        placeholder="Campus Orbit"
                                    />
                                </div>
                            </div>  
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Company Email</label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        formControlName="company_email"
                                        placeholder="Campus Orbit"
                                    />
                                </div>
                            </div>  

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Company Website</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="company_website"
                                        placeholder="https://campusorbit.com"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Company City</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="company_city"
                                        placeholder="Delhi"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="address"
                                        placeholder="Delhi, India"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <div style="position: relative;">
                                    <label>About Company</label>
                                    <textarea
                                        id="your_message"
                                        class="form-control"
                                        formControlName="about_company"
                                        [(ngModel)]="textInput" (input)="onInput()" maxlength="500" 
                                        rows="8"
                                        placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, quaerat aliquid. Aperiam reprehenderit, id dolorem reiciendis atque sit. Mollitia consequatur assumenda autem tempora cum quas blanditiis aspernatur dolore accusantium ipsam!"
                                    ></textarea>
                                    <p style="position: absolute; bottom: 0; right: 0;margin-right: 5px; color: gray;">Remaining characters: {{ remainingCharacters }}</p>
                                </div>
                                </div>
                            </div>
                        </div>
            </form>

            <button
                form="ngFormCompanyForm"
                type="submit"
                class="btn create-ac-btn mb-4"
            >
                Save
            </button>
        </div>


    </div>
    <div class="col-md-2">
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
        <div class="row">
            <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
            </ng-adsense> 
        </div>
    </div>
</div>
