<div class="interview-bot-area">
    <div class="page-title">
        <h2>AI Interview Bot</h2>
    </div>
    <div class="conatiner-fluid row m-0">
        <div class="d-none d-md-block col-md-2">
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="continer interview-form col-md-8 pb-5">
            <div class="row" *ngIf="currentTab === 0">
                <div class="col-12">
                    <div class="welcome-page">
                        <div class="info">
                            <p>
                                <span>Welcome, You will be attempting an Interview.</span>
                                <span>&#x261B; Form</span>
                                <span>Fill in your detail then submit the form.</span>
                                <span>By submitting form you will start the interview.</span>
                                <span>&#x261B; Interview</span>
                                <span>AI Bot will ask you the questions, based on details you provided.</span>
                                <span>Click the answer button (allow the permission for microphone),</span>
                                <span>microphone will start listening, say your answer,</span>
                                <span>when done click on next question button.</span>
                                <span>&#x261B; Result</span>
                                <span>After interview click on generate result button.</span>
                                <span>The result are meant to give you a basic view</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="currentTab === 1">
                <div class="col-12">
                    <div class="page-title">
                        <h3>Fill in your detail</h3>
                    </div>
                    <form #detailForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 mb-1">
                                <div class="form-group">
                                    <label>Position (Job Role)</label>
                                    <input type="text" class="form-control" name="jobPosition" [(ngModel)]="jobPosition" placeholder="Sales Executive, Jr Devloper..." required/>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-1">
                                <div class="form-group">
                                    <label>Education Level</label>
                                    <input [(ngModel)]="userEducation" name="userEducation" class="form-control" id="userEducation" type="text" placeholder="BCOM, BMS, BSC IT.." required />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-1">
                                <div class="form-group">
                                    <label>Interview Topic</label>
                                    <input [(ngModel)]="interviewTopic" name="interviewTopic" class="form-control" id="interviewTopic" type="text" placeholder="Accounts, Excel, DataBase.." required />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-1">
                                <div class="form-group">
                                    <label>Secret Key<a class="link"  href="https://youtu.be/TBon6jszDlY" target="_blank">Get key</a></label>
                                    <input [(ngModel)]="secretKey" name="secretKey" class="form-control" id="secretKey" type="text" placeholder="122k3nnn2nk1nnn4n4n444nnk4k4n11kok"  required />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-1">
                                <div class="form-group">
                                    <label>Experience Level</label>
                                    <div class="form-group">
                                        <select class="form-select" name="experienceLevel" id="experienceLevel"  [(ngModel)]="experienceLevel">
                                            <option value="Fresher">Fresher</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Experienced">Experienced</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mb-1">
                                <div class="form-group">
                                    <label>Question Count</label>
                                    <input [(ngModel)]="questionCount" name="questionCount" class="form-control" id="questionCount" type="number" placeholder="number of question" readonly />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="currentTab === 2">
                <div class="col-12">
                    <div class="chat-container" #chatContainer>
                        <div class="conversation"  *ngFor="let question of questionList;let i=index">
                            <div class="bot-chat-card">
                                <img src="../../../../assets/img/bot.png"><p>{{question}}</p>
                            </div>
                            <div class="user-chat-card" *ngIf="answerList[i]">
                                <p>{{answerList[i]}}</p><img src="../../../../assets/img/user.png">
                            </div>
                        </div>
                    </div>
              
                    <div class="form-floating mb-3">
                        <textarea [(ngModel)]="userInput" readonly  class="form-control" ></textarea>
                        <label for="floatingTextarea2">Answer You speak will be diplayed here..</label>
                    </div>

                    <div *ngIf="currentQuestionIndex < context.length">
                        <div *ngIf="!isUserSpeaking" class="d-flex align-item-center justfy-content-center">
                            <button class="control-btn" (click)="startRecording()">Answer</button>&nbsp;&nbsp;
                            <button class="control-btn active-mic" (click)="getQuestion()">Repeat Question</button>
                        </div>
                        
                        <div *ngIf="isUserSpeaking" class="d-flex align-item-center justfy-content-center">
                            <button class="control-btn active-mic" (click)="stopRecording()">Next Question</button>&nbsp;&nbsp;
                            <button class="control-btn active-mic" (click)="clearInput()">Clear</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="currentTab === 3">
                <div class="col-12">
                    <div class="page-title">
                        <h3>Result</h3>
                    </div>
                    <div>
                        <hr>
                        <div class="result-container">
                            <div>
                                <div *ngFor="let result of resultList" class="list-container">
                                    <span class="list-item"><span>Category</span> <span>Score</span> <span>Remark</span></span>
                                    <span class=" list-item"><span>Time</span><span>{{totalTimeTaken}}, min</span> <span>{{totalTimeTaken < 12 && totalTimeTaken > 5 ? "Good":"Needs Improvement"}}</span> </span>
                                    <span class=" list-item"><span>Grammar</span><span>{{result.Grammer}}</span> <span>{{resultRating(result.Grammer)}}</span></span>
                                    <span class=" list-item"><span>Vocabulary</span><span>{{result.Vocabulary}}</span> <span>{{resultRating(result.Vocabulary)}}</span></span>
                                    <span class=" list-item"><span>English</span><span>{{result.English}}</span> <span>{{resultRating(result.English)}}</span></span>
                                    <span class=" list-item"><span>Clarity</span><span>{{result.Clarity}}</span> <span>{{resultRating(result.Clarity)}}</span></span>
                                    <span class=" list-item"><span>Correctness</span><span>{{result.Correctness}}</span> <span>{{resultRating(result.Correctness)}}</span></span>
                                    <span class=" list-item"><span>Over All</span><span>{{result.Over_All}}</span> <span>{{resultRating(result.Over_All)}}</span></span>
                                </div>
                                <hr>
                                <div *ngFor="let result of resultList" >
                                    <span class="conclusion-container"><span>Final Conclusion</span><span>{{result.Conclusion}}</span></span>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row bottom-steper">
                <div class="col-12 d-flex align-item-center justfy-content-center">
                  <!-- <button class="btn btn-primary" *ngIf="currentTab > 0" (click)="prevStep()">Previous</button>&nbsp;&nbsp; -->
                  <button [disabled]="currentTab === 1 && (jobPosition == '' || userEducation == '' || interviewTopic =='' || secretKey === '')" 
                  class="ai-btn hint" *ngIf="currentTab < 2" (click)="nextStep()">{{btnText}}</button>
                  <button [disabled]="currentTab === 2 && currentQuestionIndex != 5 "
                  class="ai-btn hint" *ngIf="currentTab == 2 && currentQuestionIndex >= 5" (click)="nextStep()">{{btnText}}</button>
                  <div></div>
                </div>
            </div>
        </div>
        <div class="d-none d-md-block col-md-2">
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>
</div>