<section class="job-area job-area-two pt-100">
    <div class="text-center">
        <h2>Applied Jobs</h2>
    </div>
    <div class="row m-0">
        <div class="d-none d-md-block col-md-2">
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
        <div class="container col-md-8">
            <div class="row pt-4">
                <div class="col-lg-9 mx-auto"
                    *ngFor="let item of myAppliedJobs | slice: (page-1) * pageSize : page * pageSize">
                    <div class="job-item" *ngIf=" itemLength > 0 ">
                        <div class="job-inner align-items-center p-0">
                            <div class="job-inner-left">
                                <div style="display: flex; align-items: center;">
                                    <h3 routerLink="/job-details/{{item.jobPostId}}" class="anchor-link">
                                        {{item.jobTitle}} </h3>
                                </div>
                                <a routerLink="/company-details"></a>
                                <ul>
                                    <li style="display: flex;flex-wrap: no-wrap;align-items: baseline;"><i
                                            class="icofont-web"></i>Website: <a href="{{item.website}}" target="_blank"
                                            style="font-size: small;margin-bottom: 0;"> {{item.website}}</a></li>
                                    <li><i class="icofont-money-bag"></i>Salary Range:
                                        {{ item.salaryRange1 === 0 && item.salaryRange2 === 0 ? 'Not Mentioned' :
                                        item.salaryRange1 + ' - ' + item.salaryRange2 }}
                                    </li>
                                    <li><i class="icofont-location-pin"></i>Location: {{item.jobLocation}}</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <span class="text-success fw-bold">
                                        <p>Applied</p>
                                        <p>{{item.jobapplyAt | appliedHours}}</p>
                                    </span>
                                    <span class="text-primary fw-bold">
                                        <p>Status</p>
                                        <p>{{ item.recruiterStatus?.length ? item.recruiterStatus : 'Not Viewed' }}</p>
                                    </span>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display:flex; justify-content: center; ">
                    <ngb-pagination [collectionSize]="itemLength" [(page)]="page" [pageSize]="pageSize"
                        aria-label="Default pagination bg-warning"></ngb-pagination>
                </div>

            </div>
        </div>
        <div class="col-md-2">
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
            <div class="row">
                <ng-adsense [adClient]="'ca-pub-6119489645323262'" [pageLevelAds]="false" [fullWidthResponsive]="true">
                </ng-adsense>
            </div>
        </div>
    </div>
</section>