import { Component, OnInit } from '@angular/core';
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service';
import { PopupService } from '../../services/other/popup.service';
import { AccountService } from '../../services/acccount/account.service';
import { RecruiterServicesService } from '../../services/candidate-services copy/recruiter-services.service';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.scss']
})
export class CandidateListComponent implements OnInit {
  candidateList
  itemLength: Number;
  page = 1;
  pageSize = 50;
  recruiterId: string;

  collegeCode: string;
  selectedAcademicYear: string;
  selectedSem: string;
  selectedBranch: string;

  branches: any[] = [];
  sem: any[] = [];
  acadYear: any[] = [];
  divisions: any[] = [];
  subjects: any[] = [];
  staffAllocationDetails = [];
  studentsResponse: any;
  students: any[] = [];

  constructor(
    private candidateServices: CandidateServicesService,
    private popup: PopupService,
    private accountService: AccountService,
    private recruiterService: RecruiterServicesService
  ) { }

  ngOnInit(): void {
    this.collegeCode = this.accountService.get("branch")

    // Check if data is already cached
    const cachedData = this.candidateServices.getCachedData();
    if (cachedData) {
      this.candidateList = cachedData
      this.itemLength = this.candidateList.length;
      this.branches = this.candidateServices.getBranch();this.selectedBranch = this.candidateServices.getSBranch();
      this.acadYear = this.candidateServices.getYear();this.selectedAcademicYear = this.candidateServices.getSYear();
      this.sem = this.candidateServices.getSem();this.selectedSem = this.candidateServices.getSSem();
    } else {
      this.loadBranchSemDiv()
    }

  }

  loadBranchSemDiv() {
    this.recruiterService.getMyAllocationDetails()
      .toPromise().then(resp => {
        this.staffAllocationDetails = <any[]>resp.body;
      }).then(() => {
        this.recruiterService.getHierarchyStaff(this.staffAllocationDetails)
          .toPromise().then(resp => {
            this.branches = <any[]>resp.body;
          }).catch(err => {
            console.log(err);
          });
      });
  }

  onBranchSelect() {
    this.acadYear = this.recruiterService.getAcademicYear(this.branches, this.selectedBranch);
  }

  onAcademicYearSelect() {
    this.sem = this.recruiterService.getSemesters(this.acadYear, this.selectedAcademicYear);
    this.candidateServices.setBranchYearSem(this.branches,this.acadYear,this.sem);
  }

  getCandidate() {
    this.candidateServices.candidateListGet(this.collegeCode, this.selectedBranch, this.selectedAcademicYear, this.selectedSem).subscribe(res => {
      this.candidateList = res.body.seekers
      this.itemLength = res.body.count
      this.candidateServices.setCachedData(res.body.seekers);
      this.candidateServices.setSBranchSYearSSem(this.selectedBranch,this.selectedAcademicYear,this.selectedSem);
    }, err => {
      this.popup.Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    });
  }

  downloadAsCSV(year: string, sem: string): void {
    try {
      let element = document.getElementById("Excel-Table");

      if (!element) {
        console.error("Table element not found");
        return;
      }

      const filteredColumns = [1, 2, 3, 6];
      let ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const filteredData = data.map(row => filteredColumns.map(colIndex => row[colIndex]));
      const newWs: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(filteredData);

      let wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, newWs, 'Sheet1');

      XLSX.writeFile(wb, year.replace(/%/g, '') + "_" + sem.replace(/%/g, '') + 'Applicants.xlsx');

      Swal.fire(
        'File Download Started',
        '',
        'success'
      );
    } catch (error) {
      console.error("Error in downloadAsCSV:", error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
  }



  downloadResume(filePath, firstName, lastName) {
    this.candidateServices.downloadResume(filePath).subscribe(result => {
      function downloadBlob(blob, name = 'download.pdf') {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
        document.body.removeChild(link);
      }
      const regex = /\.(.*?)$/;
      const matches = filePath.match(regex);
      let fileEx;
      if (matches) {
        fileEx = matches[0];
      }
      let fileName = "applicats_resume" + fileEx;
      if (firstName != null || lastName != null) {
        fileName = firstName + "_" + lastName + fileEx;
      }
      downloadBlob(result, fileName);
    }, err => {
      console.log(err);

    })
  }

  viewCandidateProfile(userName) {
    this.candidateServices.usernameSubject.next(userName);
  }

}
