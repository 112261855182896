import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/localStorage/localStorage.services';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class RecruiterProfileComponent implements OnInit {
  role : string 
  constructor(
    private localStorageServices: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.role = this.localStorageServices.getItem('role')
  }

}
