import { jsPDF } from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)
import { UserResume} from './user-interface'
import { CandidateServicesService } from '../../services/candidate-services/candidate-services.service'

export class ResumeTemplate5{
    constructor(private candidateService:CandidateServicesService){}
    private currentY = 20;

    public async sunnySectionTemplate(user:UserResume){
        this.currentY = 20;
        const doc = new jsPDF();
        let image:any;
        let imageFormat:any;
        const rightMargin = doc.internal.pageSize.width - 10;
        const font = "Helvetica";

        if(user.profilePicBlob != null && user.profilePicBlob != ""){
            image = await this.downloadTheImageFromS3(user.profilePicBlob);
            const regex = /\.([a-zA-Z]+)$/;
            imageFormat = user.profilePicBlob.match(regex);
            imageFormat = imageFormat[1].toUpperCase();
        }else{
            image="../../../../assets/img/male_profile.png";
            imageFormat="JPG";
        }
        
        //The head section
        doc.setFillColor(255,255,100);
        doc.roundedRect(0,5,doc.internal.pageSize.width,60,0,0,'F');        
        this.addImage(doc,image,imageFormat,10,10,50,50);

        doc.setFontSize(32);doc.setFont(font,'Bold');doc.setTextColor(2, 77, 22);//30,75,30
        doc.text(`${user.firstName} ${user.lastName}`,70,this.currentY);this.currentY+=10;
        doc.setFontSize(15);doc.setFont(font,'Bold');doc.setTextColor(85, 20, 15); 
        doc.text(user.jobTitle,70,this.currentY);this.currentY+=5;
        this.addText(doc,user.description,70,rightMargin);

        //The left panel #1
        this.currentY = 80;
        let conBlockH = 60 + this.getTextHeight(doc,user.strAddress,10,55); // 60 for email and number section+padding and address height
        doc.setFillColor(255,255,100);
        doc.roundedRect(-30,70,65+30,conBlockH,10,10,'F');

        let  CONTACT = 'CONTACT';
        let centerPos1 = (70 / 2) - (doc.getTextWidth(CONTACT)/2);
        this.addSubHeading(doc,CONTACT,centerPos1,10);
        doc.setFontSize(10);doc.setFont(font,'Bold');doc.setTextColor(0);
        doc.text('Email:',15,this.currentY);this.currentY+=5;
        doc.setFontSize(10);doc.setFont(font,'');doc.setTextColor(0);
        doc.text(user.email,15,this.currentY);this.currentY+=10;

        doc.setFontSize(10);doc.setFont(font,'Bold');doc.setTextColor(0);
        doc.text('Mobile:',15,this.currentY);this.currentY+=5;
        doc.setFontSize(10);doc.setFont(font,'');doc.setTextColor(0);
        doc.text(user.contactNumbers[0].number,15,this.currentY);this.currentY+=10

        doc.setFontSize(10);doc.setFont(font,'Bold');doc.setTextColor(0);
        doc.text('Address:',15,this.currentY);this.currentY+=5;
        doc.setFontSize(10);doc.setFont(font,'');doc.setTextColor(0);
        doc.text(user.strAddress,15,this.currentY,{maxWidth:55});this.currentY+=10
        
        //The left panel #2
        this.currentY = conBlockH + 80 + 5; // 80 to adjust the head section, 5 to create extra space between contact and Skills
        let skillBlockH = 20 + (12*user.skills.length);
        doc.setFillColor(255, 255, 100);
        doc.roundedRect(-30,this.currentY-10,65+30,skillBlockH,10,10,'F');
        let  SKILLS = 'SKILLS';
        let centerPos2 = (70 / 2) - (doc.getTextWidth(SKILLS)/2); 
        this.addSubHeading(doc,SKILLS,centerPos2,10);
        user.skills
            .sort((a,b) => parseInt(String(b.skillProficiency)) - parseInt(String(a.skillProficiency)))
            .map(skill =>{
                let level = this.skillLevel(skill.skillProficiency);
                doc.setFont('Helvetica','Bold');doc.setFontSize(10);doc.setTextColor(0);
                doc.text(`${skill.name}`,15,this.currentY);this.currentY +=5;
                doc.setFont('Helvetica','');doc.setFontSize(10);doc.setTextColor(100,100,100);
                doc.text(`${level}`,15,this.currentY);this.currentY +=7;
            })
        
        // right block #1
        this.currentY = 80;
        let rightPadding = doc.internal.pageSize.width - 80;
        let workBlockH = 20 + this.getTextHeight(doc,user.workExperience,10,rightPadding);
        // doc.setFillColor(255, 255, 100);
        // doc.roundedRect(75,70,rightPadding,workBlockH,0,0,'F');
        let  WORK = 'WORK EXPERIENCE';
        let centerPos3 = ((doc.internal.pageSize.width + 60) / 2) - (doc.getTextWidth(WORK)/2);
        this.addSubHeading(doc,WORK,centerPos3,10);
        this.addText(doc,user.workExperience,70,rightPadding);
        
        // right block #2
        this.currentY = workBlockH + 80 + 5; // 5 extra for padding
        let resBlockH = 20 + this.getTextHeight(doc,user.research,10,rightPadding);
        // doc.setFillColor(255, 255, 100);
        // doc.roundedRect(75,this.currentY-10,rightPadding,resBlockH,0,0,'F');
        let  RESEARCH = 'RESEARCH';
        let centerPos4 = ((doc.internal.pageSize.width + 60) / 2) - (doc.getTextWidth(RESEARCH)/2);
        this.addSubHeading(doc,RESEARCH,centerPos4,10);
        this.addText(doc,user.research,70,rightMargin);
        
        // right block #3
        this.currentY = workBlockH + resBlockH + 80 + 10; // 10 extra for padding
        let eduBlockH = 15 + (20 * user.educationalDetails.length);// 15 for title , 25 average per edu-det height
        // doc.setFillColor(255,255,100);
        // doc.roundedRect(75,this.currentY -10,rightPadding,eduBlockH,0,0,'F');
        let  EDUCATION = 'EDUCATION';
        let centerPos5 = ((doc.internal.pageSize.width + 60) / 2) - (doc.getTextWidth(EDUCATION)/2);
        this.addSubHeading(doc,EDUCATION,centerPos5,10);
        user.educationalDetails
            .sort((a, b) => parseInt(b.from_year) - parseInt(a.from_year))
            .map(edu => {
                doc.setFontSize(10);doc.setFont(font,'Bold');doc.setTextColor(0);
                doc.text(`${edu.from_year} - ${edu.to_year} / ${edu.stream}`,70,this.currentY,{maxWidth:rightPadding});this.currentY +=5
                doc.setFontSize(11);doc.setFont(font,'Bold');doc.setTextColor(0);
                doc.text(`${edu.degree}`,70,this.currentY,{maxWidth:rightPadding});this.currentY += this.getTextHeight(doc,edu.stream,10,rightPadding);
                doc.setFontSize(10);doc.setFont(font,'Oblique');doc.setTextColor(0);
                doc.text(`${edu.institute}`,70,this.currentY,{maxWidth:rightPadding});this.currentY +=10 ;
            });this.currentY+=2;

        this.addWatermark(doc,'Powered by  Campus Orbit');
        return doc;

    }

    private addSubHeading(doc: any, text: string,x:number,plusY:number, align: string = 'left') {
        doc.setFont('Helvetica', 'Bold');
        doc.setFontSize(15);doc.setTextColor(2, 77, 22);
        doc.text(text, x, this.currentY, { align });
        this.currentY += plusY; // Increment Y position
    }
    private addText(doc, text, x, maxWidth,extraSpace=0) {
        doc.setFont("Helvetica", '');
        doc.setFontSize(10);doc.setTextColor(0);
        // Ensure x + maxWidth does not exceed page width
        if (x + maxWidth > doc.internal.pageSize.width) {
            maxWidth = doc.internal.pageSize.width - x - 10; // Adjust maxWidth
        }
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        doc.text(text, x, this.currentY, { maxWidth: maxWidth-extraSpace  });
        this.currentY += blockHeight + 8;
    }
    private getTextHeight(doc,text,textSize,maxWidth){
        doc.setFontSize(textSize);
        const lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor;
        const splittedText = doc.splitTextToSize(text, maxWidth);
        const lines = splittedText.length; // splitted text is a string array
        const blockHeight = lines * lineHeight;
        return blockHeight;
    }
    private skillLevel(skillProficiency){
        const levelArray = ["Beginner","Intermediate","Proficient","Expert"];
        if(skillProficiency <= 50){return levelArray[0]}
        if(skillProficiency > 50 && skillProficiency <=70){return levelArray[1]}
        if(skillProficiency > 70 && skillProficiency <=85){return levelArray[2]}
        if(skillProficiency > 85 && skillProficiency <=95){return levelArray[3]}
        if(skillProficiency > 95 && skillProficiency <=100){return levelArray[4]}
    }
    private addWatermark(doc:any, watermarkText: string) {
        doc.setFontSize(12);
        doc.setFont('Times','Italic');
        doc.setTextColor(100, 100, 100);
        const watermarkX = doc.internal.pageSize.width - 10;
        const watermarkY = doc.internal.pageSize.height - 5;
        doc.textWithLink(watermarkText, watermarkX, watermarkY, { url: 'https://job.campusorbit.com', align:'right' });
    }
    private addImage(doc: any, imagePath: string, imageFormat: string, x: number, y: number, width: number, height: number) {
        const imgData = imagePath; // Replace with your image data
        doc.addImage(imgData, imageFormat, x, y, width, height);
    }  
    private downloadTheImageFromS3(filePath: string): Promise<string> {
        return new Promise((resolve, reject) => {
          this.candidateService.downloadProfilePicPublic(filePath).subscribe(
            (res) => {
              const imageUrl = URL.createObjectURL(res);
              resolve(imageUrl);
            },
            (err) => {
              reject(err);
            }
          );
        });
    }
}